/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */
import { Popconfirm, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteSensor,
  fetchSensorById, fetchSensorByIdGrafica, fetchTipoSensor, limpiarSensor
} from '../../../actions/sensor';
import Accordion from '../../../components/accordion';
import Bread from '../../../components/breadcrumb';
import CardGrafica from '../../../components/card-grafica';
import { routeSensorInfo } from '../../../utils/routesBread';
import FormSensor from '../form';
import Button from '../../../components/button';
import { fetchTarjetaNotPagination } from '../../../actions/tarjeta';
import { SENSOR_PATH } from '../../../routes/path';

const InfoElemento = () => {
  const { sensor, tiposensores, isLoading } = useSelector((state) => state.sensorReducer);
  const { tarjetas } = useSelector((state) => state.tarjetaReducer);
  const { rol } = useSelector((state) => state.userReducer.user);

  const [form, setForm] = useState({
    nombre: '',
    alias: '',
    unidades: '',
    rangoMin: '',
    rangoMax: '',
    resolucionMin: '',
    resolucionMax: '',
    peligrosidad: '',
    peligrosidadAjuste: '',
    posicionEnTrama: '',
    tipo: '',
    tarjeta: '',
    elemento: '',
    proyecto: '',
    empresa: '',
  });
  const [disabled, setDisabled] = useState(true);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectEdit = () => {
    navigate(`/editar-sensor/${id}`);
  };
  useEffect(() => {
    dispatch(fetchSensorById(id));
    dispatch(fetchTarjetaNotPagination());
    dispatch(fetchTipoSensor());
  }, []);

  useEffect(() => {
    setForm({
      nombre: sensor && sensor.nombre,
      alias: sensor && sensor.alias,
      unidades: sensor && sensor.unidades,
      rangoMin: sensor && sensor.rangoMin.$numberDecimal,
      rangoMax: sensor && sensor.rangoMax.$numberDecimal,
      resolucionMin: sensor && sensor.resolucionMin.$numberDecimal,
      resolucionMax: sensor && sensor.resolucionMax.$numberDecimal,
      peligrosidad: sensor && sensor.peligrosidad.$numberDecimal,
      peligrosidadAjuste: sensor && sensor.peligrosidadAjuste.$numberDecimal,
      posicionEnTrama: sensor && sensor.posicionEnTrama,
      tipo: sensor && sensor?.tipo?._id,
      tarjeta: sensor && sensor?.tarjeta?.nombre,
      elemento: sensor && sensor?.tarjeta?.elemento?.nombre,
      proyecto: sensor && sensor?.tarjeta?.elemento?.proyecto?.nombre,
      empresa: sensor && sensor?.tarjeta?.elemento?.proyecto?.empresa?.nombre,
      consumo: sensor && sensor.consumo,
    });
  }, [sensor]);

  useEffect(() => {
    const intervalId = setInterval(() => dispatch(fetchSensorByIdGrafica(id)), 30000);
    return (() => {
      clearInterval(intervalId);
      dispatch(limpiarSensor());
    });
  }, []);
  const redirect = () => {
    navigate(SENSOR_PATH);
  };

  const handleDelete = () => {
    dispatch(deleteSensor(id, redirect));
  };
  return (
    <div>
      <div className="header-nav">
        <Bread routes={routeSensorInfo} />
        <Popconfirm
          title="¿Estás seguro que deseas eliminar el sensor?"
          onConfirm={handleDelete}
          okText="Si"
          cancelText="Cancelar"
          placement="right"
        >
          {rol !== 'empresa' && <Button type="submit" text="Eliminar sensor" color="redd" />}
        </Popconfirm>
      </div>
      <div className="info-container">
        {isLoading
          ? (
            <div className="container-spin">
              <Spin tip="Cargando..." />
            </div>
          )
          : (
            <>
              {sensor === undefined && isLoading === true
                ? (
                  <p className="info-container__nothing">Los detalles del sensor no se encontrarón</p>
                )
                : (
                  <>
                    <FormSensor
                      data={form}
                      disabled={disabled}
                      setDisabled={setDisabled}
                      tiposensores={tiposensores}
                      tarjetas={tarjetas}
                    />
                    <div className="form-info-container__button-edit">
                      {rol !== 'empresa' && <Button type="submit" text="Editar sensor" color="gray" onClick={redirectEdit} />}
                    </div>
                    <div className="info-container__grafica">
                      <Accordion header="Grafica" keyPanel="grafica">
                        {sensor?.data
                          ? (
                            <div className="graficas-items">
                              <CardGrafica
                                key={sensor.nombre}
                                title={sensor.nombre}
                                data={[sensor]}
                                sensor
                              />
                            </div>
                          ) : <p className="text-nothing">No hay datos para mostrar</p>}
                      </Accordion>
                    </div>
                  </>
                )}
            </>
          )}
      </div>
    </div>
  );
};

export default InfoElemento;

/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Popconfirm, Spin } from 'antd';
import {
  deleteEmpresa, editEmpresa, fetchEmpresaById, limpiarEmpresa
} from '../../../actions/empresa';
import { parserData } from '../../../utils/parsers/formData';
import FormCreateEditEmpresa from '../form';
import AccordionUsuarioProyecto from '../accordion-usuario-proyecto';
import { routeEmpresaInfo } from '../../../utils/routesBread';
import Bread from '../../../components/breadcrumb';
import { EMPRESA_PATH } from '../../../routes/path';
import Button from '../../../components/button';

const InfoEmpresa = () => {
  const { empresa, isLoading } = useSelector((state) => state.empresaReducer);
  const [form, setForm] = useState({
    nombre: '',
    planta: '',
    descripcion: '',
    imagen: ''
  });
  const [disabled] = useState(true);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (newForm) => {
    setForm(newForm);
  };

  const redirect = () => {
    navigate(EMPRESA_PATH);
  };

  const handleDelete = () => {
    dispatch(deleteEmpresa(id, redirect));
  };

  const handleSubmitEdit = () => {
    dispatch(editEmpresa(id, parserData(form), redirect));
    dispatch(fetchEmpresaById(id));
  };
  useEffect(() => {
    dispatch(fetchEmpresaById(id));
    return () => {
      dispatch(limpiarEmpresa());
    };
  }, []);

  const redirectEditEmpresa = () => {
    navigate(`/editar-empresa/${id}`);
  };

  useEffect(() => {
    setForm({
      nombre: empresa && empresa.nombre,
      planta: empresa && empresa.planta,
      descripcion: empresa && empresa.descripcion,
      imagen: empresa && empresa.imagen
    });
  }, [empresa]);

  return (
    <div>
      <div className="header-nav">
        <Bread routes={routeEmpresaInfo} />
        <Popconfirm
          title="¿Estás seguro que deseas eliminar la empresa?"
          onConfirm={handleDelete}
          okText="Si"
          cancelText="Cancelar"
          placement="right"
        >
          <Button type="submit" text="Eliminar empresa" color="redd" />
        </Popconfirm>
      </div>
      <div className="info-container">
        {isLoading
          ? (
            <div className="container-spin">
              <Spin tip="Cargando..." />
            </div>
          )
          : (
            <>
              {empresa === undefined
                ? (
                  <p className="info-container__nothing">Los detalles de la empresa no se encontrarón</p>
                )
                : (
                  <>
                    <FormCreateEditEmpresa
                      data={form}
                      onSubmit={handleSubmitEdit}
                      onChange={handleChange}
                      disabled={disabled}
                    />
                    <div className="form-info-container__button-edit">
                      <Button type="submit" text="Editar empresa" color="gray" onClick={redirectEditEmpresa} />
                    </div>
                    {empresa.imagen && (
                      <img
                        style={{ maxWidth: '50%', marginTop: '10px', marginLeft: '50px' }}
                        src={empresa.imagen}
                        alt="Imagen de la empresa"
                      />
                    )}
                    <AccordionUsuarioProyecto data={empresa} />
                  </>
                )}
            </>
          )}
      </div>
    </div>
  );
};

export default InfoEmpresa;

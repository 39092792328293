/* eslint-disable no-underscore-dangle */
import { handle } from 'redux-pack';
import {
  CHANGE_ELEMENTO_GRAFICA,
  CHANGE_TARJETA_GRAFICA,
  FETCH_ELEMENTO_GRAFICA,
  FETCH_GRAFICA,
  FETCH_GRAFICA_BY_ID,
  FETCH_GRAFICA_BY_ID_REFRESH,
  FETCH_PROYECTO_GRAFICA,
  FETCH_SENSORES_GRAFICA,
  FETCH_TARJETA_GRAFICA,
  FETCH_TIPO_GRAFICA,
  LIMPIAR_ESTADO_GRAFICA,
  LIMPIAR_GRAFICA,
  LOGOUT
} from '../actions/types';
import { handleAction } from '../utils/handleAction';

const initialState = {
  graficas: [],
  isLoading: false,
  error: '',
};

const GraficaReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_GRAFICA:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          graficas: payload.data.data,
          total: payload.data.total
        }),
      });
    case FETCH_GRAFICA_BY_ID:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          grafica: payload.data,
        }),
      });
    case FETCH_GRAFICA_BY_ID_REFRESH:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          grafica: payload.data,
        }),
      });
    case FETCH_PROYECTO_GRAFICA:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          proyectos: payload.data,
        }),
      });
    case FETCH_TIPO_GRAFICA:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          tipo: payload.data,
        }),
      });
    case FETCH_ELEMENTO_GRAFICA:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          elementos: payload.data,
        }),
      });
    case FETCH_TARJETA_GRAFICA:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          tarjetas: payload.data,
        }),
      });
    case FETCH_SENSORES_GRAFICA:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          sensores: payload.data,
        }),
      });
    case CHANGE_ELEMENTO_GRAFICA:
      return {
        ...state,
        tarjetas: [],
      };
    case CHANGE_TARJETA_GRAFICA:
      return {
        ...state,
        elementos: [],
        tarjetas: [],
      };
    case LIMPIAR_GRAFICA:
      return { ...state, grafica: {}, isLoading: true };
    case LIMPIAR_ESTADO_GRAFICA:
      return { ...initialState };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default GraficaReducer;

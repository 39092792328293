/* eslint-disable no-underscore-dangle */
const toCamelCase = (str) => str.split(' ').map((word, index) => {
  let parsedWord = word;
  parsedWord = parsedWord.replace('á', 'a');
  parsedWord = parsedWord.replace('Á', 'A');
  parsedWord = parsedWord.replace('é', 'e');
  parsedWord = parsedWord.replace('í', 'i');
  parsedWord = parsedWord.replace('ó', 'o');
  parsedWord = parsedWord.replace('ú', 'u');
  if (index === 0) {
    return parsedWord.toLowerCase();
  }
  return parsedWord.charAt(0).toUpperCase() + parsedWord.slice(1).toLowerCase();
}).join('');
const parserColumns = (columns) => columns.map((item) => ({
  title: item,
  dataIndex: toCamelCase(item),
  key: toCamelCase(item),
}));

export const columnsEmpresa = parserColumns(['Nombre', 'Planta']);
export const columnsUser = parserColumns(['Email', 'Celular', 'Puesto', 'Empresa']);
export const columnsProyecto = parserColumns(['Descripción', 'Area', 'Linea', 'Proceso', 'Identificación']);
export const columnsElementos = parserColumns(['Descripción', 'Area', 'Linea', 'Proceso', 'Tipo']);
export const columnsObjetivos = parserColumns(['Objetivo', 'Fecha', 'Fecha de creacion',]);
export const columnsTarjetas = parserColumns(['Posiciones en trama', 'Elemento']);
export const columnsSensores = parserColumns([
  'Tipo',
  'Alias',
  'Unidades',
  'Rango Min',
  'Rango Max',
  'Resolución Min',
  'Resolución Max',
  'Peligrosidad',
  'Peligrosidad Ajuste',
  'Posición en trama',
]);
export const columnsReporteGeneral = parserColumns([
  'Sensor',
  'Rango',
  'Minimo',
  'Promedio',
  'Maximo',
  'Consumo',
  'Alertas',
]);
export const columnsReporteBloques = parserColumns([
  'fecha',
  'Minimo',
  'Promedio',
  'Maximo',
  'Consumo',
  'Alertas',
]);
export const columnsGraficas = (data) => {
  if (data.nivel === 'proyecto') return parserColumns(['Tipo', 'Nivel', 'Proyecto']);
  if (data.nivel === 'elemento') return parserColumns(['Tipo', 'Nivel', 'Proyecto', 'Elemento']);
  if (data.nivel === 'tarjeta') return parserColumns(['Tipo', 'Nivel', 'Proyecto', 'Elemento', 'Tarjeta']);
  return parserColumns([]);
};

export const parserDataTabla = (data) => ({
  ...data,
  key: data._id
});

export const colunnsReporteAcumulado = (tipoSensor, secundario) => {
  if (secundario === null) {
    return [
      {
        title: 'Fecha',
        dataIndex: 'fecha',
        key: 'fecha',
      },
      {
        title: `Objetivo (${tipoSensor})`,
        dataIndex: 'objetivo',
        key: 'objetivo',
      },
      {
        title: 'Rango 1 (00-06)',
        dataIndex: 'totalRango1',
        key: 'totalRango1',
      },
      {
        title: 'Rango 2 (06-16)',
        dataIndex: 'totalRango2',
        key: 'totalRango2',
      },
      {
        title: 'Rango 3 (16-00)',
        dataIndex: 'totalRango3',
        key: 'totalRango3',
      },
      {
        title: `Total (${tipoSensor})`,
        dataIndex: 'total',
        key: 'total',
      },
      {
        title: '%Real vs Objetivo',
        dataIndex: 'ryo',
        key: 'ryo',
      },
    ];
  }
  return [
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
    },
    {
      title: `Objetivo (${tipoSensor})`,
      dataIndex: 'objetivo',
      key: 'objetivo',
    },
    {
      title: 'Rango 1 (00-06)',
      dataIndex: 'totalRango1',
      key: 'totalRango1',
    },
    {
      title: 'Rango 2 (06-16)',
      dataIndex: 'totalRango2',
      key: 'totalRango2',
    },
    {
      title: 'Rango 3 (16-00)',
      dataIndex: 'totalRango3',
      key: 'totalRango3',
    },
    {
      title: `${secundario?.nombre} (${tipoSensor})`,
      dataIndex: 'secundario',
      key: 'secundario',
    },
    {
      title: `Total (${tipoSensor})`,
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: '%Real vs Objetivo',
      dataIndex: 'ryo',
      key: 'ryo',
    },
  ];
};

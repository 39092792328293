/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-google-charts';
import { colors } from '../../utils/colorsGrafica';
import { fechaHora } from '../../utils/parsers/date';

const options = {
  height: 600,
  width: 800,
  sankey: {
    node: {
      colors
    },
    link: {
      colorMode: 'target',
      colors
    }
  }
};
const Sankey = ({ data, title }) => {
  const [dataset, setDataset] = useState();
  useEffect(() => {
    let parsedData = [];
    if (data.nivel === 'proyecto') {
      const proyectoElemento = data.graficaSankey.elementos.map(
        (elemento) => [`${data.graficaSankey.nombre} (${(data.graficaSankey.totalProyecto).toFixed(2)})`,
          `E. ${elemento.nombre} (${elemento.totalElemento.toFixed(2)})`, elemento.totalElemento]
      );
      const elementoTarjeta = data.graficaSankey.elementos.map(
        (elemento) => elemento.tarjetas.map(
          (tarjeta) => [`E. ${elemento.nombre} (${elemento.totalElemento.toFixed(2)})`,
            `T. ${tarjeta.nombre} (${tarjeta.totalTarjeta.toFixed(2)})`, tarjeta.totalTarjeta]
        )
      );
      const tarjetaSensores = data.graficaSankey.elementos.map(
        (elemento) => elemento.tarjetas.map(
          (tarjeta) => tarjeta.sensores.map(
            (sensor) => [`T. ${tarjeta.nombre} (${tarjeta.totalTarjeta.toFixed(2)})`,
              `${sensor.nombre} (${Number(sensor.data[0]?.total?.$numberDecimal).toFixed(2)})`, Number(sensor.data[0]?.total?.$numberDecimal)]
          )
        )
      );
      parsedData = [['', '', 'Valor'], ...proyectoElemento, ...elementoTarjeta.flat(1), ...tarjetaSensores.flat(2)];
    }
    if (data.nivel === 'elemento') {
      const elementoTarjeta = data.graficaSankey.tarjetas.map(
        (tarjeta) => [`${data.graficaSankey.nombre} (${data.graficaSankey.totalElemento.toFixed(2)})`,
          `T. ${tarjeta.nombre} (${tarjeta.totalTarjeta.toFixed(2)})`, tarjeta.totalTarjeta]
      );

      const tarjetaSensores = data.graficaSankey.tarjetas.map(
        (tarjeta) => tarjeta.sensores.map(
          (sensor) => [`T. ${tarjeta.nombre} (${tarjeta.totalTarjeta.toFixed(2)})`,
            `${sensor.nombre} (${Number(sensor.data[0]?.total?.$numberDecimal).toFixed(2)})`,
            Number(sensor.data[0]?.total?.$numberDecimal)]
        )
      );

      parsedData = [['', '', 'Valor'], elementoTarjeta.flat(), ...tarjetaSensores.flat(1)];
    }
    // if (data.nivel === 'tarjeta') {
    //   const tarjetaSensores = data.graficaSankey.sensores.map(
    //     (sensor) => [data.graficaSankey.nombre, sensor.nombre, Number(sensor.data[0]?.total?.$numberDecimal)]
    //   );
    //   parsedData = [['', '', 'Valor'], ...tarjetaSensores];
    // }

    setDataset(parsedData);
  }, [data]);
  return (
    <div className="card-grafica-container">
      <div className="card-grafica-container__header">
        <p className="card-grafica-container__title">{title}</p>
      </div>
      {data.fechaInicio && data.fechaFin && (
        <div>
          <p className="text-reporte-2">
            {`(${fechaHora(data.fechaInicio)} - ${fechaHora(data.fechaFin)})`}
          </p>
        </div>
      )}
      <div className="card-grafica-container__body-grafica-sankey">
        {dataset?.length !== 1 ? (
          <Chart
            chartType="Sankey"
            data={dataset}
            options={options}
          />
        )
          : (
            <p className="text-nothing">
              Los sensores de la grafica
              {' '}
              {title}
              {' '}
              no contienen datos
            </p>
          )}
      </div>
    </div>
  );
};

Sankey.propTypes = {
  data: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,

};

export default Sankey;

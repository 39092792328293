import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const usePagination = (dispatchFunc, reducer, id) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const perPage = 10;

  useEffect(() => {
    const offset = (page - 1) * perPage;
    if (id) {
      dispatch(dispatchFunc(10, offset, id));
    } else {
      dispatch(dispatchFunc(10, offset));
    }
  }, [page]);

  const total = useSelector((state) => state[`${reducer}Reducer`].total);
  const isLoading = useSelector((state) => state[`${reducer}Reducer`].isLoading);
  const dataSource = useSelector((state) => state[`${reducer}Reducer`][`${reducer}s`]);

  const handlePage = (e) => {
    setPage(e);
  };

  return {
    dataSource,
    total,
    handlePage,
    page,
    isLoading
  };
};

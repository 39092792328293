import React from 'react';
import { useSelector } from 'react-redux';
import Logo from '../../assets/logo/logo_azul.png';

const Home = () => {
  const user = useSelector((state) => state.userReducer.user);
  return (
    <div className="home-container">
      <div className="home-container__header">
        <p>Dashboard</p>
      </div>
      {user?.empresa
        ? (
          <div className="home-container__body">
            <img src={user?.empresa?.imagen} alt="Visor Mapx" />
            <div className="home-container__empresa">
              <p>{user?.empresa?.nombre}</p>
              <p>{user?.empresa?.descripcion}</p>
            </div>
          </div>
        )
        : (
          <div className="home-container__logo">
            <img src={Logo} alt="Visormapx" />
          </div>
        )}
      <div className="home-container__footer">
        <p>{`Usuario: ${user.nombre}`}</p>
        <div>
          <p>{`Puesto: ${user.puesto}`}</p>
          <p>{`Correo: ${user.email}`}</p>
          <p>{`Celular: ${user.celular}`}</p>
        </div>
      </div>
    </div>
  );
};

export default Home;

/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import Input from '../../../components/input';
import Button from '../../../components/button';
import { thereIsErrorInForm, validateDate, validateField } from '../../../utils/validations';
import Select from '../../../components/select';
import ErrorMessage from '../../../components/errorMessage';
import { nivelesReporte } from '../../../utils/niveles';

const FormGrafica = ({
  data,
  onSubmit,
  onChange, isNew = false,
  proyectos,
  tipoSensores,
  elementos,
  tarjetas,
  tipoGrafica,
  disabled = false,
  onShow,
  idSankey,
  hideButton = false
}) => {
  const [fechaInicio, setFechaInicio] = useState();
  const [error, setError] = useState({
    tipografica: '',
    nombre: '',
    tipo: '',
    fechaInicio: '',
    fechaFin: '',
    nivel: '',
    proyecto: '',
    elemento: '',
    tarjeta: ''
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    const tipograficaError = validateField('tipografica', data.tipografica);

    const fechaFinError = data?.tipografica === idSankey && validateDate('fechaFin', data.fechaFin);
    const fechaInicioError = data?.tipografica === idSankey && validateDate('fechaInicio', data.fechaInicio);
    const nombreError = validateField('nombre', data.nombre);
    const tipoError = validateField('tipo', data.tipo);
    const nivelError = validateField('nivel', data.nivel);
    const proyectoError = validateField('proyecto', data.proyecto);
    const elementoError = data.nivel === 'elemento' || data.nivel === 'tarjeta' ? validateField('elemento', data.elemento) : '';
    const tarjetaError = data.nivel === 'tarjeta' ? validateField('tarjeta', data.tarjeta) : '';
    const errorMessage = {
      ...error,
      ...nombreError,
      ...fechaFinError,
      ...fechaInicioError,
      ...tipograficaError,
      ...tipoError,
      ...nivelError,
      ...proyectoError,
      ...elementoError,
      ...tarjetaError,
    };
    setError(errorMessage);
    if (!thereIsErrorInForm(errorMessage)) {
      onSubmit();
    }
  };

  const handleChange = (name, value) => {
    let formReset;
    if (name === 'nivel') {
      if (value === 'proyecto') {
        formReset = {
          ...data,
          proyecto: '',
          [name]: value,
        };
      }
      if (value === 'elemento') {
        formReset = {
          ...data,
          proyecto: '',
          elemento: '',
          [name]: value,
        };
      }
      if (value === 'tarjeta') {
        formReset = {
          ...data,
          proyecto: '',
          elemento: '',
          tarjeta: '',
          [name]: value,
        };
      }
      onChange(formReset);
    } else if (name === 'proyecto') {
      formReset = {
        ...data,
        elemento: '',
        tarjeta: '',
        [name]: value,
      };
      onChange(formReset);
    } else {
      onChange({
        ...data,
        [name]: value,
      });
    }
  };

  const handleBlur = (key, value) => {
    let fieldError = '';
    if (key === 'fechaInicio' || key === 'fechaFin') fieldError = validateDate(key, value);
    else fieldError = validateField(key, value);
    setError({
      ...error,
      ...fieldError,
    });
  };

  const handleShow = () => {
    onShow();
  };

  useEffect(() => {
    setFechaInicio(new Date(data.fechaInicio));
  }, [data.fechaInicio]);

  useEffect(() => {
    if (data.nivel === 'proyecto') {
      const errorProyecto = { ...error, elemento: '', tarjeta: '' };
      setError(errorProyecto);
    }
    if (data.nivel === 'elemento') {
      const errorElemento = { ...error, tarjeta: '' };
      setError(errorElemento);
    }
  }, [data.nivel]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-info-container">
        <div className="form-info-container__box-container-up">
          {
              isNew
            && (
              <Select
                label="Tipo de gráfica"
                value={data.tipografica}
                onChange={handleChange}
                onBlur={handleBlur}
                options={tipoGrafica}
                name="tipografica"
                disabled={disabled}
                error={error.tipografica}
              />
            )
            }
          <div className="form-info-container__box-grid">

            {data?.tipografica === idSankey && (
            <>
              <div className="input-container">
                <p>Inicio</p>
                <ReactDatePicker
                  showIcon
                  selected={data.fechaInicio}
                  onChange={(date) => handleChange('fechaInicio', date)}
                  showTimeSelect
                  maxDate={new Date()}
                  dateFormat="dd/MM/yyyy HH:mm"
                  locale="es"
                />
                <ErrorMessage textError={error.fechaInicio} />
              </div>
              <div className="input-container">
                <p>Fin</p>
                <ReactDatePicker
                  showIcon
                  selected={data.fechaFin}
                  onChange={(date) => handleChange('fechaFin', date)}
                  minDate={fechaInicio}
                  maxDate={new Date()}
                  showTimeSelect
                  dateFormat="dd/MM/yyyy HH:mm"
                  locale="es"
                />
                <ErrorMessage textError={error.fechaFin} />
              </div>
            </>
            )}
            <Input
              placeholder=""
              name="nombre"
              label="Nombre"
              value={data.nombre}
              inputType="text"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
              error={error.nombre}
            />
            <Select
              label="Tipo sensor"
              value={data.tipo}
              onChange={handleChange}
              onBlur={handleBlur}
              options={tipoSensores}
              name="tipo"
              disabled={disabled}
              error={error.tipo}
            />
            <Select
              label="Nivel"
              value={data.nivel}
              onChange={handleChange}
              onBlur={handleBlur}
              options={nivelesReporte}
              name="nivel"
              disabled={disabled}
              error={error.nivel}
            />
            <Select
              label="Proyectos"
              value={data.proyecto}
              onChange={handleChange}
              onBlur={handleBlur}
              options={proyectos}
              name="proyecto"
              disabled={disabled}
              error={error.proyecto}
            />
            { data.nivel === 'elemento' && (
            <Select
              label="Elementos"
              value={data.elemento}
              onChange={handleChange}
              onBlur={handleBlur}
              options={elementos}
              name="elemento"
              disabled={disabled}
              error={error.elemento}
            />
            )}
            {
              data.nivel === 'tarjeta' && (
                <>
                  <Select
                    label="Elementos"
                    value={data.elemento}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={elementos}
                    name="elemento"
                    disabled={disabled}
                    error={error.elemento}
                  />
                  <Select
                    label="Tarjetas"
                    value={data.tarjeta}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={tarjetas}
                    name="tarjeta"
                    disabled={disabled}
                    error={error.tarjeta}
                  />
                </>
              )
            }
          </div>
        </div>
        <div className="form-info-container__button">
          <div className="form-info-container__button-edit">
            {isNew
              ? (
                <Button
                  type="submit"
                  text="Guardar"
                  color="gray"
                />
              )
              : (
                <div>
                  {!hideButton
                  && (
                  <Button
                    type="button"
                    text="Agregar sensor"
                    color="gray"
                    onClick={handleShow}
                  />
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    </form>
  );
};
FormGrafica.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.shape().isRequired,
  proyectos: PropTypes.arrayOf(PropTypes.shape()),
  tipoSensores: PropTypes.arrayOf(PropTypes.shape()),
  elementos: PropTypes.arrayOf(PropTypes.shape()),
  tarjetas: PropTypes.arrayOf(PropTypes.shape()),
  tipoGrafica: PropTypes.arrayOf(PropTypes.shape()),
  isNew: PropTypes.bool,
  disabled: PropTypes.bool,
  onShow: PropTypes.func,
  idSankey: PropTypes.string,
  hideButton: PropTypes.bool,
};
FormGrafica.defaultProps = {
  tipoSensores: [],
  proyectos: [],
  elementos: [],
  tarjetas: [],
  tipoGrafica: [],
  disabled: false,
  isNew: false,
  onShow: () => {},
  onSubmit: () => {},
  onChange: () => {},
  idSankey: '',
  hideButton: false
};
export default FormGrafica;

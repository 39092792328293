/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import CardGrafica from '../card-grafica';
import Sankey from '../sankey';

const Tab = ({ dataGrafica, dataSankey }) => {
  const graficaCardLineas = () => {
    if (dataGrafica.length !== 0) {
      return (
        dataGrafica?.map((grafica) => (
          <div key={grafica._id}>
            <div className="graficas-items">
              <CardGrafica
                idGrafica={grafica._id}
                title={grafica.nombre}
                data={grafica.sensores}
              />
            </div>
          </div>
        )));
    } return (
      <div className="graficas-items">
        <p className="text-nothing">No hay graficas de lineas para mostrar</p>
      </div>
    );
  };

  const graficasSankey = () => {
    if (dataSankey.length !== 0) {
      return (
        dataSankey?.map((grafica) => (
          <div className="graficas-items" key={grafica._id}>
            <Sankey
              data={grafica}
              title={grafica.nombre}
            />
          </div>

        ))
      );
    } return (
      <div className="graficas-items">
        <p className="text-nothing">No hay graficas sankey para mostrar</p>
      </div>
    );
  };

  const items = [
    {
      key: '1',
      label: 'Grafica de lineas',
      children: graficaCardLineas(),
    },
    {
      key: '2',
      label: 'Grafica Sankey',
      children: graficasSankey(),
    },
  ];

  return (
    <Tabs defaultActiveKey="1" items={items} />
  );
};

Tab.propTypes = {
  dataGrafica: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dataSankey: PropTypes.arrayOf(PropTypes.shape()).isRequired,

};
export default Tab;

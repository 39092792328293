/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../components/select';
import { columnsSensores, parserDataTabla } from '../../../utils/parsers/table';
import Card from '../../../components/card';
import Accordion from '../../../components/accordion';
import { parserSensores } from '../../../utils/parsers/sensor';
import Button from '../../../components/button';

const AgregarSensores = ({
  data, onChange, proyectos, tarjetas, elementos, sensores, onAddSenores, onClose, error, setError
}) => {
  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };

  const submitAddSensores = () => {
    if (data.sensor) {
      onAddSenores();
      onClose();
    } else {
      setError('No seleccionó un sensor');
    }
  };

  return (
    <div className="form-info-container">
      <div className="form-info-container__box-grid">
        <Select
          label="Proyectos"
          value={data.proyecto}
          onChange={handleChange}
          options={proyectos}
          name="proyecto"
          disabled={!!data.proyecto}
        />
        <Select
          label="Elementos"
          value={data.elemento}
          onChange={handleChange}
          options={elementos}
          name="elemento"
          disabled={data.nivel === 'elemento' || data.nivel === 'tarjeta'}
        />
        <Select
          label="Tarjetas"
          value={data.tarjeta}
          onChange={handleChange}
          options={tarjetas}
          name="tarjeta"
          disabled={data.nivel === 'tarjeta'}
        />
      </div>
      {data.tarjeta
      && (
        <>
          <div className="form-info-container__accordion-sensores">
            <Accordion keyPanel="sensores" header="Sensores">
              {!sensores
                ? (
                  <div className="container-spin">
                    <Spin tip="Cargando..." />
                  </div>
                )
                : sensores?.length !== 0 ? (
                  sensores?.map((item) => (
                    <div key={item.nombre}>
                      <Card
                        columns={columnsSensores}
                        data={[parserDataTabla(parserSensores(item))]}
                        header={item.nombre}
                        route="info-sensor"
                        onAddSenores={onAddSenores}
                        onChange={handleChange}
                        selectedRadio={data.sensor}
                      />
                    </div>
                  ))
                )
                  : <p className="text-nothing">No hay datos para mostrar</p>}
            </Accordion>
          </div>
          <p className="text-nothing">{error}</p>
          <div className="form-info-container__button">
            <div className="form-info-container__button-edit">
              <Button
                type="button"
                text="Guardar"
                color="gray"
                onClick={submitAddSensores}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
AgregarSensores.propTypes = {
  onChange: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
  proyectos: PropTypes.arrayOf(PropTypes.shape()),
  elementos: PropTypes.arrayOf(PropTypes.shape()),
  tarjetas: PropTypes.arrayOf(PropTypes.shape()),
  sensores: PropTypes.arrayOf(PropTypes.shape()),
  error: PropTypes.string,
  onAddSenores: PropTypes.func,
  onClose: PropTypes.func.isRequired
};
AgregarSensores.defaultProps = {
  proyectos: [],
  elementos: [],
  tarjetas: [],
  error: '',
  sensores: [],
  onAddSenores: () => {},
};

export default AgregarSensores;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Input from '../../../components/input';
import { thereIsErrorInForm, validateField } from '../../../utils/validations';
import Button from '../../../components/button';

const FormCreateEmpresa = ({
  data, onSubmit, onChange, disabled = false, /* setDisabled */
}) => {
  const [error, setError] = useState({
    nombre: '',
    planta: '',
    descripcion: '',
  });

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (data.imagen instanceof Blob) {
      const imageUrl = URL.createObjectURL(data.imagen);
      setImagePreview(imageUrl);
    }
  }, [data.imagen]);

  const { pathname } = useLocation();

  const [isFormComplete, setIsFormComplete] = useState(false);

  useEffect(() => {
    setIsFormComplete(
      data.nombre
      && data.planta
      && data.descripcion
    );
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const nombreError = validateField('nombre', data.nombre);
    const plantaError = validateField('planta', data.planta);
    const descripcionError = validateField('descripcion', data.descripcion);
    const errorMessage = {
      ...error,
      ...nombreError,
      ...plantaError,
      ...descripcionError,
    };
    setError(errorMessage);
    if (!thereIsErrorInForm(errorMessage)) {
      onSubmit({ ...data, imagen: image });
    }
  };

  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
    onChange({
      ...data,
      imagen: selectedImage
    });

    // Mostrar la vista previa de la imagen
    const reader = new FileReader();
    reader.onload = (e) => {
      setImagePreview(e.target.result);
    };
    reader.readAsDataURL(selectedImage);
  };

  const handleBlur = (key, value) => {
    const fieldError = validateField(key, value);
    setError({
      ...error,
      ...fieldError,
    });
  };
  return (

    <form onSubmit={handleSubmit}>
      <div className="form-info-container">
        <div className="form-info-container__box-container">
          <div className="form-info-container__box">
            <Input
              placeholder=""
              name="nombre"
              label="Nombre"
              value={data.nombre}
              inputType="text"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
            />
            <Input
              placeholder=""
              name="descripcion"
              label="Descripcion"
              value={data.descripcion}
              inputType="text"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
            />
            {
              (pathname.includes('crear') || pathname.includes('editar'))
              && (
                <>
                  <label htmlFor="fileInput" className="custom-file-input">
                    Subir imagen
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleImageChange}
                    disabled={disabled}
                    className="hidden-file-input"
                  />
                </>
              )
            }
            {imagePreview && (
            <img src={imagePreview} alt="Vista previa de la imagen" style={{ maxWidth: '50%', marginTop: '10px' }} />
            )}
          </div>
          <div className="form-info-container__box">
            <Input
              placeholder=""
              name="planta"
              label="Planta"
              value={data.planta}
              inputType="text"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="form-info-container__button">
          { (pathname.includes('crear') || pathname.includes('editar'))
            && (
              <div className="form-info-container__button-edit">
                <Button
                  type="submit"
                  text="Guardar empresa"
                  color="gray"
                  onClick={handleSubmit}
                  disabled={!isFormComplete}
                />
              </div>
            )}
        </div>
      </div>

    </form>

  );
};
FormCreateEmpresa.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired
};
export default FormCreateEmpresa;

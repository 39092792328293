import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendEmail } from '../../actions/app';
import Button from '../../components/button';
import Input from '../../components/input';
import { thereIsErrorInForm, validateField } from '../../utils/validations';

const ResetPassword = () => {
  const [form, setForm] = useState({ email: '' });
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState({
    email: ''
  });
  const dispatch = useDispatch();

  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleBlur = (key, value) => {
    const fieldError = validateField(key, value);
    setError({
      ...error,
      ...fieldError,
    });
  };
  const handleDisabled = () => {
    setDisabled(!disabled);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const emailError = validateField('email', form.email);
    const errorMessage = {
      ...error,
      ...emailError
    };
    setError(errorMessage);
    if (!thereIsErrorInForm(errorMessage)) {
      handleDisabled();
      dispatch(sendEmail(form.email, handleDisabled));
    }
  };

  return (
    <div className="reset-container">
      <h2>Recuperar contraseña</h2>
      <div className="reset-container__input">
        <Input
          placeholder="Ingresar email"
          name="email"
          label=""
          value={form.email}
          inputType="text"
          onChange={handleChange}
          onBlur={handleBlur}
          error={error.email}
        />
        <Button onClick={handleSubmit} text="Enviar" color="primary" disabled={disabled} />
      </div>
    </div>
  );
};

export default ResetPassword;

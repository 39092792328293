/* eslint-disable no-else-return */
/* eslint-disable keyword-spacing */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import {
  Navigate, useLocation
} from 'react-router-dom';
import { LOGIN_PATH } from '../../routes/path';

const ProtectedRoute = ({ children }) => {
  const { pathname } = useLocation();
  const { rol } = useSelector((state) => state.userReducer.user);
  const { isLoading } = useSelector((state) => state.userReducer);
  const token = useSelector((state) => state.appReducer.token.accessToken);

  if (token && (pathname.includes('nuevo-password') || pathname.includes('restablecer-password'))) {
    return <Navigate to={LOGIN_PATH} replace />;
  } else if (!token || ((pathname.includes('empresa') || pathname.includes('usuario') || pathname.includes('nuevo-password')) && rol === 'empresa')) {
    return <Navigate to={LOGIN_PATH} replace />;
  } else if (isLoading) {
    return (
      <div className="container-spin">
        <Spin tip="Cargando..." />
      </div>
    );
  } else {
    return children;
  }
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
export default ProtectedRoute;

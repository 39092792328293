export const LOGIN_PATH = '/iniciar-sesion';
export const ROOT_PAGE_PATH = '/';

export const EMPRESA_PATH = '/empresa';
export const CREAR_EMPRESA_PATH = '/crear-empresa';
export const EDITAR_EMPRESA_PATH = '/editar-empresa/:id';
export const INFO_EMPRESA_PATH = '/info-empresa/:id';

export const USUARIO_PATH = '/usuario';
export const CREAR_USUARIO_PATH = '/crear-usuario';
export const EDIT_USUARIO_PATH = '/editar-usuario/:id';
export const INFO_USUARIO_PATH = '/info-usuario/:id';

export const PROYECTO_PATH = '/proyecto';
export const CREAR_PROYECTO_PATH = '/crear-proyecto';
export const EDITAR_PROYECTO_PATH = '/editar-proyecto/:id';
export const INFO_PROYECTO_PATH = '/info-proyecto/:id';

export const ELEMENTO_PATH = '/elemento';
export const CREAR_ELEMENTO_PATH = '/crear-elemento';
export const INFO_ELEMENTO_PATH = '/info-elemento/:id';
export const EDITAR_ELEMENTO_PATH = '/editar-elemento/:id';

export const SENSOR_PATH = '/sensor';
export const CREAR_SENSOR_PATH = '/crear-sensor';
export const INFO_SENSOR_PATH = '/info-sensor/:id';
export const EDITAR_SENSOR_PATH = '/editar-sensor/:id';

export const TARJETA_PATH = '/tarjeta';
export const CREAR_TARJETA_PATH = '/crear-tarjeta';
export const INFO_TARJETA_PATH = '/info-tarjeta/:id';
export const EDITAR_TARJETA_PATH = '/editar-tarjeta/:id';

export const GRAFICA_PATH = '/grafica';
export const INFO_GRAFICA_PATH = '/info-grafica/:id';
export const CREAR_GRAFICA_PATH = '/crear-grafica';

export const RESET_PASS_PATH = '/restablecer-password';
export const NUEVA_CONTRASEÑA_PATH = '/nuevo-password/:token';

export const REPORTES_PATH = '/reporte';

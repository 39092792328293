import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LayoutContainer from '../components/layout';
import ProtectedRoute from '../components/protectedRoute';
import Elemento from '../pages/elementos';
import InfoElemento from '../pages/elementos/info-elemento';
import Empresa from '../pages/empresa';
import InfoEmpresa from '../pages/empresa/info-empresa';
import Usuario from '../pages/usuario';
import InfoUsuario from '../pages/usuario/info-usuario';
import Tarjeta from '../pages/tarjeta';
import InfoTarjeta from '../pages/tarjeta/info-tarjeta';
import Home from '../pages/home';
import Login from '../pages/login';
import Proyecto from '../pages/proyecto';
import InfoProyecto from '../pages/proyecto/info-proyecto';
import {
  ELEMENTO_PATH,
  EMPRESA_PATH,
  INFO_ELEMENTO_PATH,
  INFO_EMPRESA_PATH,
  INFO_PROYECTO_PATH,
  USUARIO_PATH,
  INFO_USUARIO_PATH,
  LOGIN_PATH,
  PROYECTO_PATH,
  ROOT_PAGE_PATH,
  SENSOR_PATH,
  INFO_SENSOR_PATH,
  TARJETA_PATH,
  INFO_TARJETA_PATH,
  GRAFICA_PATH,
  CREAR_GRAFICA_PATH,
  INFO_GRAFICA_PATH,
  RESET_PASS_PATH,
  NUEVA_CONTRASEÑA_PATH,
  REPORTES_PATH,
  CREAR_SENSOR_PATH,
  CREAR_EMPRESA_PATH,
  CREAR_PROYECTO_PATH,
  CREAR_ELEMENTO_PATH,
  CREAR_USUARIO_PATH,
  CREAR_TARJETA_PATH,
  EDIT_USUARIO_PATH,
  EDITAR_PROYECTO_PATH,
  EDITAR_ELEMENTO_PATH,
  EDITAR_TARJETA_PATH,
  EDITAR_SENSOR_PATH,
  EDITAR_EMPRESA_PATH
} from './path';
import Sensor from '../pages/sensor';
import InfoSensor from '../pages/sensor/info-sensor';
import Grafica from '../pages/grafica';
import CrearGrafica from '../pages/grafica/crear';
import CrearEditUsuario from '../pages/usuario/create-edit';
import CrearEditElemento from '../pages/elementos/crear-edit';
import CrearEditTarjeta from '../pages/tarjeta/crear-edit';
import CrearEditSensor from '../pages/sensor/crear-edit';
import InfoGrafica from '../pages/grafica/info-grafica';
import Error404 from '../pages/404';
import ResetPassword from '../pages/resetear-contraseña';
import NuevaContraseña from '../pages/resetear-contraseña/cambiar-contraseña';
import ScrollToTop from '../components/scrollToTop';
import Reporte from '../pages/reporte';
import CrearEmpresa from '../pages/empresa/create-edit';
import CrearEditProyecto from '../pages/proyecto/crear-edit';

const RouteApp = () => (
  <BrowserRouter>
    <LayoutContainer>
      <ScrollToTop>
        <Routes>
          <Route path={ROOT_PAGE_PATH} element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path={LOGIN_PATH} element={<Login />} />
          <Route
            path={EMPRESA_PATH}
            element={(
              <ProtectedRoute>
                <Empresa />
              </ProtectedRoute>
            )}
          />
          <Route
            path={CREAR_EMPRESA_PATH}
            element={(
              <ProtectedRoute>
                <CrearEmpresa />
              </ProtectedRoute>
            )}
          />
          <Route
            path={EDITAR_EMPRESA_PATH}
            element={(
              <ProtectedRoute>
                <CrearEmpresa />
              </ProtectedRoute>
            )}
          />
          <Route
            path={INFO_EMPRESA_PATH}
            element={(
              <ProtectedRoute>
                <InfoEmpresa />
              </ProtectedRoute>
            )}
          />
          <Route
            path={PROYECTO_PATH}
            element={(
              <ProtectedRoute>
                <Proyecto />
              </ProtectedRoute>
            )}
          />
          <Route
            path={CREAR_PROYECTO_PATH}
            element={(
              <ProtectedRoute>
                <CrearEditProyecto />
              </ProtectedRoute>
            )}
          />
          <Route
            path={EDITAR_PROYECTO_PATH}
            element={(
              <ProtectedRoute>
                <CrearEditProyecto />
              </ProtectedRoute>
            )}
          />
          <Route
            path={INFO_PROYECTO_PATH}
            element={(
              <ProtectedRoute>
                <InfoProyecto />
              </ProtectedRoute>
            )}
          />
          <Route
            path={ELEMENTO_PATH}
            element={(
              <ProtectedRoute>
                <Elemento />
              </ProtectedRoute>
            )}
          />
          <Route
            path={CREAR_ELEMENTO_PATH}
            element={(
              <ProtectedRoute>
                <CrearEditElemento />
              </ProtectedRoute>
            )}
          />
          <Route
            path={EDITAR_ELEMENTO_PATH}
            element={(
              <ProtectedRoute>
                <CrearEditElemento />
              </ProtectedRoute>
            )}
          />
          <Route
            path={INFO_ELEMENTO_PATH}
            element={(
              <ProtectedRoute>
                <InfoElemento />
              </ProtectedRoute>
            )}
          />
          <Route
            path={USUARIO_PATH}
            element={(
              <ProtectedRoute>
                <Usuario />
              </ProtectedRoute>
            )}
          />
          <Route
            path={CREAR_USUARIO_PATH}
            element={(
              <ProtectedRoute>
                <CrearEditUsuario />
              </ProtectedRoute>
            )}
          />
          <Route
            path={EDIT_USUARIO_PATH}
            element={(
              <ProtectedRoute>
                <CrearEditUsuario />
              </ProtectedRoute>
            )}
          />
          <Route
            path={INFO_USUARIO_PATH}
            element={(
              <ProtectedRoute>
                <InfoUsuario />
              </ProtectedRoute>
            )}
          />
          <Route
            path={TARJETA_PATH}
            element={(
              <ProtectedRoute>
                <Tarjeta />
              </ProtectedRoute>
            )}
          />
          <Route
            path={CREAR_TARJETA_PATH}
            element={(
              <ProtectedRoute>
                <CrearEditTarjeta />
              </ProtectedRoute>
            )}
          />
          <Route
            path={EDITAR_TARJETA_PATH}
            element={(
              <ProtectedRoute>
                <CrearEditTarjeta />
              </ProtectedRoute>
            )}
          />
          <Route
            path={INFO_TARJETA_PATH}
            element={(
              <ProtectedRoute>
                <InfoTarjeta />
              </ProtectedRoute>
            )}
          />
          <Route
            path={SENSOR_PATH}
            element={(
              <ProtectedRoute>
                <Sensor />
              </ProtectedRoute>
            )}
          />
          <Route
            path={CREAR_SENSOR_PATH}
            element={(
              <ProtectedRoute>
                <CrearEditSensor />
              </ProtectedRoute>
            )}
          />
          <Route
            path={EDITAR_SENSOR_PATH}
            element={(
              <ProtectedRoute>
                <CrearEditSensor />
              </ProtectedRoute>
            )}
          />
          <Route
            path={INFO_SENSOR_PATH}
            element={(
              <ProtectedRoute>
                <InfoSensor />
              </ProtectedRoute>
            )}
          />
          <Route
            path={GRAFICA_PATH}
            element={(
              <ProtectedRoute>
                <Grafica />
              </ProtectedRoute>
            )}
          />
          <Route
            path={CREAR_GRAFICA_PATH}
            element={(
              <ProtectedRoute>
                <CrearGrafica />
              </ProtectedRoute>
            )}
          />
          <Route
            path={INFO_GRAFICA_PATH}
            element={(
              <ProtectedRoute>
                <InfoGrafica />
              </ProtectedRoute>
            )}
          />
          <Route
            path={REPORTES_PATH}
            element={(
              <ProtectedRoute>
                <Reporte />
              </ProtectedRoute>
            )}
          />
          <Route
            path={RESET_PASS_PATH}
            element={(
              <ResetPassword />
            )}
          />
          <Route
            path={NUEVA_CONTRASEÑA_PATH}
            element={(
              <NuevaContraseña />
            )}
          />
          <Route
            path="*"
            element={(
              <Error404 />
            )}
          />
        </Routes>
      </ScrollToTop>
    </LayoutContainer>
  </BrowserRouter>
);

export default RouteApp;

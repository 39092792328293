/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spin, Table } from 'antd';
import { columnsReporteBloques, columnsReporteGeneral } from '../../../utils/parsers/table';
import { parserReportesBloques, parserReportesGeneral } from '../../../utils/parsers/reporte';
import { fechaHora } from '../../../utils/parsers/date';

const ReporteBase = ({ reporte, loading }) => {
  const [dataTableGeneral, setDataTableGeneral] = useState([]);
  const [dataTableBloques, setDataTableBloques] = useState([]);

  const parseBloques = (bloques) => {
    const parsedBloques = [];
    for (let index = 0; index < bloques.length; index++) {
      const maxAnterior = bloques[index - 1]?.maximo
        ? bloques[index - 1]?.maximo : bloques[index]?.minimo;
      const newConsumo = parseFloat(bloques[index]?.maximo?.$numberDecimal, 10)
       - parseFloat(maxAnterior?.$numberDecimal, 10);
      const bloq = { ...bloques[index], consumo: newConsumo, minimo: maxAnterior };
      parsedBloques.push(parserReportesBloques(bloq));
    }
    return (parsedBloques);
  };

  useEffect(() => {
    if (reporte?.general) {
      const parsertableGeneral = reporte?.general?.map((item) => parserReportesGeneral(item));
      setDataTableGeneral(parsertableGeneral);
    }
    if (reporte?.sensores) {
      const parseTableBloques = reporte?.sensores?.map(
        (sensor) => {
          if (!sensor?.loading) {
            return ({
              ...sensor,
              bloques: parseBloques(sensor?.bloques)
            });
          }
          return sensor;
        }
      );
      setDataTableBloques(parseTableBloques);
    }
  }, [reporte]);

  const rangoSensor = (sensor) => `(${`${sensor.rangoMin?.$numberDecimal}${sensor.unidades} ${sensor.rangoMax?.$numberDecimal}${sensor.unidades}`}) `;

  return (
    <div>
      {dataTableGeneral.length > 0
      && (
        <div className="info-container__card-reportegeneral info-container__card-reporte--mobile">
            {loading
              ? (
                <div style={{ textAlign: 'center', paddingBlock: '2rem' }}>
                  <Spin />
                </div>
              )
              : (
                <>
                  <div className="info-container__title-reporte">
                    <p>General</p>
                  </div>
                  <div className="info-container__table-general">
                    <div>
                      <p className="text-nothing">{`${fechaHora(reporte.general[0].fechaInicio)} a ${fechaHora(reporte.general[0].fechaFin).toLocaleString()}`}</p>
                    </div>
                    <Table
                      columns={columnsReporteGeneral}
                      dataSource={dataTableGeneral}
                      pagination={false}
                      className="info-container__container-general"
                    />
                  </div>
                </>
              )}
        </div>
      )}
      {dataTableBloques.length > 0
      && (
        <div className="info-container__card-reporte info-container__card-reporte--mobile">
          <div className="info-container__title-reporte">
            <p>Bloques</p>
          </div>
          <div className="info-container__table-bloques">
            {dataTableBloques?.map((item) => (
              <div className="info-container__bloque-bloques" key={item._id}>
                {item.loading
                  ? (
                    <div style={{ textAlign: 'center', paddingBlock: '2rem' }}>
                      <Spin />
                    </div>
                  )
                  : (
                    <>
                      <div>
                        <p className="text-reporte-1">{item.alias}</p>
                        <p className="text-reporte-2">{rangoSensor(item)}</p>
                      </div>
                      <Table
                        columns={columnsReporteBloques}
                        dataSource={item.bloques}
                        pagination={false}
                      />
                    </>
                  )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
ReporteBase.propTypes = {
  reporte: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ReporteBase;

import { ELEMENTO_PATH } from '../routes/path';
import { api } from '../services/api';
import { SENSOR_PATH, FETCH_TIPO_SENSOR_PATH } from '../services/path';
import { showErrorMessage, showSuccessMessage } from '../utils/utils';
import {
  CREATE_SENSOR,
  DELETE_SENSOR,
  EDITAR_SENSOR,
  FETCH_SENSOR,
  FETCH_SENSOR_BY_ID,
  FETCH_SENSOR_BY_ID_GRAFICA,
  FETCH_TARJETA_BY_ELEMENTO,
  FETCH_TIPO_SENSOR,
  LIMPIAR_ESTADO_SENSOR,
} from './types';

export const fetchSensor = (perPage, offSet) => (dispatch) => dispatch({
  type: FETCH_SENSOR,
  promise: api.get(`${SENSOR_PATH}?perPage=${perPage}&offSet=${offSet}`)
});
export const fetchTipoSensor = () => (dispatch) => dispatch({
  type: FETCH_TIPO_SENSOR,
  promise: api.get(FETCH_TIPO_SENSOR_PATH)
});

export const fetcthTarjetaByElemento = (id) => (dispatch) => dispatch({
  type: FETCH_TARJETA_BY_ELEMENTO,
  promise: api.get(`${ELEMENTO_PATH}/${id}/tarjetas`)
});

export const fetchSensorById = (id) => (dispatch) => dispatch({
  type: FETCH_SENSOR_BY_ID,
  promise: api.get(`${SENSOR_PATH}/${id}`)
});

export const fetchSensorByIdGrafica = (id) => (dispatch) => dispatch({
  type: FETCH_SENSOR_BY_ID_GRAFICA,
  promise: api.get(`${SENSOR_PATH}/${id}`)
});

export const limpiarSensor = () => (dispatch) => dispatch({
  type: LIMPIAR_ESTADO_SENSOR,
});

export const createSensor = (formData, callback) => (dispatch) => dispatch({
  type: CREATE_SENSOR,
  promise: api.post(SENSOR_PATH, formData),
  meta: {
    onSuccess: () => {
      callback();
      showSuccessMessage('El sensor se creó correctamente', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  }
});

export const updateSensor = (id, formData, callback) => (dispatch) => dispatch({
  type: EDITAR_SENSOR,
  promise: api.patch(`${SENSOR_PATH}/${id}`, formData),
  meta: {
    onSuccess: () => {
      callback();
      showSuccessMessage('El sensor se edito correctamente', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  }
});
export const deleteSensor = (id, callback) => (dispatch) => dispatch({
  type: DELETE_SENSOR,
  promise: api.delete(`${SENSOR_PATH}/${id}`),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El sensor se elimino correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  },
});

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../actions/app';
import Logo from '../../assets/logo/logo_blanco.png';
import { ROOT_PAGE_PATH } from '../../routes/path';
import FormLogin from './form';

const Login = () => {
  const [form, setForm] = useState({
    nombre: '',
    password: '',
  });
  const token = useSelector((state) => state.appReducer.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (token.accessToken) {
      navigate(ROOT_PAGE_PATH);
    }
  }, [token]);

  const handleChange = (newForm) => {
    setForm(newForm);
  };

  const handleSubmit = () => {
    dispatch(login(form.nombre, form.password));
  };

  return (
    <div className="login-container">
      <div className="login-container__box">
        <div className="login-container__logo-container">
          <img className="login-container__logo" src={Logo} alt="visor MAPX" />
        </div>
        <div className="login-container__form-container">
          <FormLogin
            data={form}
            onSubmit={handleSubmit}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;

/* eslint-disable consistent-return */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import { Modal, Popconfirm, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addSensor,
  deleteGrafica,
  deleteSensor,
  fetchElementoGrafica,
  fetchGraficaById,
  fetchGraficaByIdRefresh,
  fetchSensoresGrafica,
  fetchTarjetaGrafica,
  limpiarGraficaEstado,
} from '../../../actions/grafica';
import Bread from '../../../components/breadcrumb';
import CardGrafica from '../../../components/card-grafica';
import Sankey from '../../../components/sankey';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { niveles } from '../../../utils/niveles';
import { routeGraficaInfo } from '../../../utils/routesBread';
import AgregarSensores from '../agregar-sensores';
import FormGrafica from '../form';
import Button from '../../../components/button';
import { GRAFICA_PATH } from '../../../routes/path';

const InfoGrafica = () => {
  const {
    elementos, tarjetas, grafica, sensores, isLoading
  } = (useSelector((state) => state.graficaReducer));
  const [sensorFiltered, setSensorFiltered] = useState();
  const { width } = useWindowDimensions();
  const [showModal, setShowModal] = useState(false);
  const tipoSensores = (useSelector((state) => state.sensorReducer.tiposensores));
  const [form, setForm] = useState({
    tipografica: '',
    nombre: '',
    tipo: '',
    nivel: '',
    proyecto: '',
    elemento: '',
    tarjeta: ''
  });
  const [formSensor, setFormSensor] = useState({
    nivel: '',
    proyecto: '',
    elemento: '',
    tarjeta: '',
    sensor: ''
  });
  const [error, setError] = useState('');
  const [disabled] = useState(true);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchGraficaById(id));
  }, []);

  useEffect(() => {
    setForm({
      tipografica: grafica && grafica?.tipografica,
      nombre: grafica && grafica.nombre,
      tipo: grafica && grafica.tipo._id,
      nivel: grafica && grafica.nivel,
      proyecto: grafica && grafica?.proyecto?._id,
      elemento: grafica && grafica.elemento && grafica?.elemento?._id,
      tarjeta: grafica && grafica.tarjeta && grafica?.tarjeta?._id
    });
  }, [grafica]);

  useEffect(() => {
    if (grafica?.tipografica.nombre !== 'Sankey') {
      const intervalId = setInterval(() => dispatch(fetchGraficaByIdRefresh(id)), 30000);
      return (() => {
        clearInterval(intervalId);
      });
    }
    return () => {
      dispatch(limpiarGraficaEstado());
    };
  }, []);

  useEffect(() => {
    if (formSensor.elemento) {
      dispatch(fetchTarjetaGrafica(formSensor.elemento));
    }
    if (formSensor.tarjeta) {
      dispatch(fetchSensoresGrafica(formSensor.tarjeta, grafica.tipo._id, id));
    }
  }, [formSensor.elemento, formSensor.tarjeta]);

  useEffect(() => {
    const listFiltered = sensores?.filter((item) => item !== null);
    setSensorFiltered(listFiltered);
  }, [sensores]);

  const handleShow = () => {
    setError('');
    setShowModal(!showModal);
    if (showModal) {
      setFormSensor({
        nivel: grafica && grafica.nivel,
        proyecto: grafica && grafica?.proyecto?._id,
        elemento: grafica && grafica.elemento && grafica?.elemento?._id,
        tarjeta: grafica && grafica.tarjeta && grafica?.tarjeta?._id
      });
      setSensorFiltered([]);
    } else {
      setFormSensor({
        nivel: grafica && grafica.nivel,
        proyecto: grafica && grafica?.proyecto?._id,
        elemento: grafica && grafica.elemento && grafica?.elemento?._id,
        tarjeta: grafica && grafica.tarjeta && grafica?.tarjeta?._id
      });
      if (grafica?.nivel === 'elemento' || grafica?.nivel === 'proyecto') {
        dispatch(fetchElementoGrafica(grafica.proyecto && grafica.proyecto._id));
      }
      if (grafica?.nivel === 'tarjeta') {
        dispatch(fetchElementoGrafica(grafica.proyecto._id));
        dispatch(fetchTarjetaGrafica(grafica.elemento._id));
        dispatch(fetchSensoresGrafica(
          grafica && grafica.tarjeta && grafica?.tarjeta?._id,
          grafica.tipo._id,
          id
        ));
      }
    }
  };

  const handleChange = (newForm) => {
    setFormSensor(newForm);
  };

  const handleAddSensores = () => {
    if (formSensor.sensor) {
      const sensorGrafica = {
        grafica: id,
        sensor: formSensor.sensor
      };
      dispatch(addSensor(sensorGrafica, id));
    }
  };

  const handleRemoveSensores = (index) => {
    dispatch(deleteSensor(index, id));
  };

  const redirect = () => {
    navigate(GRAFICA_PATH);
  };

  const handleRemoveGrafica = () => {
    dispatch(deleteGrafica(id, redirect));
  };

  return (
    <div>
      <div className="header-nav">
        <Bread routes={routeGraficaInfo} />
        <Popconfirm
          title="¿Estás seguro que deseas eliminar la gráfica?"
          onConfirm={handleRemoveGrafica}
          okText="Si"
          cancelText="Cancelar"
          placement="right"
        >
          <Button type="submit" text="Eliminar Grafica" color="redd" />
        </Popconfirm>
      </div>
      <div className="info-container">
        {isLoading
          ? (
            <div className="container-spin">
              <Spin tip="Cargando..." />
            </div>
          )
          : (
            <>
              {grafica === undefined && isLoading === true
                ? (
                  <p className="info-container__nothing">Los detalles de la grafica no se encontrarón</p>
                )
                : (
                  <div>
                    <FormGrafica
                      disabled={disabled}
                      niveles={niveles}
                      data={form}
                      proyectos={grafica ? [grafica?.proyecto] : []}
                      tipoSensores={grafica ? [grafica?.tipo] : []}
                      elementos={grafica ? [grafica?.elemento] : []}
                      tarjetas={grafica ? [grafica?.tarjeta] : []}
                      onShow={handleShow}
                      hideButton={grafica?.tipografica?.nombre === 'Sankey' && true}
                    />
                    {grafica?.sensores && (
                      <div>
                        <p className="text-sensor">Sensores Agregados</p>
                        {grafica?.sensores[0]?.data === undefined
                          ? (
                            <div className="not-sensores">
                              <p>
                                No tienes sensores configurados para tu grafica
                              </p>
                            </div>
                          )
                          : (
                            <CardGrafica
                              data={grafica?.sensores}
                              title={grafica?.nombre}
                              onRemoveSensor={handleRemoveSensores}
                              id="sensoresgraficaId"
                            />
                          )}
                      </div>
                    )}
                    {grafica?.graficaSankey && (
                      <Sankey
                        title={grafica?.nombre}
                        data={grafica}
                      />
                    )}
                  </div>
                )}
            </>
          )}
      </div>
      <Modal
        open={showModal}
        onCancel={handleShow}
        footer={null}
        style={{ width: (80 * (width / 100)), minWidth: (80 * (width / 100)) }}
      >
        <AgregarSensores
          niveles={niveles}
          data={formSensor}
          proyectos={grafica && [grafica?.proyecto]}
          tipoSensores={tipoSensores}
          tarjetas={tarjetas}
          elementos={elementos}
          onChange={handleChange}
          sensores={sensorFiltered}
          onAddSenores={handleAddSensores}
          onClose={handleShow}
          error={error}
          setError={setError}
        />
      </Modal>
    </div>
  );
};

export default InfoGrafica;

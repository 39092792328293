/* eslint-disable max-len */
import { PROYECTO_PATH } from '../routes/path';
import { api } from '../services/api';
import {
  TARJETA_PATH
} from '../services/path';
import { showErrorMessage, showSuccessMessage } from '../utils/utils';
import {
  CREATE_TARJETA,
  DELETE_TARJETA,
  EDITAR_TARJETA,
  FETCH_ELEMENTO_BY_PROYECTO,
  FETCH_TARJETA,
  FETCH_TARJETA_BY_ID,
  FETCH_TARJETA_BY_ID_GRAFICA,
  FETCH_TARJETA_NOT_PAGINATION,
  LIMPIAR_ESTADO_TARJETA
} from './types';

export const fetchTarjeta = (perPage, offSet) => (dispatch) => dispatch({
  type: FETCH_TARJETA,
  promise: api.get(`${TARJETA_PATH}?perPage=${perPage}&offSet=${offSet}`),
});

export const fetchTarjetaById = (id) => (dispatch) => dispatch({
  type: FETCH_TARJETA_BY_ID,
  promise: api.get(`${TARJETA_PATH}/${id}`)
});

export const fetchTarjetaByIdGrafica = (id) => (dispatch) => dispatch({
  type: FETCH_TARJETA_BY_ID_GRAFICA,
  promise: api.get(`${TARJETA_PATH}/${id}`)
});

export const limpiarTarjeta = () => (dispatch) => dispatch({
  type: LIMPIAR_ESTADO_TARJETA,
});

export const fetchTarjetaNotPagination = () => (dispatch) => dispatch({
  type: FETCH_TARJETA_NOT_PAGINATION,
  promise: api.get(`${TARJETA_PATH}`)
});

export const fetchElementoByProyecto = (id) => (dispatch) => dispatch({
  type: FETCH_ELEMENTO_BY_PROYECTO,
  promise: api.get(`${PROYECTO_PATH}/${id}/elementos`)
});

export const createTarjeta = (formData, callback) => (dispatch) => dispatch({
  type: CREATE_TARJETA,
  promise: api.post(TARJETA_PATH, formData),
  meta: {
    onSuccess: () => {
      callback();
      showSuccessMessage('Tarjeta creada correctamente', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  }
});

export const updateTarjeta = (id, formData, callback) => (dispatch) => dispatch({
  type: EDITAR_TARJETA,
  promise: api.patch(`${TARJETA_PATH}/${id}`, formData),
  meta: {
    onSuccess: () => {
      callback();
      showSuccessMessage('Tarjeta editada correctamente', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  }
});
export const deleteTarjeta = (id, callback) => (dispatch) => dispatch({
  type: DELETE_TARJETA,
  promise: api.delete(`${TARJETA_PATH}/${id}`),
  meta: {
    onSuccess: () => {
      showSuccessMessage('Tarjeta se elimino correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  },
});

export const parserUsuarios = ({
  _id,
  nombre,
  celular,
  estado,
  email,
  puesto,
  rol,
  empresa
}) => ({
  _id,
  nombre,
  celular,
  estado,
  email,
  puesto,
  rol,
  empresa: empresa?.nombre
});

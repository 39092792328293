/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { colunnsReporteAcumulado } from '../../../utils/parsers/table';
import { parserReportesAcumulado } from '../../../utils/parsers/reporte';
import { formatDate } from '../../../utils/parsers/date';

const ReporteAcumulado = ({
  reporte, periodo, fechaInicio, fechaFin
}) => {
  const dataGraficaParser = () => {
    const objetivos = reporte?.sensores.map((sensor) => sensor.objetivo);
    const total = reporte?.sensores.map((sensor) => sensor.total);
    const fechas = reporte?.sensores.map((sensor) => formatDate(new Date(sensor.fecha)));
    const seriesOptions = {
      series: [{
        name: 'Objetivo',
        data: objetivos
      }, {
        name: 'Real',
        data: total
      }],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
            tools: {
              download: false
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        colors: ['#77B6EA', '#545454'],
        grid: {
          row: {
            colors: 'Transparent', // takes an array which will be repeated on columns
            opacity: 0
          },
        },
        markers: {
          size: 5
        },
        xaxis: {
          categories: fechas,
        },
        zoom: false,
      },
    };
    return seriesOptions;
  };

  const dataTable = () => {
    const data = reporte?.sensores?.map((sensor) => parserReportesAcumulado(sensor, periodo));
    return data;
  };

  return (
    <div>
      {reporte.sensores.length !== 0
        ? (
          <div className="info-container__card-reporte info-container__card-reporte--mobile">
            <div className="info-container__title-reporte">
              <p>Bloques</p>
            </div>
            <div className="info-container__table-bloques">
              <div className="info-container__grafica-acumulado">
                <ReactApexChart
                  options={dataGraficaParser()?.options}
                  series={dataGraficaParser()?.series}
                  type="line"
                  height={500}
                  width={700}
                />
              </div>
              <div>
                <p className="text-reporte-1">{reporte?.nombre}</p>
                <p className="text-reporte-2">{`(${formatDate(fechaInicio)} - ${formatDate(fechaFin)})`}</p>
              </div>
              <Table
                columns={colunnsReporteAcumulado(
                  reporte?.tipoSensorPrincipal?.nombre,
                  reporte?.tipoSensorSecundario,
                )}
                dataSource={dataTable()}
                pagination={false}
              />
            </div>
          </div>
        )
        : <p className="text-nothing">No se encontrarón objetivos para el periodo seleccionado</p>}
    </div>
  );
};
ReporteAcumulado.propTypes = {
  reporte: PropTypes.shape().isRequired,
  periodo: PropTypes.string,
  fechaInicio: PropTypes.any.isRequired,
  fechaFin: PropTypes.any.isRequired,
};
ReporteAcumulado.defaultProps = {
  periodo: '',
};
export default ReporteAcumulado;

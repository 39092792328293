import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Input from '../../../components/input';
import Select from '../../../components/select';
import { thereIsErrorInForm, validateField } from '../../../utils/validations';
import Button from '../../../components/button';

const FormElemento = ({
  data, onSubmit, onChange, disabled = false, proyectos, empresas
}) => {
  const [error, setError] = useState({
    nombre: '',
    descripcion: '',
    area: '',
    linea: '',
    proyecto: '',
    proceso: '',
    tipo: '',
    empresa: ''
  });
  const { pathname } = useLocation();

  const [isFormComplete, setIsFormComplete] = useState(false);

  useEffect(() => {
    setIsFormComplete(
      data.nombre
      && data.descripcion
      && data.area
      && data.linea
      && data.proyecto
      && data.empresa
      && data.proceso
      && data.tipo
    );
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const nombreError = validateField('nombre', data.nombre);
    const descripcionError = validateField('descripcion', data.descripcion);
    const areaError = validateField('area', data.area);
    const lineaError = validateField('linea', data.linea);
    const proyectoError = validateField('proyecto', data.proyecto);
    const empresaError = validateField('empresa', data.empresa);
    const procesoError = validateField('proceso', data.proceso);
    const tipoError = validateField('tipo', data.tipo);
    const errorMessage = {
      ...error,
      ...nombreError,
      ...descripcionError,
      ...areaError,
      ...lineaError,
      ...proyectoError,
      ...empresaError,
      ...procesoError,
      ...tipoError,
    };
    setError(errorMessage);
    if (!thereIsErrorInForm(errorMessage)) {
      onSubmit();
    }
  };

  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };

  const handleBlur = (key, value) => {
    const fieldError = validateField(key, value);
    setError({
      ...error,
      ...fieldError,
    });
  };
  return (
    <form>
      <div className="form-info-container">

        <div className="form-info-container__box-container">
          <div className="form-info-container__box">
            <Input
              placeholder=""
              name="nombre"
              label="Elemento"
              value={data.nombre}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              placeholder=""
              name="descripcion"
              label="Descripcion"
              value={data.descripcion}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              placeholder=""
              name="area"
              label="Área"
              value={data.area}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              placeholder=""
              name="linea"
              label="Línea"
              value={data.linea}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form-info-container__box">
            {pathname.includes('info') && (
            <Input
              placeholder=""
              name="empresa"
              label="Empresa"
              value={data.empresa}
              disabled={disabled}
            />
            )}
            {(pathname.includes('crear') || pathname.includes('editar')) && (
            <Select
              placeholder=""
              name="empresa"
              label="Empresa"
              value={data.empresa}
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
              options={empresas}
            />
            )}
            {pathname.includes('info') && (
            <Input
              placeholder=""
              name="proyecto"
              label="Proyectos"
              value={data.proyecto}
              disabled={disabled}
            />
            )}
            {(pathname.includes('crear') || pathname.includes('editar')) && (
            <Select
              placeholder=""
              name="proyecto"
              label="Proyectos"
              value={data.proyecto}
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
              options={proyectos}
            />
            )}
            <Input
              placeholder=""
              name="proceso"
              label="Proceso"
              value={data.proceso}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              placeholder=""
              name="tipo"
              label="Tipo"
              value={data.tipo}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-info-container__button">
          { (pathname.includes('crear') || pathname.includes('editar'))
            && (
              <div className="form-info-container__button-edit">
                <Button
                  type="submit"
                  text="Guardar elemento"
                  color="gray"
                  onClick={handleSubmit}
                  disabled={!isFormComplete}
                />
              </div>
            )}
        </div>
      </div>
    </form>
  );
};
FormElemento.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  proyectos: PropTypes.arrayOf(PropTypes.shape()),
  empresas: PropTypes.arrayOf(PropTypes.shape()),
};

FormElemento.defaultProps = {
  onSubmit: () => {},
  onChange: () => {},
  proyectos: [],
  empresas: [],
};

export default FormElemento;

/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Pagination, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchTarjeta } from '../../actions/tarjeta';
import Accordion from '../../components/accordion';
import Bread from '../../components/breadcrumb';
import Card from '../../components/card';
import { columnsTarjetas, parserDataTabla } from '../../utils/parsers/table';
import { routeTarjeta } from '../../utils/routesBread';
import { usePagination } from '../../hooks/usePagination';
import AccordionSensores from './accordion-sensores';
import { parserTarjeta } from '../../utils/parsers/tarjeta';
import Button from '../../components/button';
import { CREAR_TARJETA_PATH } from '../../routes/path';

const Tarjeta = () => {
  const {
    dataSource, total, handlePage, page, isLoading
  } = usePagination(fetchTarjeta, 'tarjeta');
  const navigate = useNavigate();
  const { rol } = useSelector((state) => state.userReducer.user);
  const modifiedColumns = rol !== 'empresa'
    ? [{ dataIndex: 'id', title: 'ID' }, ...columnsTarjetas]
    : columnsTarjetas;

  return (
    <div className="page-container">
      <div className="header-nav">
        <Bread routes={routeTarjeta} />
        {rol !== 'empresa' && (
        <Button
          type="button"
          text="Crear nueva tarjeta"
          onClick={() => navigate(CREAR_TARJETA_PATH)}
          color="gray"
        />
        )}
      </div>
      <div className="page-container__box">
        <Accordion keyPanel="tarjeta" header="Tarjetas">
          {isLoading
            ? (
              <div className="container-spin">
                <Spin tip="Cargando..." />
              </div>
            )
            : (
              <>
                {dataSource && dataSource.length !== 0 ? (
                  dataSource.map((item) => (
                    <div key={item._id}>
                      <Card
                        columns={modifiedColumns}
                        data={[parserDataTabla(parserTarjeta(item))]}
                        header={item.nombre}
                        route="info-tarjeta"
                      />
                      <AccordionSensores data={item} />
                    </div>
                  ))
                ) : <p className="text-nothing">No hay datos para mostrar</p>}
              </>
            )}
        </Accordion>
        {dataSource
        && (
        <div className="page-container__pagination">
          <Pagination current={page} onChange={handlePage} total={total} />
        </div>
        )}
      </div>
    </div>
  );
};

export default Tarjeta;

import { api } from '../services/api';
import { ELEMENTO_PATH } from '../services/path';
import { showErrorMessage, showSuccessMessage } from '../utils/utils';
import {
  CREATE_ELEMENTO,
  DELETE_ELEMENTO,
  EDITAR_ELEMENTO,
  FETCH_ELEMENTO,
  FETCH_ELEMENTO_BY_ID,
  FETCH_ELEMENTO_BY_ID_GRAFICA,
  FETCH_ELEMENTO_NOT_PAGINATION,
  LIMPIAR_ESTADO_ELEMENTO
} from './types';

export const fetchElemento = (perPage, offSet) => (dispatch) => dispatch({
  type: FETCH_ELEMENTO,
  promise: api.get(`${ELEMENTO_PATH}?perPage=${perPage}&offSet=${offSet}`)
});

export const fetchElementoById = (id) => (dispatch) => dispatch({
  type: FETCH_ELEMENTO_BY_ID,
  promise: api.get(`${ELEMENTO_PATH}/${id}`)
});

export const fetchElementoByIdGrafica = (id) => (dispatch) => dispatch({
  type: FETCH_ELEMENTO_BY_ID_GRAFICA,
  promise: api.get(`${ELEMENTO_PATH}/${id}`)
});

export const fetchElementoNotPagination = () => (dispatch) => dispatch({
  type: FETCH_ELEMENTO_NOT_PAGINATION,
  promise: api.get(`${ELEMENTO_PATH}`)
});

export const limpiarElemento = () => (dispatch) => dispatch({
  type: LIMPIAR_ESTADO_ELEMENTO,
});

export const createElemento = (formData, callback) => (dispatch) => dispatch({
  type: CREATE_ELEMENTO,
  promise: api.post(ELEMENTO_PATH, formData),
  meta: {
    onSuccess: () => {
      callback();
      showSuccessMessage('El elemento se creó correctamente', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  }
});

export const updateElemento = (id, formData, callback) => (dispatch) => dispatch({
  type: EDITAR_ELEMENTO,
  promise: api.patch(`${ELEMENTO_PATH}/${id}`, formData),
  meta: {
    onSuccess: () => {
      callback();
      showSuccessMessage('El elemento se edito correctamente', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  }
});
export const deleteElemento = (id, callback) => (dispatch) => dispatch({
  type: DELETE_ELEMENTO,
  promise: api.delete(`${ELEMENTO_PATH}/${id}`),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El elemento se elimino correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  },
});

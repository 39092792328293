import {
  DELETE_USER, SET_USER, SET_USER_HEADER, LOGOUT, LIMPIAR_ESTADO_USUARIO
} from '../actions/types';
import { handleAction } from '../utils/handleAction';

const initialState = {
  user: {
    nombre: '',
    estado: '',
    email: '',
    password: '',
    celular: '',
    puesto: '',
    telegram: '',
    rol: '',
  },
  isLoading: false,
  error: '',
};

const profileReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER:
      return {
        ...state,
        token: action.tokens,
      };
    case DELETE_USER:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({ ...prevState }),
      });
    case SET_USER_HEADER:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({ ...prevState, user: payload.data }),
      });
    case LIMPIAR_ESTADO_USUARIO:
      return { ...initialState };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default profileReducer;

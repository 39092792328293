/* eslint-disable import/no-cycle */
import { CHANGE_PASSWORD_PATH, LOGIN_PATH, RESET_PASSWORD_PATH } from '../services/path';
import {
  LOGIN, LOGOUT, RESET_PASS, SEND_EMAIL, SET_TOKEN
} from './types';
import { api, saveTokenAuthentication } from '../services/api';
import { showErrorMessage, showSuccessMessage } from '../utils/utils';
import { setUserHeader } from './user';

const setToken = (tokens) => (dispatch) => dispatch({
  type: SET_TOKEN,
  tokens,
  meta: {
    onSuccess: () => {
      saveTokenAuthentication(tokens);
    }
  }
});
const login = (nombre, password) => (dispatch) => dispatch({
  type: LOGIN,
  promise: api.post(LOGIN_PATH, {
    nombre,
    password,
  }),
  meta: {
    onSuccess: (res) => {
      saveTokenAuthentication(res.data.tokens);
      dispatch(setUserHeader());
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

const logout = () => (dispatch) => dispatch({
  type: LOGOUT,
});

const sendEmail = (email, callback) => (dispatch) => dispatch({
  type: SEND_EMAIL,
  promise: api.post(RESET_PASSWORD_PATH, {
    email
  }),
  meta: {
    onSuccess: (res) => {
      showSuccessMessage(res.data.message, dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
      callback();
    }

  }
});
const newPassword = (data) => (dispatch) => dispatch({
  type: RESET_PASS,
  promise: api.post(CHANGE_PASSWORD_PATH, data),
  meta: {
    onSuccess: (res) => {
      showSuccessMessage(res.data.message, dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }

  }
});
export {
  logout,
  login,
  setToken,
  sendEmail,
  newPassword
};

/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  createEmpresa, editEmpresa, fetchEmpresaById, fetchEmpresaNotPagination, limpiarEmpresa
} from '../../../actions/empresa';
import FormCreateEditEmpresa from '../form';
import Bread from '../../../components/breadcrumb';
import { routeEmpresaCreate, routeEmpresaEdit } from '../../../utils/routesBread';
import { EMPRESA_PATH } from '../../../routes/path';
import { parserData } from '../../../utils/parsers/formData';

const CreateEmpresa = () => {
  const [form, setForm] = useState({
    nombre: '',
    planta: '',
    descripcion: '',
    estado: 'activo',
    imagen: '',
  });
  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { empresa } = useSelector((state) => state.empresaReducer);

  const handleChange = (newForm) => {
    setForm(newForm);
  };

  const redirect = () => {
    navigate(EMPRESA_PATH);
  };

  const redirectProfile = () => {
    navigate(`/info-empresa/${id}`);
  };

  const handleSubmitCreate = () => {
    dispatch(createEmpresa((form), redirect));
  };
  const handleSubmitEdit = () => {
    const dataNotImage = {
      nombre: form.nombre,
      planta: form.planta,
      descripcion: form.descripcion
    };
    if (typeof form.imagen === 'string') {
      dispatch(editEmpresa(id, dataNotImage, redirectProfile));
    } else {
      dispatch(editEmpresa(id, parserData(form), redirectProfile));
    }
  };

  useEffect(() => {
    dispatch(fetchEmpresaNotPagination());
    dispatch(fetchEmpresaById(id));
    return () => {
      dispatch(limpiarEmpresa());
    };
  }, []);

  useEffect(() => {
    setForm({
      nombre: empresa && empresa.nombre,
      planta: empresa && empresa.planta,
      descripcion: empresa && empresa.descripcion,
      imagen: empresa && empresa.imagen,
    });
  }, [empresa]);

  return (
    <>
      <div className="header-nav">
        <Bread routes={pathname.includes('crear-empresa') ? routeEmpresaCreate : routeEmpresaEdit} />
      </div>
      <div className="info-container">
        {pathname.includes('crear')
          ? (
            <FormCreateEditEmpresa
              data={form}
              onSubmit={handleSubmitCreate}
              onChange={handleChange}
            />
          )
          : (
            <FormCreateEditEmpresa
              data={form}
              onSubmit={handleSubmitEdit}
              onChange={handleChange}
              isEdit
            />
          )}
      </div>
    </>
  );
};

export default CreateEmpresa;

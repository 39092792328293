/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import Input from '../../../components/input';
import { thereIsErrorInForm, validateField } from '../../../utils/validations';
import Button from '../../../components/button';
import { RESET_PASS_PATH } from '../../../routes/path';

const FormLogin = ({ data, onSubmit, onChange }) => {
  const [type, setType] = useState('password');
  const [error, setError] = useState({
    nombre: '',
    password: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const nombreError = validateField('nombre', data.nombre);
    const passwordError = validateField('password', data.password);
    const errorMessage = {
      ...error,
      ...nombreError,
      ...passwordError,
    };
    setError(errorMessage);
    if (!thereIsErrorInForm(errorMessage)) {
      onSubmit();
    }
  };

  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };

  const handleBlur = (key, value) => {
    const fieldError = validateField(key, value);
    setError({
      ...error,
      ...fieldError,
    });
  };

  const changePassword = () => {
    if (type === 'password') setType('text');
    if (type === 'text') setType('password');
  };

  return (
    <form className="form-login-container" onSubmit={handleSubmit}>
      <h1 className="form-login-container__title">Iniciar sesión</h1>
      <p className="form-login-container__subtitle">
        Inicia sesión con tu cuenta
      </p>
      <div className="form-login-container__box">
        <Input
          placeholder="Nombre de usuario"
          name="nombre"
          value={data.nombre}
          inputType="text"
          onChange={handleChange}
          onBlur={handleBlur}
          login
          error={error.nombre}
        />
        <div className="form-info-container__pass">
        <Input
          placeholder="Contraseña"
          name="password"
          value={data.password}
          inputType={type}
          onChange={handleChange}
          onBlur={handleBlur}
          login
          error={error.password}
        />
        <button
          type="button"
          onClick={changePassword}
        >
          {type === 'password' ? <EyeOutlined /> : <EyeInvisibleOutlined /> }
        </button>
        </div>
          <Link className="reset-pass-text" to={RESET_PASS_PATH}>¿Desea restablecer contraseña?</Link>

        <div className="form-login-container__button">
          <Button type="submit" text="Iniciar sesión" color="primary" />
        </div>
      </div>
    </form>
  );
};
FormLogin.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.shape({
    nombre: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
};
export default FormLogin;

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Popconfirm, Spin } from 'antd';
import Bread from '../../../components/breadcrumb';
import { routeUsuarioInfo } from '../../../utils/routesBread';
import FormUsuario from '../form';
import { deleteUser, fetchUsuarioById, limpiarUsuario } from '../../../actions/user';
import Button from '../../../components/button';
import { fetchEmpresaNotPagination } from '../../../actions/empresa';
import { USUARIO_PATH } from '../../../routes/path';

const InfoUsuario = () => {
  const { usuario, isLoading } = useSelector((state) => state.usuarioReducer);
  const { empresas } = useSelector((state) => state.empresaReducer);

  const [form, setForm] = useState({
    email: '',
    nombre: '',
    usuario: '',
    celular: '',
    puesto: '',
    empresa: '',
  });
  const [disabled, setDisabled] = useState(true);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchUsuarioById(id));
    dispatch(fetchEmpresaNotPagination());
    return () => {
      dispatch(limpiarUsuario());
    };
  }, []);

  const redirect = () => {
    navigate(USUARIO_PATH);
  };

  const handleDelete = () => {
    dispatch(deleteUser(id, redirect));
  };

  useEffect(() => {
    setForm({
      email: usuario && usuario.email,
      nombre: usuario && usuario.nombre,
      usuario: usuario && usuario.usuario,
      celular: usuario && usuario.celular,
      puesto: usuario && usuario.puesto,
      empresa: usuario && usuario.empresa && usuario.empresa._id
    });
  }, [usuario]);

  const redirectEdit = () => {
    navigate(`/editar-usuario/${id}`);
  };

  return (
    <div>
      <div className="header-nav">
        <Bread routes={routeUsuarioInfo} />
        <Popconfirm
          title="¿Estás seguro que deseas eliminar el usuario?"
          onConfirm={handleDelete}
          okText="Si"
          cancelText="Cancelar"
          placement="right"
        >
          <Button type="submit" text="Eliminar usuario" color="redd" />
        </Popconfirm>
      </div>
      <div className="info-container">
        {isLoading
          ? (
            <div className="container-spin">
              <Spin tip="Cargando..." />
            </div>
          )
          : (
            <>
              {usuario === undefined
                ? (
                  <p className="info-container__nothing">Los detalles del usuario no se encontrarón</p>
                )
                : (
                  <FormUsuario
                    data={form}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    empresas={empresas}
                  />
                )}
            </>
          )}
        <div className="form-info-container__button-edit">
          {disabled && (
          <Button
            type="submit"
            text="Editar usuario"
            color="gray"
            onClick={redirectEdit}
          />
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoUsuario;

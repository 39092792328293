/* eslint-disable max-len */
import {
  LOGIN, SET_FLASH_MESSAGE, SET_TOKEN, LOGOUT
} from '../actions/types';
import { handleAction } from '../utils/handleAction';

const initialState = {
  isLoading: true,
  user: {},
  token: {},
  flashMessageContent: {
    text: '',
    type: '',
    isVisible: false
  }
};
const appReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TOKEN:
      return {
        ...state,
        isLoading: false,
        token: action.tokens
      };
    case SET_FLASH_MESSAGE:
      return {
        ...state,
        flashMessageContent: {
          ...state.flashMessageContent,
          ...action.flashMessageContent
        }
      };
    case LOGIN:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          token: payload.data.tokens
        }),
      });

    case LOGOUT:
      return {
        ...initialState,
        isLoading: false,
      };
    default:
      return state;
  }
};
export default appReducer;

/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Table } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';

const Card = ({
  columns, data, header, route, onChange, selectedRadio, btnInfo
}) => {
  const { pathname } = useLocation();
  const handleChange = (e) => {
    onChange('sensor', e.target.value);
  };
  return (
    <div className="card-container">
      <div className="card-container__header">
        <p>{header}</p>
        <div className="card-container__header-object">
          {window.screen.width < 600
            && (
              <div>
                {pathname.includes('info-grafica')
                  ? (
                    <div>
                      <Radio checked={selectedRadio === data[0]._id} value={data[0]._id} onChange={handleChange} buttonStyle="solid" />
                    </div>
                  )
                  : (
                    <div>
                      <Link to={`/${route}/${data[0]._id}`}>
                        <InfoCircleOutlined />
                      </Link>
                    </div>
                  )}
              </div>
            )}
        </div>
      </div>
      <div className="card-container__body">
        <div className="card-container__table">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            size="small"
          />
        </div>
        {window.screen.width > 600
          && (
            <>
              {pathname.includes('info-grafica')
                ? (
                  <div className="card-container__radio">
                    <Radio checked={selectedRadio === data[0]._id} value={data[0]._id} onChange={handleChange} buttonStyle="solid" />
                  </div>
                )
                : (
                  (btnInfo === true && (
                  <div className="card-container__info">
                    <Link to={`/${route}/${data[0]._id}`}>
                      <InfoCircleOutlined />
                    </Link>
                  </div>
                  ))
                )}
            </>
          )}
      </div>
    </div>
  );
};

Card.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string
  })).isRequired,
  header: PropTypes.string.isRequired,
  route: PropTypes.string,
  onChange: PropTypes.func,
  selectedRadio: PropTypes.string,
  btnInfo: PropTypes.bool
};
Card.defaultProps = {
  onChange: () => { },
  selectedRadio: '',
  btnInfo: true,
  route: ''
};

export default Card;

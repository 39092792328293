/* eslint-disable react/jsx-no-useless-fragment */
import { Pagination, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchElemento, limpiarElemento } from '../../actions/elemento';
import Accordion from '../../components/accordion';
import Bread from '../../components/breadcrumb';
import Card from '../../components/card';
import { usePagination } from '../../hooks/usePagination';
import { parserElementos } from '../../utils/parsers/elemento';
import { columnsElementos, parserDataTabla } from '../../utils/parsers/table';
import { routeElemento } from '../../utils/routesBread';
import AccordionElemento from './accordion-elemento';
import Button from '../../components/button';
import { CREAR_ELEMENTO_PATH } from '../../routes/path';

const Elemento = () => {
  const {
    dataSource, total, handlePage, page, isLoading
  } = usePagination(fetchElemento, 'elemento');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rol } = useSelector((state) => state.userReducer.user);

  useEffect(() => () => {
    dispatch(limpiarElemento());
  }, []);

  return (
    <div className="page-container">
      <div className="header-nav">
        <Bread routes={routeElemento} />
        {rol !== 'empresa' && (
        <Button
          type="button"
          text="Crear nuevo elemento"
          onClick={() => navigate(CREAR_ELEMENTO_PATH)}
          color="gray"
        />
        )}
      </div>
      <div className="page-container__box">
        <Accordion keyPanel="elementos" header="Elementos">
          {isLoading
            ? (
              <div className="container-spin">
                <Spin tip="Cargando..." />
              </div>
            )
            : (
              <>
                {dataSource
              && dataSource.length !== 0 ? (
                    dataSource.map((item) => (
                      <div key={parserElementos(item).id}>
                        <Card
                          columns={columnsElementos}
                          data={[parserDataTabla(item)]}
                          header={item.nombre}
                          route="info-elemento"
                        />
                        <AccordionElemento data={item} />
                      </div>
                    ))
                  ) : <p className="text-nothing">No hay datos para mostrar</p>}
              </>
            )}
        </Accordion>
        {dataSource
        && (
        <div className="page-container__pagination">
          <Pagination current={page} onChange={handlePage} total={total} />
        </div>
        )}
      </div>
    </div>
  );
};

export default Elemento;

/* eslint-disable react/forbid-prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import Elemento from './elemento';
import Button from '../../../components/button';

const opcionesReportes = ['General', 'Bloques'];
const ConfiguracionAvanzada = ({
  sensores, onChange, sensoresChecked, setChecked, allSensores, disabled
}) => {
  const handleCheckSensor = (id) => {
    if (sensoresChecked.includes(id)) {
      const newSensoresChecked = sensoresChecked.filter((s) => s !== id);
      setChecked(newSensoresChecked);
      onChange('sensores', newSensoresChecked);
    } else {
      setChecked([...sensoresChecked, id]);
      onChange('sensores', [...sensoresChecked, id]);
    }
  };
  return (
    <div className="form-info-container">
      <div className="form-info-container__box-container-reporte">
        <Checkbox.Group
          disabled={disabled}
          key="seccion"
          options={opcionesReportes}
          defaultValue={['General', 'Bloques']}
          onChange={(value) => onChange('seccion', value)}
        />
        <br />
        <br />

        {Object.keys(sensores).length !== 0 && (
          <div>
            {sensores?.length === 0
              ? <p className="text-nothing">No hay elementos para mostrar</p>
              : (sensores?.map((item) => (
                <div key={item.id}>
                  <Elemento
                    id={item.id}
                    nombre={item.nombre}
                    tarjetas={item.tarjetas}
                    sensoresChecked={sensoresChecked}
                    onCheckSensor={handleCheckSensor}
                    disabled={disabled}
                  />
                </div>

              )))}
          </div>
        )}
        {disabled === false && (
        <div className="button-selec">
          <Button
            text="Seleccionar"
            color="gray"
            onClick={() => { onChange('sensores', allSensores); setChecked(allSensores); }}
          />
          <Button
            text="Deseleccionar"
            color="gray"
            onClick={() => { onChange('sensores', []); setChecked([]); }}
          />
        </div>
        )}
      </div>
    </div>
  );
};

ConfiguracionAvanzada.propTypes = {
  onChange: PropTypes.func,
  sensores: PropTypes.any,
  sensoresChecked: PropTypes.any,
  setChecked: PropTypes.func,
  allSensores: PropTypes.any,
  disabled: PropTypes.bool.isRequired
};
ConfiguracionAvanzada.defaultProps = {
  sensores: [],
  sensoresChecked: [],
  onChange: () => { },
  setChecked: () => {},
  allSensores: undefined
};
export default ConfiguracionAvanzada;

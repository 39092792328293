/* eslint-disable no-undef */
import axios from 'axios';
import toast from 'react-hot-toast';
import { REFRESH } from './path';

const { REACT_APP_URL_API } = process.env;
export const api = axios.create({
  baseURL: REACT_APP_URL_API,
});

export const setAuthenticationRefresh = () => {
  api.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('refreshToken')}`;
};
export const setAuthentication = () => {
  api.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
};

export const setChangeAuthentication = () => {
  api.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
};
export const cancelarRequestReporte = (url, data) => {
  const { CancelToken } = api;
  const source = CancelToken.source();
  api.post(url, data, source);
  return () => {
    source.cancel('Canceled');
  };
};
export const removeTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

export const saveTokenAuthentication = ({ accessToken, refreshToken }) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  setAuthentication(accessToken);
};
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;
    if (error.response.data.statusCode === 401
      && !originalConfig.url.includes(REFRESH)) {
      setAuthenticationRefresh();
      const res = await api.get(REFRESH);
      saveTokenAuthentication(res.data);
      return api(`${originalConfig.baseURL}/${originalConfig.url}`);
    }
    if (error.response.data.statusCode === 401
        && originalConfig.url.includes(REFRESH)) {
      removeTokens();
      window.location.reload(false);
    }
    toast.error(error.response.data.message);
    return Promise.reject(error);
  }
);

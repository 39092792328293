/* eslint-disable max-len */
import { api } from '../services/api';
import { PROYECTO_PATH } from '../services/path';
import { showErrorMessage, showSuccessMessage } from '../utils/utils';
import {
  CREATE_PROYECTO,
  DELETE_PROYECTO,
  EDITAR_PROYECTO,
  FETCH_PROYECTO, FETCH_PROYECTO_BY_ID, FETCH_PROYECTO_BY_ID_GRAFICA, FETCH_PROYECTO_NOT_PAGINATION, LIMPIAR_ESTADO_PROYECTO
} from './types';

export const fetchProyecto = (perPage, offSet) => (dispatch) => dispatch({
  type: FETCH_PROYECTO,
  promise: api.get(`${PROYECTO_PATH}?perPage=${perPage}&offSet=${offSet}`)
});

export const fetchProyectoById = (id) => (dispatch) => dispatch({
  type: FETCH_PROYECTO_BY_ID,
  promise: api.get(`${PROYECTO_PATH}/${id}`)
});
export const fetchProyectoByIdGrafica = (id) => (dispatch) => dispatch({
  type: FETCH_PROYECTO_BY_ID_GRAFICA,
  promise: api.get(`${PROYECTO_PATH}/${id}`)
});

export const fetchProyectoNotPagination = () => (dispatch) => dispatch({
  type: FETCH_PROYECTO_NOT_PAGINATION,
  promise: api.get(`${PROYECTO_PATH}`)
});

export const limpiarProyecto = () => (dispatch) => dispatch({
  type: LIMPIAR_ESTADO_PROYECTO,
});

export const createProyecto = (formData, callback) => (dispatch) => dispatch({
  type: CREATE_PROYECTO,
  promise: api.post(PROYECTO_PATH, formData),
  meta: {
    onSuccess: () => {
      callback();
      showSuccessMessage('El proyecto se creó correctamente', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  }
});

export const updateProyecto = (id, formData, callback) => (dispatch) => dispatch({
  type: EDITAR_PROYECTO,
  promise: api.patch(`${PROYECTO_PATH}/${id}`, formData),
  meta: {
    onSuccess: () => {
      callback();
      showSuccessMessage('El proyecto se edito correctamente', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  }
});
export const deleteProyecto = (id, callback) => (dispatch) => dispatch({
  type: DELETE_PROYECTO,
  promise: api.delete(`${PROYECTO_PATH}/${id}`),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El proyecto se elimino correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  },
});

/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Bread from '../../../components/breadcrumb';
import FormSensor from '../form';
import { routeSensorCreate, routeSensorEdit } from '../../../utils/routesBread';
import { SENSOR_PATH } from '../../../routes/path';
import {
  createSensor, fetchSensorById, fetchTipoSensor,
  fetcthTarjetaByElemento, limpiarSensor, updateSensor
} from '../../../actions/sensor';
import { fetchElementoByProyecto, limpiarTarjeta } from '../../../actions/tarjeta';
import { fetchEmpresaNotPagination, fetchProyectoByEmpresa } from '../../../actions/empresa';
import { limpiarElemento } from '../../../actions/elemento';
import { limpiarProyecto } from '../../../actions/proyecto';

const CrearSensor = () => {
  const [form, setForm] = useState({
    nombre: undefined,
    alias: undefined,
    unidades: undefined,
    rangoMin: undefined,
    rangoMax: undefined,
    resolucionMin: undefined,
    resolucionMax: undefined,
    peligrosidad: undefined,
    peligrosidadAjuste: undefined,
    posicionEnTrama: undefined,
    tipo: undefined,
    tarjeta: undefined,
    elemento: undefined,
    proyecto: undefined,
    empresa: undefined,
    consumo: undefined,
  });
  const [selectedEmpresa, setSelectedEmpresa] = useState('');
  const [selectedProyecto, setSelectedProyecto] = useState('');
  const [selectedElemento, setSelectedElemento] = useState('');
  const { tarjetas } = useSelector((state) => state.tarjetaReducer);
  const { empresas } = useSelector((state) => state.empresaReducer);
  const { proyectos } = useSelector((state) => state.proyectoReducer);
  const { elementos } = useSelector((state) => state.elementoReducer);
  const { sensor, tiposensores } = useSelector((state) => state.sensorReducer);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setForm({
      nombre: sensor && sensor.nombre,
      alias: sensor && sensor.alias,
      unidades: sensor && sensor.unidades,
      rangoMin: sensor && sensor.rangoMin.$numberDecimal,
      rangoMax: sensor && sensor.rangoMax.$numberDecimal,
      resolucionMin: sensor && sensor.resolucionMin.$numberDecimal,
      resolucionMax: sensor && sensor.resolucionMax.$numberDecimal,
      peligrosidad: sensor && sensor.peligrosidad.$numberDecimal,
      peligrosidadAjuste: sensor && sensor.peligrosidadAjuste.$numberDecimal,
      posicionEnTrama: sensor && sensor.posicionEnTrama,
      tipo: sensor && sensor.tipo._id,
      tarjeta: sensor && sensor.tarjeta?._id,
      elemento: sensor && sensor.tarjeta?.elemento?._id,
      proyecto: sensor && sensor.tarjeta?.elemento?.proyecto?._id,
      empresa: sensor && sensor.tarjeta?.elemento?.proyecto?.empresa?._id,
      consumo: sensor && sensor.consumo,
    });
    dispatch(limpiarElemento());
    dispatch(limpiarProyecto());
    dispatch(limpiarElemento());
    dispatch(limpiarTarjeta());
    setSelectedEmpresa(sensor?.tarjeta?.elemento?.proyecto?.empresa?._id);
    setSelectedProyecto(sensor?.tarjeta?.elemento?.proyecto?._id);
    setSelectedElemento(sensor?.tarjeta?.elemento?._id);
  }, [sensor]);

  useEffect(() => {
    dispatch(fetchEmpresaNotPagination());
    dispatch(fetchTipoSensor());
    if (pathname.includes('editar')) {
      dispatch(fetchSensorById(id));
    }
    return () => {
      dispatch(limpiarSensor());
    };
  }, []);

  const redirectSensores = () => {
    navigate(SENSOR_PATH);
  };

  const redirectProfile = () => {
    navigate(`/info-sensor/${id}`);
  };

  const handleChange = (newForm) => {
    if (newForm?.empresa !== form?.empresa) {
      // Se ha cambiado la empresa
      setForm({
        ...newForm,
        proyecto: '',
        elemento: '',
        tarjeta: ''
      });
      dispatch(limpiarElemento());
      dispatch(limpiarTarjeta());
    } else if (newForm?.proyecto !== form?.proyecto) {
      // Se ha cambiado solo el proyecto
      setForm({
        ...newForm,
        elemento: ''
      });
    } else {
      // No ha cambiado ni la empresa ni el proyecto
      setForm(newForm);
    }
    setSelectedEmpresa(newForm?.empresa);
    setSelectedProyecto(newForm?.proyecto);
    setSelectedElemento(newForm?.elemento);
  };

  useEffect(() => {
    if (selectedEmpresa) dispatch(fetchProyectoByEmpresa(selectedEmpresa));
  }, [selectedEmpresa]);

  useEffect(() => {
    if (selectedProyecto) dispatch(fetchElementoByProyecto(selectedProyecto));
  }, [selectedProyecto]);

  useEffect(() => {
    if (selectedElemento) dispatch(fetcthTarjetaByElemento(selectedElemento));
  }, [selectedElemento]);

  const handleCreate = () => {
    delete form.empresa;
    delete form.proyecto;
    delete form.elemento;
    dispatch(createSensor(form, redirectSensores));
  };

  const handleEdit = () => {
    delete form.empresa;
    delete form.proyecto;
    delete form.elemento;
    dispatch(updateSensor(id, form, redirectProfile));
  };
  return (
    <>
      <div className="header-nav">
        <Bread routes={pathname.includes('crear-sensor') ? routeSensorCreate : routeSensorEdit} />
      </div>
      <div className="info-container">
        {pathname.includes('crear')
          ? (
            <FormSensor
              data={form}
              tarjetas={tarjetas}
              tiposensores={tiposensores}
              onChange={handleChange}
              onSubmit={handleCreate}
              empresas={empresas}
              proyectos={proyectos}
              elementos={elementos}
            />
          ) : (
            <FormSensor
              data={form}
              tarjetas={tarjetas}
              tiposensores={tiposensores}
              onChange={handleChange}
              onSubmit={handleEdit}
              empresas={empresas}
              proyectos={proyectos}
              elementos={elementos}

            />
          )}
      </div>
    </>
  );
};

export default CrearSensor;

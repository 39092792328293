/* eslint-disable no-undef */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/react-in-jsx-scope */
import { Link } from 'react-router-dom';
import Icon from '../components/icon';
import {
  ELEMENTO_PATH, EMPRESA_PATH, GRAFICA_PATH, PROYECTO_PATH, REPORTES_PATH, SENSOR_PATH,
  TARJETA_PATH, USUARIO_PATH
} from '../routes/path';

export const menuItems = [{
  key: USUARIO_PATH,
  label: <Link to={USUARIO_PATH} className="link">
    <Icon name="usuario" />
    Usuario
  </Link>
},
{
  key: EMPRESA_PATH,
  label: <Link to={EMPRESA_PATH} className="link">
    <Icon name="empresa" />
    Empresa
  </Link>,
},
{
  key: PROYECTO_PATH,
  label: <Link to={PROYECTO_PATH} className="link">
    <Icon name="proyecto" />
    Proyecto
  </Link>
},
{
  key: ELEMENTO_PATH,
  label: <Link to={ELEMENTO_PATH} className="link">
    <Icon name="elemento" />
    Elemento
  </Link>
},
{
  key: TARJETA_PATH,
  label: <Link to={TARJETA_PATH} className="link">
    <Icon name="tarjeta" />
    Tarjeta
  </Link>
},
{
  key: SENSOR_PATH,
  label: <Link to={SENSOR_PATH} className="link">
    <Icon name="sensor" />
    Sensor
  </Link>
},
{
  key: GRAFICA_PATH,
  label: <Link to={GRAFICA_PATH} className="link">
    <Icon name="grafica" />
    Gráfica
  </Link>
},
{
  key: REPORTES_PATH,
  label: <Link to={REPORTES_PATH} className="link">
    <Icon name="reporte" />
    Reportes
  </Link>
},
];

export const menuItemsEmpresa = [
  {
    key: PROYECTO_PATH,
    label: <Link to={PROYECTO_PATH} className="link">
      <Icon name="proyecto" />
      Proyecto
    </Link>
  },
  {
    key: ELEMENTO_PATH,
    label: <Link to={ELEMENTO_PATH} className="link">
      <Icon name="elemento" />
      Elemento
    </Link>
  },
  {
    key: TARJETA_PATH,
    label: <Link to={TARJETA_PATH} className="link">
      <Icon name="tarjeta" />
      Tarjeta
    </Link>
  },
  {
    key: SENSOR_PATH,
    label: <Link to={SENSOR_PATH} className="link">
      <Icon name="sensor" />
      Sensor
    </Link>
  },
  {
    key: GRAFICA_PATH,
    label: <Link to={GRAFICA_PATH} className="link">
      <Icon name="grafica" />
      Gráfica
    </Link>
  },
  {
    key: REPORTES_PATH,
    label: <Link to={REPORTES_PATH} className="link">
      <Icon name="reporte" />
      Reportes
    </Link>
  },
];

import { formatDate } from './date';

export const parserObjetivo = ({
  _id,
  tipoSensor,
  valor,
  fecha,
  fechaCreacion

}) => ({
  _id,
  tipoSensor: tipoSensor?.nombre,
  objetivo: valor,
  fecha: formatDate(new Date(fecha)),
  fechaDeCreacion: formatDate(new Date(fechaCreacion))
});

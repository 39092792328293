/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../../components/accordion';
import { columnsSensores, columnsTarjetas, parserDataTabla } from '../../../utils/parsers/table';
import Card from '../../../components/card';
import { parserTarjeta } from '../../../utils/parsers/tarjeta';
import { parserSensores } from '../../../utils/parsers/sensor';

const AccordionElemento = ({ data }) => (
  <div>
    {data && data.tarjetas
    && (
    <div className="page-container__accordion">
      <Accordion header="Tarjetas">
        {data.tarjetas.length !== 0 ? (
          <>
            {data.tarjetas.map((tarjeta) => (
              <div key={tarjeta._id}>
                <Card
                  columns={columnsTarjetas}
                  data={[parserDataTabla(parserTarjeta(tarjeta))]}
                  header={tarjeta.nombre}
                  route="info-tarjeta"
                />
                {tarjeta && tarjeta.sensores && (
                <Accordion header="Sensores">
                  {tarjeta.sensores.length !== 0 ? (
                    <>
                      {tarjeta.sensores.map((sensor) => (
                        <Card
                          columns={columnsSensores}
                          data={[parserDataTabla(parserSensores(sensor))]}
                          header={sensor.nombre}
                          route="info-sensor"
                          sensores
                          key={sensor.nombre}
                        />
                      ))}
                    </>
                  )
                    : <p className="text-nothing">No hay datos para mostrar</p>}
                </Accordion>
                )}
              </div>
            ))}
          </>
        )
          : <p className="text-nothing">No hay datos para mostrar</p>}
      </Accordion>
    </div>
    )}
  </div>
);
AccordionElemento.propTypes = {
  data: PropTypes.shape({
    tarjetas: PropTypes.arrayOf(PropTypes.shape({
      sensores: PropTypes.arrayOf(PropTypes.shape({
        nombre: PropTypes.string
      }))
    }))
  }),
};

AccordionElemento.defaultProps = {
  data: undefined,
};

export default AccordionElemento;

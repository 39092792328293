import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Input from '../../../components/input';
import Select from '../../../components/select';
import { thereIsErrorInForm, validateField } from '../../../utils/validations';
import Button from '../../../components/button';
import CheckboxInput from '../../../components/inputCheckbox';

const FormSensor = ({
  data, onSubmit, onChange, disabled = false, tarjetas, tiposensores, empresas, proyectos, elementos
}) => {
  const { pathname } = useLocation();

  const [isFormComplete, setIsFormComplete] = useState(false);

  useEffect(() => {
    setIsFormComplete(
      data.nombre
      && data.alias
      && data.unidades
      && data.rangoMin
      && data.rangoMax
      && data.resolucionMin
      && data.resolucionMax
      && data.peligrosidad
      && data.peligrosidadAjuste
      && data.posicionEnTrama
      && data.tipo
      && data.tarjeta
    );
  }, [data]);

  const [error, setError] = useState({
    nombre: undefined,
    alias: undefined,
    unidades: undefined,
    rangoMin: undefined,
    rangoMax: undefined,
    resolucionMin: undefined,
    resolucionMax: undefined,
    peligrosidad: undefined,
    peligrosidadAjuste: undefined,
    posicionEnTrama: undefined,
    tipo: undefined,
    tarjeta: undefined,
  });

  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };

  const handleBlur = (key, value) => {
    const fieldError = validateField(key, value);
    setError({
      ...error,
      ...fieldError,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const nombreError = validateField('nombre', data.nombre);
    const aliasError = validateField('alias', data.alias);
    const unidadesError = validateField('unidades', data.unidades);
    const rangoMinError = validateField('rangoMin', data.rangoMin);
    const rangoMaxError = validateField('rangoMax', data.rangoMax);
    const peligrosidadError = validateField('peligrosidad', data.peligrosidad);
    const peligrosidadAjusteError = validateField('peligrosidadAjuste', data.peligrosidadAjuste);
    const posicionEnTramaError = validateField('posicionEnTrama', data.posicionEnTrama);
    const tipoError = validateField('tipo', data.tipo);
    const tarjetaError = validateField('tarjeta', data.tarjeta);
    const errorMessage = {
      ...error,
      ...nombreError,
      ...aliasError,
      ...unidadesError,
      ...rangoMinError,
      ...rangoMaxError,
      ...peligrosidadError,
      ...peligrosidadAjusteError,
      ...posicionEnTramaError,
      ...tipoError,
      ...tarjetaError,
    };
    setError(errorMessage);
    if (!thereIsErrorInForm(errorMessage)) {
      onSubmit();
    }
  };

  return (
    <form>
      <div className="form-info-container">
        <div className="form-info-container__box-container">
          <div className="form-info-container__box">
            <Select
              placeholder=""
              name="tipo"
              label="Tipo"
              value={data.tipo}
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
              options={tiposensores}
            />
            <Input
              name="nombre"
              label="Nombre"
              value={data.nombre}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              name="alias"
              label="Alias"
              value={data.alias}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              name="unidades"
              label="Unidades"
              value={data.unidades}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              name="rangoMin"
              label="Rango Minimo"
              value={data.rangoMin}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              name="rangoMax"
              label="Rango Maximo"
              value={data.rangoMax}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {(pathname.includes('crear') || pathname.includes('editar')) && (
              <Select
                placeholder=""
                name="empresa"
                label="Empresas"
                value={data.empresa}
                disabled={disabled}
                onChange={handleChange}
                options={empresas}
              />
            )}
            {(pathname.includes('crear') || pathname.includes('editar')) && (
            <Select
              placeholder=""
              name="proyecto"
              label="Proyecto"
              value={data.proyecto}
              disabled={disabled}
              onChange={handleChange}
              options={proyectos}
            />
            )}
            {pathname.includes('info-sensor') && (
              <Input
                placeholder=""
                name="empresa"
                label="Empresas"
                value={data.empresa}
                disabled={disabled}
              />
            )}
            {pathname.includes('info-sensor') && (
            <Input
              placeholder=""
              name="proyecto"
              label="Proyecto"
              value={data.proyecto}
              disabled={disabled}
            />
            )}
          </div>
          <div className="form-info-container__box">
            {(pathname.includes('crear') || pathname.includes('editar')) && (
            <Select
              placeholder=""
              name="elemento"
              label="Elemento"
              value={data.elemento}
              disabled={disabled}
              onChange={handleChange}
              options={elementos}
            />
            )}
            {pathname.includes('info-sensor') && (
            <Input
              placeholder=""
              name="elemento"
              label="Elemento"
              value={data.elemento}
              disabled={disabled}
            />
            )}
            {(pathname.includes('crear') || pathname.includes('editar')) && (
            <Select
              placeholder=""
              name="tarjeta"
              label="Tarjeta"
              value={data.tarjeta}
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
              options={tarjetas}
            />
            )}
            {pathname.includes('info-sensor') && (
            <Input
              placeholder=""
              name="tarjeta"
              label="Tarjeta"
              value={data.tarjeta}
              disabled={disabled}
            />
            )}
            <Input
              name="resolucionMin"
              label="Resolución Minima"
              value={data.resolucionMin}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              name="resolucionMax"
              label="Resolución Máxima"
              value={data.resolucionMax}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              name="peligrosidad"
              label="Peligrosidad"
              value={data.peligrosidad}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              name="peligrosidadAjuste"
              label="Peligrosidad ajuste"
              value={data.peligrosidadAjuste}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              name="posicionEnTrama"
              label="Posición en trama"
              value={(data.posicionEnTrama)}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <CheckboxInput
              name="consumo"
              label="Consumo"
              value={data.consumo || false}
              disabled={disabled}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-info-container__button">
          { (pathname.includes('crear') || pathname.includes('editar'))
            && (
              <div className="form-info-container__button-edit">
                <Button
                  type="submit"
                  text="Guardar sensor"
                  color="gray"
                  onClick={handleSubmit}
                  disabled={!isFormComplete}
                />
              </div>
            )}
        </div>
      </div>
    </form>
  );
};
FormSensor.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  tarjetas: PropTypes.arrayOf(PropTypes.shape()),
  proyectos: PropTypes.arrayOf(PropTypes.shape()),
  empresas: PropTypes.arrayOf(PropTypes.shape()),
  elementos: PropTypes.arrayOf(PropTypes.shape()),
  tiposensores: PropTypes.arrayOf(PropTypes.shape())
};

FormSensor.defaultProps = {
  onChange: () => {},
  onSubmit: () => {},
  tarjetas: [],
  disabled: false,
  proyectos: [],
  empresas: [],
  elementos: [],
  tiposensores: [],
};

export default FormSensor;

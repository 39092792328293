/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import { Popconfirm, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteProyecto, fetchProyectoById, fetchProyectoByIdGrafica, limpiarProyecto
} from '../../../actions/proyecto';
import Accordion from '../../../components/accordion';
import Bread from '../../../components/breadcrumb';
import { routeProyectoInfo } from '../../../utils/routesBread';
import AccordionProyecto from '../accordion-proyecto';
import FormProyecto from '../form';
import AccordionObjetivos from '../../../components/acordion-objetivos';
import Tab from '../../../components/tab';
import Button from '../../../components/button';
import { PROYECTO_PATH } from '../../../routes/path';
import { fetchEmpresaNotPagination } from '../../../actions/empresa';

const InfoProyecto = () => {
  const { proyecto, isLoading } = useSelector((state) => state.proyectoReducer);
  const { empresas } = useSelector((state) => state.empresaReducer);
  const { rol } = useSelector((state) => state.userReducer.user);

  const [form, setForm] = useState({
    nombre: '',
    descripcion: '',
    area: '',
    linea: '',
    empresa: '',
    proceso: '',
    identificacion: '',
    tipo: '',
  });
  const [disabled, setDisabled] = useState(true);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchEmpresaNotPagination());
    dispatch(fetchProyectoById(id));
    return () => {
      dispatch(limpiarProyecto());
    };
  }, []);

  useEffect(() => {
    setForm({
      nombre: proyecto && proyecto.nombre,
      descripcion: proyecto && proyecto.descripcion,
      area: proyecto && proyecto.area,
      linea: proyecto && proyecto.linea,
      empresa: proyecto && proyecto?.empresa?.nombre,
      proceso: proyecto && proyecto.proceso,
      identificacion: proyecto && proyecto.identificacion,
      tipo: proyecto && proyecto.tipo,
    });
  }, [proyecto]);

  useEffect(() => {
    const intervalId = setInterval(() => dispatch(fetchProyectoByIdGrafica(id)), 30000);
    return (() => {
      clearInterval(intervalId);
    });
  }, []);

  const editProyecto = () => {
    navigate(`/editar-proyecto/${id}`);
  };
  const redirect = () => {
    navigate(PROYECTO_PATH);
  };

  const handleDelete = () => {
    dispatch(deleteProyecto(id, redirect));
  };

  return (
    <div>
      <div className="header-nav">
        <Bread routes={routeProyectoInfo} />
        <Popconfirm
          title="¿Estás seguro que deseas eliminar el proyecto?"
          onConfirm={handleDelete}
          okText="Si"
          cancelText="Cancelar"
          placement="right"
        >

          {rol !== 'empresa'
          && <Button type="submit" text="Eliminar proyecto" color="redd" />}
        </Popconfirm>
      </div>
      <div className="info-container">
        {isLoading
          ? (
            <div className="container-spin">
              <Spin tip="Cargando..." />
            </div>
          )
          : (
            <>
              {proyecto && isLoading === true
                ? (
                  <p className="info-container__nothing">Los detalles del proyecto no se encontrarón</p>
                )
                : (
                  <>
                    {form && (

                    <FormProyecto
                      data={form}
                      disabled={disabled}
                      setDisabled={setDisabled}
                      empresas={empresas}
                    />
                    )}
                    <div className="form-info-container__button-edit">
                      {(disabled && rol !== 'empresa') && (
                        <Button
                          type="submit"
                          text="Editar proyecto"
                          color="gray"
                          onClick={editProyecto}
                        />
                      )}
                    </div>
                    <AccordionObjetivos />
                    <AccordionProyecto data={proyecto} />
                    <div className="info-container__grafica">
                      <Accordion header="Graficas" keyPanel="grafica">
                        {(proyecto?.graficas || proyecto?.graficaSankey)
                          && (
                            <Tab
                              dataGrafica={proyecto?.graficas}
                              dataSankey={proyecto?.graficasSankey}
                            />
                          )}
                      </Accordion>
                    </div>
                  </>
                )}
            </>
          )}
      </div>
    </div>
  );
};

export default InfoProyecto;

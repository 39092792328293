/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { Modal, Pagination, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Accordion from '../accordion';
import Button from '../button';
import Card from '../card';
import { parserObjetivo } from '../../utils/parsers/objetivos';
import { columnsObjetivos, parserDataTabla } from '../../utils/parsers/table';
import AgregarObjetivo from './agregar-objetivo';
import {
  agregarObjetivo, fetchObjetivoElemento, fetchObjetivoProyecto, fetchTipoSensor
} from '../../actions/objetivo';
import { usePagination } from '../../hooks/usePagination';

const AccordionObjetivos = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const {
    dataSource, total, handlePage, page, isLoading
  } = pathname.includes('proyecto')
    ? usePagination(fetchObjetivoProyecto, 'objetivo', id)
    : usePagination(fetchObjetivoElemento, 'objetivo', id);

  const [showModal, setShowModal] = useState(false);
  const [formObjetivo, setFormObjetivo] = useState({
    valor: '',
    fecha: '',
    tipoSensor: ''
  });
  const [error, setError] = useState({
    valor: '',
    fecha: '',
    tipoSensor: ''
  });

  const dispatch = useDispatch();
  const { tipoSensor } = useSelector((state) => state.objetivoReducer);

  const handleChange = (newForm) => {
    setFormObjetivo(newForm);
  };

  const onSuccess = () => {
    setFormObjetivo({
      valor: '',
      fecha: '',
      tipoSensor: ''
    });
    setError({
      valor: '',
      fecha: '',
      tipoSensor: ''
    });
    setShowModal(false);
    if (pathname.includes('proyecto')) dispatch(fetchObjetivoProyecto(10, 0, id));
    if (pathname.includes('elemento')) dispatch(fetchObjetivoElemento(10, 0, id));
  };

  const handleShow = () => {
    setShowModal(!showModal);
    if (showModal) {
      onSuccess();
    }
  };

  const handleSubmit = () => {
    let formParsed = {
      ...formObjetivo, fecha: new Date(formObjetivo.fecha)
    };
    if (pathname.includes('proyecto')) formParsed = { ...formParsed, proyecto: id };
    if (pathname.includes('elemento')) formParsed = { ...formParsed, elemento: id };
    dispatch(agregarObjetivo(formParsed, handleShow));
  };

  useEffect(() => {
    dispatch(fetchTipoSensor());
  }, []);

  return (
    <div>
      <Accordion header="Objetivos">
        <div className="info-container__objetivo">
          <Button
            type="button"
            text="Agregar objetivo"
            color="white"
            onClick={handleShow}
          />
        </div>
        {isLoading
          ? (
            <div className="container-spin">
              <Spin tip="Cargando..." />
            </div>
          )
          : (
            <>
              {dataSource && dataSource?.length !== 0 ? (
                <div>
                  { dataSource?.map((item) => (
                    <div key={item._id}>
                      <Card
                        columns={columnsObjetivos}
                        data={[parserDataTabla(parserObjetivo(item))]}
                        header={parserObjetivo(item).tipoSensor}
                        btnInfo={false}
                      />
                    </div>
                  ))}
                </div>
              )
                : <p className="text-nothing">No hay datos para mostrar</p>}
              <div className="page-container__pagination">
                <Pagination current={page} onChange={handlePage} total={total} />
              </div>
            </>
          )}

      </Accordion>
      <Modal
        width="40rem"
        open={showModal}
        onCancel={handleShow}
        footer={null}
      >
        <AgregarObjetivo
          data={formObjetivo}
          onSubmit={handleSubmit}
          onChange={handleChange}
          objetivosOption={tipoSensor}
          error={error}
          setError={setError}
        />
      </Modal>

    </div>
  );
};

export default AccordionObjetivos;

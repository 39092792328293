export const periodos = [
  {
    _id: 'Diario',
    nombre: 'Diario'
  },
  {
    _id: 'Mensual',
    nombre: 'Mensual'
  },
];

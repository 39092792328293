/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Spin } from 'antd';
import ReactToPrint from 'react-to-print';
import {
  fetchTipoReporte,
  fetchProyectoReporte,
  fetchElementoReporteById,
  fetchTarjetaReporteById,
  fetchSensoresProyectoReporteById,
  fetchSensoresElementoReporteById,
  fetchSensoresTarjetaReporteById,
  limpiarSensores,
  limpiarReporte,
  changeTipoReporte,
} from '../../actions/reporte';
import { niveles } from '../../utils/niveles';
import Bread from '../../components/breadcrumb';
import { routeReporte } from '../../utils/routesBread';
import GenerarReporte from './form';
import Logo from '../../assets/logo/logo_azul.png';
import Flogo_Logo from '../../assets/logo/Flogo_Logo.png';
import Button from '../../components/button';
import { fetchTipoSensor } from '../../actions/sensor';
import ReporteBase from './reporte-base';
import ReporteAcumulado from './reporte-acumulado';
import { api } from '../../services/api';
import { GENERAR_REPORTE_ACUMULADO_PATH, GENERAR_REPORTE_BASE_PATH } from '../../services/path';

export const newController = null;
const Reporte = () => {
  const {
    tipoReporte, proyectos, elementos, tarjetas, sensores, isLoading, isLoadingSensores
  } = (useSelector((state) => state.reporteReducer));
  const { tiposensores } = useSelector((state) => state.sensorReducer);
  const dispatch = useDispatch();
  const componentRef = useRef();
  // const [abortController, setAbortController] = useState();
  const [tipoReporteNombre, setTipoReporteNombre] = useState();
  const [elementosSensores, setElementosSensores] = useState([]);
  const [allSensores, setAllSensores] = useState([]);
  const [sensoresChecked, setSensoresChecked] = useState([]);
  const [reporte, setReporte] = useState(null);
  const [loadingGeneral, setLoadingGeneral] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  // const [loading, setLoading] = useState();
  const [form, setForm] = useState({
    tipoReporte: '',
    nivel: '',
    proyecto: '',
    elemento: '',
    tarjeta: '',
    fechaInicio: '',
    fechaFin: '',
    seccion: ['General', 'Bloques'],
    sensores: [],
    principal: '',
    secundario: '',
    conversion: '',
    periodo: ''
  });

  const handleChange = (newForm) => {
    const formToSet = {
      ...newForm,
      sensores: sensoresChecked
    };

    setForm(formToSet);
  };

  const generarBloques = async (form, report, sensoresLoadings) => {
    const sensores = [];
    let requestFailed = false;
    for (const sensor of form.sensores) {
      if (!requestFailed) {
        const formBloques = {
          ...form,
          sensores: [sensor],
          seccion: ['Bloques']
        };
        try {
          await api.post(`${GENERAR_REPORTE_BASE_PATH}`, formBloques)
            .then((res) => {
              const loadingIndex = sensoresLoadings.findIndex(
                (loading) => loading.loading === res.data.sensores[0]._id
              );
              if (loadingIndex !== -1) {
                sensoresLoadings.splice(loadingIndex, 1, ...res.data.sensores);
              } else {
                sensores.push(...res.data.sensores);
              }
              const rep = {
                empresa: report ? report.empresa : '',
                general: report ? report.general : null,
                sensores: sensoresLoadings
              };
              setReporte({ reporteBase: rep });
            })
            .catch(() => {
              requestFailed = true;
              setReporte(null);
              message.error('fallo la carga de bloques');
            });
        } catch (error) {
          requestFailed = true;
          console.error(error);
          message.error('fallo la carga de los bloques');
        }
      }
    }
    setDisabledButton(false);
  };

  const handleSubmit = async () => {
    setReporte(null);
    dispatch(changeTipoReporte());
    if (tipoReporteNombre === 'Base') {
      const formParse = {
        ...form,
        sensores: sensoresChecked,
        fechaFin: new Date(form.fechaFin),
        fechaInicio: new Date(form.fechaInicio),
      };
      delete formParse.periodo;
      delete formParse.principal;
      delete formParse.secundario;
      delete formParse.conversion;
      setDisabledButton(true);
      setLoadingGeneral(true);
      const loadings = await Promise.all(formParse.sensores.map((sensor) => ({ loading: sensor })));
      if (form.seccion.includes('General') && form.seccion.includes('Bloques')) {
        const formParseGeneral = { ...formParse, seccion: ['General'] };
        api.post(`${GENERAR_REPORTE_BASE_PATH}`, formParseGeneral)
          .then((res) => {
            setLoadingGeneral(false);
            if (res.data) {
              setReporte({ reporteBase: { ...res.data, sensores: loadings } });
              generarBloques(formParse, res.data, loadings);
            } else {
              setReporte(null);
              setDisabledButton(false);
            }
          })
          .catch((error) => {
            message.error('error ', error);
            setDisabledButton(false);
            setReporte(null);
          });
      } else if (form.seccion.includes('General')) {
        const formParseGeneral = { ...formParse, seccion: ['General'] };
        api.post(`${GENERAR_REPORTE_BASE_PATH}`, formParseGeneral)
          .then((res) => {
            setLoadingGeneral(false);
            if (res.data) {
              setReporte({ reporteBase: { ...res.data, sensores: [] } });
              setDisabledButton(false);
            } else {
              setReporte(null);
              setDisabledButton(false);
            }
          })
          .catch((error) => {
            message.error('error ', error);
            setDisabledButton(false);
            setReporte(null);
          });
      } else if (form.seccion.includes('Bloques')) {
        await generarBloques(formParse, null, loadings);
      }
    }
    if (tipoReporteNombre === 'Acumulado') {
      const formParse = {
        ...form,
        fechaFin: new Date(form.fechaFin),
        fechaInicio: new Date(form.fechaInicio)
      };
      delete formParse.tarjeta;
      delete formParse.sensores;
      delete formParse.seccion;
      setDisabledButton(true);
      api.post(`${GENERAR_REPORTE_ACUMULADO_PATH}`, formParse)
        .then((res) => {
          setReporte({ reporteAcumulado: { ...res.data } });
          setDisabledButton(false);
        })
        .catch(() => {
          setDisabledButton(false);
        });
    }
  };

  const selectAllCheckbox = (sensoresForm) => {
    const checkedSensores = sensoresForm?.map(
      (elemento) => elemento.tarjetas.map(
        (tarjeta) => tarjeta.sensores.map((sensor) => sensor.id)
      )
    ).flat(2);
    const newForm = { ...form, sensores: checkedSensores };
    setForm(newForm);
    setSensoresChecked(checkedSensores);
    setAllSensores(checkedSensores);
  };

  useEffect(() => {
    dispatch(fetchTipoReporte());

    return () => {
      dispatch(limpiarReporte());
    };
  }, []);

  useEffect(() => {
    dispatch(changeTipoReporte());
    const tipo = tipoReporte?.find((tipo) => tipo._id === form?.tipoReporte);
    setTipoReporteNombre(tipo?.nombre);
    if (tipo?.nombre === 'Acumulado') {
      dispatch(fetchTipoSensor());
    }
  }, [form.tipoReporte]);

  useEffect(() => {
    if (tiposensores !== undefined) {
      const tipoKilowatt = tiposensores.find((tipo) => tipo.nombre === 'Kilowatt')._id;
      const formPrincipal = { ...form, principal: tipoKilowatt };
      setForm(formPrincipal);
    }
  }, [tiposensores]);

  useEffect(() => {
    setReporte(null);
    if (form.nivel !== '') dispatch(fetchProyectoReporte());

    if (form.nivel === 'proyecto') {
      const formProyecto = {
        ...form, elemento: '', tarjeta: '', sensores: []
      };
      setForm(formProyecto);
      dispatch(limpiarSensores());
    }

    if (form.nivel === 'elemento') {
      if (form.proyecto !== '') {
        dispatch(fetchElementoReporteById(form.proyecto));
        const formElemento = {
          ...form, tarjeta: '', sensores: []
        };
        setForm(formElemento);
      }
    }

    if (form.nivel === 'tarjeta') {
      if (form.proyecto !== '') { dispatch(fetchElementoReporteById(form.proyecto)); }

      if (form.elemento !== '') {
        dispatch(fetchTarjetaReporteById(form.elemento));
      }
    }

    if (tipoReporteNombre === 'Base') {
      if (form.nivel === 'proyecto' && form.proyecto !== '') {
        dispatch(fetchSensoresProyectoReporteById(form.proyecto));
      }

      if (form.elemento !== '' && form.nivel === 'elemento') {
        dispatch(fetchSensoresElementoReporteById(form.elemento));
      }

      if (form.tarjeta !== '' && form.nivel === 'tarjeta') {
        dispatch(fetchSensoresTarjetaReporteById(form.tarjeta));
      }
    }

    if (form.elemento === '' && form.nivel === 'elemento') {
      dispatch(limpiarSensores());
    }

    if (form.nivel === 'tarjeta' && form.tarjeta === '' && form.elemento === '') {
      dispatch(limpiarSensores());
    }
  }, [form.nivel, form.proyecto, form.elemento, form.tarjeta, tipoReporteNombre]);

  useEffect(() => {
    let sensoresForm = [];
    if (form.nivel === 'tarjeta' && form.tarjeta !== '') {
      sensoresForm = [{
        id: sensores?.elemento?._id,
        nombre: sensores?.elemento?.nombre,
        tarjetas: [{
          id: sensores?.tarjeta?._id,
          nombre: tarjetas?.find((elemento) => elemento?._id === form.tarjeta)?.nombre,
          sensores: sensores?.sensores?.map(
            (sensor) => ({ id: sensor._id, nombre: sensor.nombre })
          )

        }]
      }];
    }

    if (form.nivel === 'elemento' && form.elemento !== '') {
      sensoresForm = [{
        id: form.elemento,
        nombre: elementos?.find((elemento) => elemento?._id === form.elemento)?.nombre,
        tarjetas: sensores?.tarjetas?.map(
          (tarjeta) => ({
            id: tarjeta._id,
            nombre: tarjeta.nombre,
            sensores: tarjeta.sensores.map(
              (sensor) => ({ id: sensor._id, nombre: sensor.nombre })
            )
          })
        )
      }];
    }

    if (form.nivel === 'proyecto' && form.proyecto !== '') {
      sensoresForm = sensores?.elementos?.map(
        (elemento) => ({
          id: elemento._id,
          nombre: elemento.nombre,
          tarjetas: elemento.tarjetas.map(
            (tarjeta) => ({
              id: tarjeta._id,
              nombre: tarjeta.nombre,
              sensores: tarjeta.sensores.map(
                (sensor) => ({ id: sensor._id, nombre: sensor.nombre })
              )
            })
          )
        })
      );
    }
    if (sensoresForm !== []) {
      selectAllCheckbox(sensoresForm);
    }
    setElementosSensores(sensoresForm);
  }, [sensores]);

  return (
    <div className="page-container">
      <div className="header-nav">
        <Bread routes={routeReporte} />
      </div>
      <div className="info-container">
        <div className="info-container__title">
          <h2>Reportes</h2>
        </div>
        <div className="info-container__form">
          <GenerarReporte
            tipoReporte={tipoReporteNombre}
            data={form}
            tipoReportes={tipoReporte}
            tipoSensores={tiposensores}
            proyectos={proyectos}
            elementos={elementos}
            tarjetas={tarjetas}
            niveles={niveles}
            sensores={elementosSensores}
            isLoading={isLoadingSensores}
            sensoresChecked={sensoresChecked}
            onSubmit={handleSubmit}
            onChange={handleChange}
            setChecked={setSensoresChecked}
            allSensores={allSensores}
            disabledButton={disabledButton}
          />
        </div>
        {isLoading
          ? (
            <div>
              <div className="container-spin">
                <Spin tip="Cargando..." />
              </div>
            </div>
          )
          : (
            <div>
              { reporte
          && (
          <div>
            <ReactToPrint
              trigger={() => (
                <div className="form-info-container__button--center">
                  <Button
                    text="Imprimir Reporte (PDF)"
                    color="primary"
                  />
                </div>
              )}
              content={() => componentRef.current}
            />
            <div className="info-container__reporte" ref={componentRef}>
              <div className="info-container__encabezado-mobile info-container__encabezado">
                <img className="logo" src={Logo} width="250px" />
                <img className="empresa" src={reporte?.empresa?.imagen ? reporte.empresa.imagen : Flogo_Logo} width="100px" />
              </div>
              <div className="info-container__nombre-empresa info-container__nombre-empresa-mobile">
                <p>{reporte?.empresa?.nombre ? reporte.empresa.nombre : ''}</p>
              </div>
              {reporte?.reporteBase && (
              <ReporteBase
                reporte={reporte?.reporteBase}
                loading={loadingGeneral}
              />
              )}
              {reporte?.reporteAcumulado && (
              <ReporteAcumulado
                reporte={reporte?.reporteAcumulado}
                periodo={form.periodo}
                fechaInicio={form.fechaInicio}
                fechaFin={form.fechaFin}
              />

              ) }
            </div>
          </div>
          )}
            </div>

          )}
      </div>
    </div>
  );
};

export default Reporte;

import { handle } from 'redux-pack';
import {
  FETCH_OBJETIVOS_TIPO_SENSOR, FETCH_OBJETIVO_ELEMENTO, FETCH_OBJETIVO_PROYECTO
} from '../actions/types';
import { handleAction } from '../utils/handleAction';

const initialState = {
  objetivos: [],
  isLoading: false,
  error: '',
};

const ObjetivoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_OBJETIVOS_TIPO_SENSOR:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          tipoSensor: payload.data
        }),
      });
    case FETCH_OBJETIVO_PROYECTO:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          objetivos: payload.data.data,
          total: payload.data.total
        }),
      });
    case FETCH_OBJETIVO_ELEMENTO:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          objetivos: payload.data.data,
          total: payload.data.total
        }),
      });
    default:
      return state;
  }
};

export default ObjetivoReducer;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */

import React from 'react';

const Sensor = ({
  id, nombre, checked, onCheckSensor, disabled
}) => (
  <div key={id} className="container-checkbox-sensor">
    <label>
      <input disabled={disabled} type="checkbox" checked={checked} onChange={() => onCheckSensor(id)} />
    </label>
    <p>{`${nombre} `}</p>
  </div>
);

export default Sensor;

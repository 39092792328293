/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../errorMessage';

const Select = ({
  name, value, onChange, onBlur, label, disabled = false, options, error
}) => {
  const handleChange = (e) => {
    e.preventDefault();
    onChange(name, e.target.value);
  };
  const handleBlur = (e) => {
    onBlur(name, e.target.value);
  };
  return (
    <div className="select-container">
      <p>{label}</p>
      <select
        name={name}
        className="select-container__select"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
      >
        <option value="" disabled className="select-container__option-default">Seleccione una opción</option>
        {
          options !== [undefined]
          && (
          <>
            {options?.map((item) => (
              <option key={String(item?._id)} value={item?._id}>{item?.nombre}</option>
            ))}
          </>
          )
        }
      </select>
      <ErrorMessage textError={error} />
    </div>

  );
};
Select.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()),
};
Select.defaultProps = {
  value: '',
  error: '',
  label: '',
  disabled: false,
  options: [],
  onBlur: () => {},
  onChange: () => {},
};

export default Select;

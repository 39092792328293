/* eslint-disable react/jsx-no-useless-fragment */
import { Pagination, Spin } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchSensor } from '../../actions/sensor';
import Accordion from '../../components/accordion';
import Bread from '../../components/breadcrumb';
import Card from '../../components/card';
import { usePagination } from '../../hooks/usePagination';
import { parserSensores } from '../../utils/parsers/sensor';
import { columnsSensores, parserDataTabla } from '../../utils/parsers/table';
import { routeSensor } from '../../utils/routesBread';
import Button from '../../components/button';
import { CREAR_SENSOR_PATH } from '../../routes/path';

const Sensor = () => {
  const {
    dataSource, total, handlePage, page, isLoading
  } = usePagination(fetchSensor, 'sensor');
  const navigate = useNavigate();
  const { rol } = useSelector((state) => state.userReducer.user);

  return (
    <div className="page-container">
      <div className="header-nav">
        <Bread routes={routeSensor} />
        {rol !== 'empresa' && (
        <Button
          type="button"
          text="Crear nuevo sensor"
          onClick={() => navigate(CREAR_SENSOR_PATH)}
          color="gray"
        />
        )}
      </div>
      <div className="page-container__box">
        <Accordion keyPanel="sensores" header="Sensores">
          {isLoading
            ? (
              <div className="container-spin">
                <Spin tip="Cargando..." />
              </div>
            )
            : (
              <>
                {dataSource
              && dataSource.length !== 0 ? (
                    dataSource.map((item) => (
                      <div key={item.nombre}>
                        <Card
                          columns={columnsSensores}
                          data={[parserDataTabla(parserSensores(item))]}
                          header={item.nombre}
                          route="info-sensor"
                          sensores
                        />
                      </div>
                    ))
                  )
                  : <p className="text-nothing">No hay datos para mostrar</p>}
              </>
            )}
        </Accordion>
        {dataSource
        && (
        <div className="page-container__pagination">
          <Pagination current={page} onChange={handlePage} total={total} />
        </div>
        )}
      </div>
    </div>
  );
};

export default Sensor;

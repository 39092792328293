/* eslint-disable max-len */
import { api } from '../services/api';
import {
  CREATE_EMPRESA_PATH, DELETE_EMPRESA_PATH, FETCH_EMPRESA_PATH, UPDATE_EMPRESA_PATH
} from '../services/path';
import { showErrorMessage, showSuccessMessage } from '../utils/utils';
import {
  FETCH_EMPRESA,
  UPDATE_EMPRESA,
  CREATE_EMPRESA,
  DELETE_EMPRESA,
  SET_EMPRESA,
  FETCH_EMPRESA_BY_ID,
  LIMPIAR_ESTADO_EMPRESA,
  FETCH_EMPRESA_NOT_PAGINATION,
  FETCH_PROYECTO_BY_EMPRESA
} from './types';

export const fetchEmpresa = (perPage, offSet) => (dispatch) => dispatch({
  type: FETCH_EMPRESA,
  promise: api.get(`${FETCH_EMPRESA_PATH}?perPage=${perPage}&offSet=${offSet}`)
});

export const fetchEmpresaNotPagination = () => (dispatch) => dispatch({
  type: FETCH_EMPRESA_NOT_PAGINATION,
  promise: api.get(`${FETCH_EMPRESA_PATH}`)
});

export const fetchEmpresaById = (id) => (dispatch) => dispatch({
  type: FETCH_EMPRESA_BY_ID,
  promise: api.get(`${FETCH_EMPRESA_PATH}/${id}`)
});

export const fetchProyectoByEmpresa = (id) => (dispatch) => dispatch({
  type: FETCH_PROYECTO_BY_EMPRESA,
  promise: api.get(`${FETCH_EMPRESA_PATH}/${id}/proyectos`)
});

export const createEmpresa = (formData, callback) => (dispatch) => dispatch({
  type: CREATE_EMPRESA,
  promise: api.post(CREATE_EMPRESA_PATH, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  meta: {
    onSuccess: () => {
      callback();
      showSuccessMessage('La empresa se creó correctamente', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  }
});
export const editEmpresa = (id, formData, callback) => (dispatch) => dispatch({
  type: UPDATE_EMPRESA,
  promise: api.patch(`${UPDATE_EMPRESA_PATH}/${id}`, formData, {
  }),
  meta: {
    onSuccess: () => {
      callback();
      showSuccessMessage('La empresa se editó correctamente', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  }
});

export const deleteEmpresa = (id, callback) => (dispatch) => dispatch({
  type: DELETE_EMPRESA,
  promise: api.delete(`${DELETE_EMPRESA_PATH}/${id}`),
  meta: {
    onSuccess: () => {
      dispatch(fetchEmpresa());
      callback();
      showSuccessMessage('La empresa se elimino correctamente', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  }
});

export const setEmpresa = (user) => (dispatch) => dispatch({
  type: SET_EMPRESA,
  user
});

export const limpiarEmpresa = () => (dispatch) => dispatch({
  type: LIMPIAR_ESTADO_EMPRESA,
});

import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import { thereIsErrorInForm, validateDate, validateField } from '../../../utils/validations';
import Button from '../../button';
import Input from '../../input';
import Select from '../../select';
import ErrorMessage from '../../errorMessage';

const AgregarObjetivo = ({
  data, onSubmit, onChange, objetivosOption, error, setError
}) => {
  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };
  const handleBlur = (key, value) => {
    const fieldError = validateField(key, value);
    setError({
      ...error,
      ...fieldError,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const valorError = validateField('valor', data.valor);
    const fechaError = validateDate('fecha', data.fecha);
    const tipoSensorError = validateField('tipoSensor', data.tipoSensor);
    const errorMessage = {
      ...error,
      ...valorError,
      ...fechaError,
      ...tipoSensorError,
    };
    setError(errorMessage);
    if (!thereIsErrorInForm(errorMessage)) {
      onSubmit();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-info-container">
        <div className="form-info-container__box-container-up">
          <h1>Objetivos</h1>
          <div className="form-objetivo">
            <Select
              label="Seleccionar tipo sensor"
              value={data.tipoSensor}
              onChange={handleChange}
              onBlur={handleBlur}
              options={objetivosOption}
              name="tipoSensor"
              error={error.tipoSensor}
            />

            <Input
              placeholder=""
              name="valor"
              label="Seleccionar valor"
              value={data.valor}
              inputType="text"
              onChange={handleChange}
              onBlur={handleBlur}
              error={error.valor}
            />
            <div className="input-container">
              <p>Fecha</p>
              <ReactDatePicker
                showIcon
                selected={data.fecha}
                onChange={(fecha) => handleChange('fecha', fecha)}
                dateFormat="dd/MM/yyyy"
                locale="es"
              />
              <ErrorMessage textError={error.fecha} />
            </div>
          </div>
        </div>
        <div className="form-info-container__button">
          <div className="form-info-container__button-edit">
            <Button
              text="Guardar objetivo"
              color="gray"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
AgregarObjetivo.propTypes = {
  data: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.shape().isRequired,
  setError: PropTypes.func.isRequired,
  objetivosOption: PropTypes.arrayOf(PropTypes.shape()),
};
AgregarObjetivo.defaultProps = {
  onSubmit: () => {},
  onChange: () => {},
  objetivosOption: [],
};

export default AgregarObjetivo;

/* eslint-disable max-len */
import { api } from '../services/api';
import {
  CREATE_USUARIO_PATH,
  DELETE_USUARIO_PATH,
  FETCH_USUARIO_PATH,
  SET_USER_HEADER_PATH,
  UPDATE_USUARIO_PATH,
} from '../services/path';
import { showErrorMessage, showSuccessMessage } from '../utils/utils';
import {
  FETCH_USER,
  UPDATE_USER,
  DELETE_USER,
  SET_USER,
  SET_USER_HEADER,
  FETCH_USUARIO_BY_ID,
  CREATE_USER,
  LIMPIAR_ESTADO_USUARIO
} from './types';

export const fetchUser = (perPage, offSet) => (dispatch) => dispatch({
  type: FETCH_USER,
  promise: api.get(`${FETCH_USUARIO_PATH}?perPage=${perPage}&offSet=${offSet}`),
});

export const createUsuario = (formData, callback) => (dispatch) => dispatch({
  type: CREATE_USER,
  promise: api.post(CREATE_USUARIO_PATH, formData),
  meta: {
    onSuccess: () => {
      callback();
      showSuccessMessage('El usuario se creó correctamente', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  }
});

export const fetchUsuarioById = (id) => (dispatch) => dispatch({
  type: FETCH_USUARIO_BY_ID,
  promise: api.get(`${FETCH_USUARIO_PATH}/${id}`)
});

export const editUser = (id, form, callback) => (dispatch) => dispatch({
  type: UPDATE_USER,
  promise: api.patch(`${UPDATE_USUARIO_PATH}/${id}`, form),
  meta: {
    onSuccess: () => {
      showSuccessMessage('Usuario editado correctamente', dispatch);
      if (callback && typeof callback === 'function') {
        callback();
      }
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    },
  },
});

export const deleteUser = (id, callback) => (dispatch) => dispatch({
  type: DELETE_USER,
  promise: api.delete(`${DELETE_USUARIO_PATH}/${id}`),
  meta: {
    onSuccess: () => {
      showSuccessMessage('Usuario eliminado correctamente', dispatch);
      if (callback && typeof callback === 'function') {
        callback();
      }
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  },
});

export const setUser = (user) => (dispatch) => dispatch({
  type: SET_USER,
  user,
});
export const setUserHeader = () => (dispatch) => dispatch({
  type: SET_USER_HEADER,
  promise: api.get(SET_USER_HEADER_PATH),
});

export const limpiarUsuario = () => (dispatch) => dispatch({
  type: LIMPIAR_ESTADO_USUARIO,
});

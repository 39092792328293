/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { Pagination, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchUser, limpiarUsuario } from '../../actions/user';
import Accordion from '../../components/accordion';
import Bread from '../../components/breadcrumb';
import Card from '../../components/card';
import { routeUsuario } from '../../utils/routesBread';
import { columnsUser, parserDataTabla } from '../../utils/parsers/table';
import { usePagination } from '../../hooks/usePagination';
import { parserUsuarios } from '../../utils/parsers/usuario';
import Button from '../../components/button';
import { CREAR_USUARIO_PATH } from '../../routes/path';

const Usuario = () => {
  const {
    dataSource, total, handlePage, page, isLoading
  } = usePagination(fetchUser, 'usuario');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(limpiarUsuario());
  }, []);

  return (
    <div className="page-container">
      <div className="header-nav">
        <Bread routes={routeUsuario} />
        <Button
          type="button"
          text="Crear nuevo usuario"
          onClick={() => navigate(CREAR_USUARIO_PATH)}
          color="gray"
        />
      </div>
      <div className="page-container__box">
        <Accordion keyPanel="usuarios" header="Usuarios">
          {isLoading
            ? (
              <div className="container-spin">
                <Spin tip="Cargando..." />
              </div>
            )
            : (
              <>
                {dataSource && dataSource.length !== 0 ? (
                  dataSource.map((item) => (
                    <div key={item._id}>
                      <Card
                        columns={columnsUser}
                        data={[parserDataTabla(parserUsuarios(item))]}
                        header={item.nombre}
                        route="info-usuario"
                      />
                    </div>
                  ))
                ) : <p className="text-nothing">No hay datos para mostrar</p>}
              </>
            )}
        </Accordion>
        {dataSource
        && (
        <div className="page-container__pagination">
          <Pagination current={page} onChange={handlePage} total={total} />
        </div>
        )}
      </div>
    </div>
  );
};

export default Usuario;

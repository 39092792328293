export const parserGrafica = ({
  _id,
  nombre,
  nivel,
  tipo,
  proyecto,
  elemento,
  tarjeta,
  tipografica
}) => ({
  _id,
  nombre,
  nivel,
  tipo: tipo && tipo?.nombre,
  proyecto: proyecto?.nombre,
  elemento: elemento && elemento?.nombre,
  tarjeta: tarjeta && tarjeta?.nombre,
  tipografica: tipografica?.nombre,
});

/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Bread from '../../../components/breadcrumb';
import FormTarjeta from '../form';
import { routeTarjetaCreate, routeTarjetaEdit } from '../../../utils/routesBread';
import {
  createTarjeta, fetchElementoByProyecto, fetchTarjetaById, limpiarTarjeta, updateTarjeta
} from '../../../actions/tarjeta';
import { TARJETA_PATH } from '../../../routes/path';
import { fetchEmpresaNotPagination, fetchProyectoByEmpresa } from '../../../actions/empresa';
import { limpiarElemento } from '../../../actions/elemento';
import { limpiarProyecto } from '../../../actions/proyecto';

const CrearTarjeta = () => {
  const [selectedEmpresa, setSelectedEmpresa] = useState('');
  const [selectedProyecto, setSelectedProyecto] = useState('');
  const [form, setForm] = useState({
    nombre: '',
    elemento: undefined,
    posicionesEnTrama: '',
    empresa: undefined,
    proyecto: undefined,
  });
  const { empresas } = useSelector((state) => state.empresaReducer);
  const { tarjeta } = useSelector((state) => state.tarjetaReducer);
  const { elementos } = useSelector((state) => state.elementoReducer);
  const { proyectos } = useSelector((state) => state.proyectoReducer);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleChange = (newForm) => {
    if (newForm?.empresa !== form?.empresa) {
      // Se ha cambiado la empresa
      setForm({
        ...newForm,
        proyecto: '',
        elemento: ''
      });
      dispatch(limpiarElemento());
    } else if (newForm?.proyecto !== form?.proyecto) {
      // Se ha cambiado solo el proyecto
      setForm({
        ...newForm,
        elemento: ''
      });
    } else {
      // No ha cambiado ni la empresa ni el proyecto
      setForm(newForm);
    }
    setSelectedEmpresa(newForm?.empresa);
    setSelectedProyecto(newForm?.proyecto);
  };

  useEffect(() => {
    setForm({
      nombre: tarjeta && tarjeta?.nombre,
      elemento: tarjeta && tarjeta?.elemento?._id,
      empresa: tarjeta && tarjeta?.elemento?.proyecto?.empresa?._id,
      proyecto: tarjeta && tarjeta?.elemento?.proyecto?._id,
      posicionesEnTrama: tarjeta && tarjeta?.posicionesEnTrama,
    });
    dispatch(limpiarElemento());
    dispatch(limpiarProyecto());
    setSelectedEmpresa(tarjeta?.elemento?.proyecto?.empresa?._id);
    setSelectedProyecto(tarjeta && tarjeta?.elemento?.proyecto?._id);
  }, [tarjeta]);

  useEffect(() => {
    dispatch(fetchEmpresaNotPagination());
    if (pathname.includes('editar')) {
      dispatch(fetchTarjetaById(id));
    }
    return () => {
      dispatch(limpiarTarjeta());
    };
  }, []);

  useEffect(() => {
    if (selectedEmpresa) dispatch(fetchProyectoByEmpresa(selectedEmpresa));
  }, [selectedEmpresa]);

  useEffect(() => {
    if (selectedProyecto) dispatch(fetchElementoByProyecto(selectedProyecto));
  }, [selectedProyecto]);

  const redirectTarjetas = () => {
    navigate(TARJETA_PATH);
  };

  const redirectProfile = () => {
    navigate(`/info-tarjeta/${id}`);
  };

  const handleCreate = () => {
    delete form.empresa;
    delete form.proyecto;
    dispatch(createTarjeta(form, redirectTarjetas));
  };

  const handleEdit = () => {
    delete form.empresa;
    delete form.proyecto;
    dispatch(updateTarjeta(id, form, redirectProfile));
  };

  return (
    <>
      <div className="header-nav">
        <Bread routes={pathname.includes('crear-tarjeta') ? routeTarjetaCreate : routeTarjetaEdit} />
      </div>
      <div className="info-container">
        {pathname.includes('crear')
          ? (
            <FormTarjeta
              data={form}
              elementos={elementos}
              onChange={handleChange}
              onSubmit={handleCreate}
              empresas={empresas}
              proyectos={proyectos}
            />
          ) : (
            <FormTarjeta
              data={form}
              elementos={elementos}
              onChange={handleChange}
              onSubmit={handleEdit}
              empresas={empresas}
              proyectos={proyectos}
            />
          )}
      </div>
    </>
  );
};

export default CrearTarjeta;

/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import Logo from '../../assets/logo/logo_blanco.png';
import { logout } from '../../actions/app';
import { LOGIN_PATH, ROOT_PAGE_PATH } from '../../routes/path';
import Icon from '../icon';
import BurguerButton from '../burguer-button';

const Header = ({ onShow, checked }) => {
  const user = useSelector((state) => state.userReducer.user);
  const token = useSelector((state) => !!state.appReducer.token.accessToken);
  const sizePage = window.screen.width;

  const dispatch = useDispatch();

  // popup methods
  const handleLogout = () => {
    <Navigate to={LOGIN_PATH} replace />;
    dispatch(logout());
  };
  const handleShow = () => {
    onShow();
  };
  return (
    <div className="header-container">
      {sizePage < 600 && token
      && (
        <button className="header-container__button-menu" onClick={handleShow} type="button">
          <BurguerButton checked={checked} onChecked={handleShow} />
        </button>
      )}
      <Link to={ROOT_PAGE_PATH}>
        <img className="header-container__logo" src={Logo} alt="Visor MAPX" />
      </Link>
      {token && (
        <div className="header-container__button">
          <Icon name="usuario" />
          {sizePage > 600
          && <p className="header-container__nombre">{user && user.nombre}</p>}
          <Popconfirm
            title="¿Estás seguro que deseas cerrar sesión?"
            onConfirm={handleLogout}
            okText="Si"
            cancelText="Cancelar"
            placement="bottomRight"
          >
            <button className="header-container__button-down">
              <DownOutlined />
            </button>
          </Popconfirm>
        </div>
      )}
    </div>
  );
};
Header.propTypes = {
  onShow: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};
export default Header;

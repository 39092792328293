/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import Input from '../../../components/input';

const ConfiguracionAvanzadaAcumulado = ({
  data, onChange, error, onBlur, tipoSensores, disabled
}) => {
  const [tipoSensorSelected, setTipoSensorSelected] = useState();
  useEffect(() => {
    const excludeTipo = tipoSensores.filter((tipo) => tipo._id !== data.principal);
    setTipoSensorSelected(excludeTipo);
  }, [data.principal]);

  return (
    <div className="form-info-container__box-grid">
      <div className="input-container">
        <p>Seleccionar Sensor Principal</p>
        <Radio.Group
          onChange={(e) => onChange('principal', e.target.value)}
          value={data.principal}
          defaultChecked={data.principal}
          disabled={disabled}
        >
          {tipoSensores?.map((tipo) => (
            <Radio
              key={tipo._id}
              value={tipo._id}
              disabled={disabled}
            >
              {tipo.nombre}

            </Radio>
          ))}
        </Radio.Group>
      </div>
      <div>
        <div className="input-container">
          <p>Seleccionar Secundario y Tasa de Conversión</p>
          <Radio.Group disabled={disabled} onChange={(e) => onChange('secundario', e.target.value)} value={data.secundario}>
            {tipoSensorSelected?.map((tipo) => (
              <Radio key={tipo._id} value={tipo._id}>{tipo.nombre}</Radio>
            ))}
            <Radio disabled={disabled} value="">No agregar sensor secundario</Radio>
          </Radio.Group>
        </div>
        <Input
          name="conversion"
          label=""
          placeholder="Ingrese la tasa de conversión"
          value={data.conversion}
          inputType="text"
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled || (data.secundario === '')}
          error={error.conversion}
        />
      </div>

    </div>
  );
};
ConfiguracionAvanzadaAcumulado.propTypes = {
  tipoSensores: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  data: PropTypes.shape().isRequired,
  error: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired
};
ConfiguracionAvanzadaAcumulado.defaultProps = {
  tipoSensores: [],
  onChange: () => { },
  onBlur: () => { },
};
export default ConfiguracionAvanzadaAcumulado;

/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../errorMessage';

const Input = ({
  placeholder,
  name,
  value,
  inputType,
  onChange,
  onBlur,
  login = false,
  label,
  disabled = false,
  error,
}) => {
  const handleChange = (e) => {
    e.preventDefault();
    onChange(name, e.target.value);
  };
  const handleBlur = (e) => {
    onBlur(name, e.target.value);
  };
  return (
    <>
      {login ? (
        <div className='input-cont'>
          <div className='input-container--login'>
            <div className='input-container--login__box' />
            <input
              name={name}
              className='input-container--login__input'
              value={value}
              onChange={handleChange}
              type={inputType}
              onBlur={handleBlur}
              placeholder={placeholder}
              disabled={disabled}
            />
          </div>
          <ErrorMessage textError={error} />
        </div>
      ) : inputType !== 'checkbox' ? (
        <div className='input-container'>
          <p>{label}</p>
          <input
            name={name}
            className='input-container__input'
            value={value}
            onChange={handleChange}
            type={inputType}
            onBlur={handleBlur}
            placeholder={placeholder}
            disabled={disabled}
          />
          <ErrorMessage textError={error} />
        </div>
      ) : (
        <div className='input-container-checkbox'>
          <p>{label}</p>
          <input
            name={name}
            className='input-container-checkbox__input'
            value={value}
            onChange={handleChange}
            type={inputType}
            onBlur={handleBlur}
            placeholder={placeholder}
            disabled={disabled}
          />
          <ErrorMessage textError={error} />
        </div>
      )}
    </>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  inputType: PropTypes.string,
  login: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};
Input.defaultProps = {
  value: '',
  label: '',
  inputType: '',
  onBlur: () => {},
  onChange: () => {},
  login: false,
  disabled: false,
  placeholder: '',
  error: '',
};

export default Input;

/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Pagination, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { fetchEmpresa } from '../../actions/empresa';
import AccordionUsuarioProyecto from './accordion-usuario-proyecto';
import Accordion from '../../components/accordion';
import Bread from '../../components/breadcrumb';
import Card from '../../components/card';
import { columnsEmpresa, parserDataTabla } from '../../utils/parsers/table';
import { routeEmpresa } from '../../utils/routesBread';
import { usePagination } from '../../hooks/usePagination';
import Button from '../../components/button';
import { CREAR_EMPRESA_PATH } from '../../routes/path';

const Empresa = () => {
  const {
    dataSource, total, handlePage, page, isLoading
  } = usePagination(fetchEmpresa, 'empresa');
  const navigate = useNavigate();
  return (
    <div className="page-container">
      <div className="header-nav">
        <Bread routes={routeEmpresa} />
        <Button
          type="button"
          text="Crear nueva empresa"
          onClick={() => navigate(CREAR_EMPRESA_PATH)}
          color="gray"
        />
      </div>
      <div className="page-container__box">
        <Accordion keyPanel="empresas" header="Empresas">
          {isLoading
            ? (
              <div className="container-spin">
                <Spin tip="Cargando..." />
              </div>
            )
            : (
              <>
                {dataSource
          && dataSource.length !== 0 ? (
                    dataSource.map((item) => (
                      <div key={item._id}>
                        <Card
                          columns={columnsEmpresa}
                          data={[parserDataTabla(item)]}
                          header={item.nombre}
                          route="info-empresa"
                        />
                        <AccordionUsuarioProyecto data={item} />
                      </div>
                    ))
                  ) : <p className="text-nothing">No hay datos para mostrar</p>}
              </>
            )}
        </Accordion>
        {dataSource
        && (
        <div className="page-container__pagination">
          <Pagination current={page} onChange={handlePage} total={total} />
        </div>
        )}
      </div>
    </div>
  );
};

export default Empresa;

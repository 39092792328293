export const LOGIN_PATH = '/login';
export const LOGOUT_PATH = '/logout';
export const REFRESH = '/refresh';

export const FETCH_USUARIO_PATH = '/usuario';
export const UPDATE_USUARIO_PATH = '/usuario';
export const REGISTER_USUARIO_PATH = '/usuario';
export const DELETE_USUARIO_PATH = '/usuario';
export const SET_USER_HEADER_PATH = 'usuario/perfil';
export const CREATE_USUARIO_PATH = '/usuario';

export const FETCH_EMPRESA_PATH = '/empresa';
export const UPDATE_EMPRESA_PATH = '/empresa';
export const REGISTER_EMPRESA_PATH = '/empresa';
export const DELETE_EMPRESA_PATH = '/empresa';
export const CREATE_EMPRESA_PATH = '/empresa';

export const PROYECTO_PATH = '/proyecto';
export const FETCH_PROYECTO_PATH = '/proyecto';
export const ELEMENTO_PATH = '/elemento';

export const SENSOR_PATH = '/sensor';

export const TARJETA_PATH = '/tarjeta';

export const FETCH_TIPO_SENSOR_PATH = '/tiposensor';

export const FETCH_GRAFICA_PATH = '/grafica';
export const FETCH_TIPO_GRAFICA_PATH = '/tipografica';
export const FETCH_ELEMENTO_GRAFICA_PATH = '/elemento/grafica';
export const FETCH_TARJETA_GRAFICA_PATH = '/tarjeta/grafica';
export const FETCH_SENSORES_TARJETA_GRAFICA_PATH = '/sensor/grafica';
export const SENSORES_GRAFICA_PATH = '/sensoresgrafica';
export const DELETE_GRAFICA_PATH = '/grafica';

export const RESET_PASSWORD_PATH = '/usuario/reset-password';
export const CHANGE_PASSWORD_PATH = '/usuario/change-password';

export const OBJETIVO_PATH = '/objetivo';
export const OBJETIVO_PROYECTO_PATH = '/objetivo/proyecto';
export const OBJETIVO_ELEMENTO_PATH = '/objetivo/elemento';

export const TIPO_REPORTE_PATH = '/tiporeporte';
export const FETCH_ELEMENTO_REPORTE_PATH = 'elemento/reporte';
export const FETCH_TARJETAS_REPORTE_PATH = 'tarjeta/reporte';
export const FETCH_SENSORES_REPORTE_PATH = 'tarjeta/reporte/sensores';
export const FETCH_TARJETA_SENSORES_REPORTE_PATH = 'tarjeta/reporte/sensores';
export const FETCH_ELEMENTO_SENSORES_REPORTE_PATH = 'elemento/reporte/sensores';
export const FETCH_PROYECTO_SENSORES_REPORTE_PATH = 'proyecto/reporte/sensores';
export const GENERAR_REPORTE_BASE_PATH = 'sensor/generar-reporte-base';
export const GENERAR_REPORTE_ACUMULADO_PATH = 'sensor/generar-reporte-acumulado';

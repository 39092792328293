import { handle } from 'redux-pack';
import {
  FETCH_TARJETA,
  FETCH_TARJETA_BY_ELEMENTO,
  FETCH_TARJETA_BY_ID,
  FETCH_TARJETA_BY_ID_GRAFICA,
  FETCH_TARJETA_NOT_PAGINATION,
  LIMPIAR_ESTADO_TARJETA,
  LOGOUT
} from '../actions/types';
import { handleAction } from '../utils/handleAction';

const initialState = {
  tarjetas: [],
  isLoading: false,
  error: '',
};

const TarjetaReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TARJETA:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          tarjetas: payload.data.data,
          total: payload.data.total
        }),
      });
    case FETCH_TARJETA_BY_ID:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          tarjeta: payload.data,
        }),
      });

    case FETCH_TARJETA_BY_ELEMENTO:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          tarjetas: payload.data,
        }),
      });
    case FETCH_TARJETA_BY_ID_GRAFICA:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          tarjeta: payload.data,
        }),
      });
    case FETCH_TARJETA_NOT_PAGINATION:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          tarjetas: payload.data,
        }),
      });
    case LIMPIAR_ESTADO_TARJETA:
      return { ...initialState };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default TarjetaReducer;

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetchProyectoGrafica,
  fetchElementoGrafica,
  fetchTarjetaGrafica,
  fetchTipoGrafica,
  createGrafica,
  changeTarjeta,
  changeElemento
} from '../../../actions/grafica';
import { fetchTipoSensor } from '../../../actions/sensor';
import Bread from '../../../components/breadcrumb';
import { routeGraficaCrear } from '../../../utils/routesBread';
import FormGrafica from '../form';

const CrearGrafica = () => {
  const tipoSensores = (useSelector((state) => state.sensorReducer.tiposensores));
  const {
    proyectos, elementos, tarjetas, tipo: tipoGrafica
  } = (useSelector((state) => state.graficaReducer));
  const navigate = useNavigate();
  const [idTipo, setIdTipo] = useState();
  const [form, setForm] = useState({
    tipografica: '',
    nombre: '',
    tipo: '',
    nivel: '',
    proyecto: '',
    elemento: '',
    tarjeta: '',
    fechaInicio: '',
    fechaFin: '',
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTipoSensor());
    dispatch(fetchProyectoGrafica());
    dispatch(fetchTipoGrafica());
  }, []);

  useEffect(() => {
    const id = tipoGrafica?.find((item) => item.nombre === 'Sankey');
    setIdTipo(id);
  }, [tipoGrafica]);

  useEffect(() => {
    if (form.proyecto === '') {
      dispatch(changeTarjeta());
      const formProyecto = { ...form, elemento: '', tarjeta: '' };
      setForm(formProyecto);
    }
    if (form.elemento === '') {
      dispatch(changeElemento());
      const formElemento = { ...form, tarjeta: '' };
      setForm(formElemento);
    }
    if (form.nivel === 'proyecto') {
      dispatch(changeTarjeta());
      const formProyecto = { ...form, elemento: '', tarjeta: '' };
      setForm(formProyecto);
    }
    if (form.nivel === 'elemento') {
      dispatch(changeElemento());
      if (form.proyecto !== '' && form.proyecto !== '0') {
        dispatch(fetchElementoGrafica(form.proyecto));
        const formElemento = { ...form, tarjeta: '' };
        setForm(formElemento);
      }
    }
    if (form.nivel === 'tarjeta') {
      if (form.proyecto !== '') dispatch(fetchElementoGrafica(form.proyecto));
      if (form.elemento !== '' && form.proyecto !== '') dispatch(fetchTarjetaGrafica(form.elemento));
    }
  }, [form.nivel, form.proyecto, form.elemento]);

  const handleChange = (newForm) => {
    setForm(newForm);
  };

  const onSuccess = (id) => {
    navigate(`/info-grafica/${id}`);
  };
  const handleSubmit = () => {
    const formSet = {
      ...form,
      fechaInicio: new Date(form.fechaInicio).toUTCString(),
      fechaFin: new Date(form.fechaFin).toUTCString()
    };
    if (formSet.fechaInicio === 'Invalid Date' && formSet.fechaFin === 'Invalid Date') {
      delete formSet.fechaInicio;
      delete formSet.fechaFin;
    }
    dispatch(createGrafica(formSet, onSuccess));
  };

  return (
    <div>
      <div className="header-nav">
        <Bread routes={routeGraficaCrear} />
      </div>
      <div className="info-container">
        <FormGrafica
          data={form}
          onSubmit={handleSubmit}
          onChange={handleChange}
          proyectos={proyectos}
          tipoSensores={tipoSensores}
          tarjetas={tarjetas}
          elementos={elementos}
          tipoGrafica={tipoGrafica}
          idSankey={idTipo?._id}
          isNew
        />
      </div>
    </div>
  );
};

export default CrearGrafica;

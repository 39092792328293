import { parserTarjeta } from './tarjeta';

export const parserElementos = ({
  _id,
  nombre,
  descripcion,
  area,
  linea,
  proceso,
  tarjetas,
  tipo,

}) => ({
  id: _id,
  nombre,
  descripcion,
  area,
  linea,
  proceso,
  tarjetas: tarjetas && [parserTarjeta(tarjetas)],
  tipo,

});

import {
  FETCH_USUARIO, FETCH_USUARIO_BY_ID, LIMPIAR_ESTADO_USUARIO, LOGOUT
} from '../actions/types';
import { handleAction } from '../utils/handleAction';

const initialState = {
  isLoading: false,
  error: '',
};

const UsuarioReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USUARIO:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          usuarios: payload.data.data,
          total: payload.data.total
        }),
      });
    case FETCH_USUARIO_BY_ID:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          usuario: payload.data,
        }),
      });
    case LIMPIAR_ESTADO_USUARIO:
      return { ...initialState };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default UsuarioReducer;

import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const Accordion = ({ children, header, keyPanel }) => (
  <Collapse defaultActiveKey={keyPanel} className="accordion-container">
    <Panel key={keyPanel} header={header}>
      <div>
        {children}
      </div>
    </Panel>
  </Collapse>
);
Accordion.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string.isRequired,
  keyPanel: PropTypes.string
};
Accordion.defaultProps = {
  children: undefined,
  keyPanel: ''
};
export default Accordion;

import { handle } from 'redux-pack';
import {
  FETCH_PROYECTO,
  FETCH_PROYECTO_BY_EMPRESA,
  FETCH_PROYECTO_BY_ID,
  FETCH_PROYECTO_BY_ID_GRAFICA,
  FETCH_PROYECTO_NOT_PAGINATION,
  LIMPIAR_ESTADO_PROYECTO,
  LOGOUT
} from '../actions/types';
import { handleAction } from '../utils/handleAction';

const initialState = {
  proyectos: [],
  isLoading: false,
  error: '',
};

const ProyectoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PROYECTO:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          proyectos: payload.data.data,
          total: payload.data.total
        }),
      });
    case FETCH_PROYECTO_BY_ID:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          proyecto: payload.data,
        }),
      });
    case FETCH_PROYECTO_BY_EMPRESA:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          proyectos: payload.data,
        }),
      });
    case FETCH_PROYECTO_BY_ID_GRAFICA:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          proyecto: payload.data,
        }),
      });
    case FETCH_PROYECTO_NOT_PAGINATION:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          proyectos: payload.data,
        }),
      });
    case LOGOUT:
      return initialState;
    case LIMPIAR_ESTADO_PROYECTO:
      return { ...initialState };
    default:
      return state;
  }
};

export default ProyectoReducer;

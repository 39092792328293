/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-undef */
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const parserTarjeta = ({
  _id, nombre, posicionesEnTrama, elemento
}) => ({
  _id,
  nombre,
  posicionesEnTrama,
  id: (
    <CopyToClipboard text={_id} onCopy={() => message.success('¡Copiado!')}>
      <Button
        style={{
          backgroundColor: 'transparent', color: 'white', border: 'none', padding: '0px'
        }}
        type="button"
      >
        {_id}
        <CopyOutlined />
      </Button>
    </CopyToClipboard>
  ),
  elemento: elemento && elemento.nombre,
});

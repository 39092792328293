import {
  EMPRESA_PATH,
  PROYECTO_PATH,
  ELEMENTO_PATH,
  USUARIO_PATH,
  SENSOR_PATH,
  TARJETA_PATH,
  GRAFICA_PATH,
} from '../routes/path';

export const routeEmpresa = [
  {
    text: 'Empresas'
  }
];

export const routeEmpresaInfo = [
  {
    route: EMPRESA_PATH,
    text: 'Empresas',
  },
  {
    text: 'Info Empresa',
  },
];

export const routeEmpresaCreate = [
  {
    route: EMPRESA_PATH,
    text: 'Empresas',
  },
  {
    text: 'Crear Empresa',
  },
];

export const routeEmpresaEdit = [
  {
    route: EMPRESA_PATH,
    text: 'Empresas',
  },
  {
    text: 'Editar Empresa',
  },
];
export const routeProyecto = [
  {
    text: 'Proyectos'
  }
];
export const routeProyectoInfo = [
  {
    route: PROYECTO_PATH,
    text: 'Proyectos'
  },
  {
    text: 'Info Proyecto',
  },
];

export const routeProyectoCrear = [
  {
    route: PROYECTO_PATH,
    text: 'Proyectos'
  },
  {
    text: 'Crear Proyecto',
  },
];

export const routeProyectoEditar = [
  {
    route: PROYECTO_PATH,
    text: 'Proyectos'
  },
  {
    text: 'Editar Proyecto',
  },
];

export const routeElemento = [
  {
    text: 'Elementos'
  }
];

export const routeElementoInfo = [
  {
    route: ELEMENTO_PATH,
    text: 'Elementos',
  },
  {
    text: 'Info Elemento'
  }
];

export const routeElementoCreate = [
  {
    route: ELEMENTO_PATH,
    text: 'Elementos',
  },
  {
    text: 'Crear Elemento'
  }
];
export const routeElementoEdit = [
  {
    route: ELEMENTO_PATH,
    text: 'Elementos',
  },
  {
    text: 'Editar Elemento'
  }
];

export const routeUsuario = [
  {
    text: 'Usuarios',
  },
];

export const routeUsuarioInfo = [
  {
    route: USUARIO_PATH,
    text: 'Usuarios',
  },
  {
    text: 'Info Usuario',
  },
];
export const routeUsuarioCreate = [
  {
    route: USUARIO_PATH,
    text: 'Usuarios',
  },
  {
    text: 'Crear usuario',
  },
];

export const routeUsuarioEditar = [
  {
    route: USUARIO_PATH,
    text: 'Usuarios',
  },
  {
    text: 'Editar usuario',
  },
];

export const routeTarjeta = [
  {
    text: 'Tarjetas',
  },
];

export const routeTarjetaInfo = [
  {
    route: TARJETA_PATH,
    text: 'Tarjetas',
  },
  {
    text: 'Info Tarjeta',
  },
];

export const routeTarjetaCreate = [
  {
    route: TARJETA_PATH,
    text: 'Tarjetas',
  },
  {
    text: 'Crear Tarjeta',
  },
];

export const routeTarjetaEdit = [
  {
    route: TARJETA_PATH,
    text: 'Tarjetas',
  },
  {
    text: 'Editar Tarjeta',
  },
];

export const routeSensor = [
  {
    text: 'Sensores',
  },
];

export const routeSensorInfo = [
  {
    route: SENSOR_PATH,
    text: 'Sensores',
  },
  {
    text: 'Info Sensor',
  },
];

export const routeSensorCreate = [
  {
    route: SENSOR_PATH,
    text: 'Sensores',
  },
  {
    text: 'Crear sensor',
  },
];

export const routeSensorEdit = [
  {
    route: SENSOR_PATH,
    text: 'Sensores',
  },
  {
    text: 'Editar sensor',
  },
];

export const routeGrafica = [
  {
    text: 'Gráficas',
  },
];

export const routeGraficaInfo = [
  {
    route: GRAFICA_PATH,
    text: 'Gráficas',
  },
  {
    text: 'Info Gráfica',
  },
];

export const routeGraficaCrear = [
  {
    route: GRAFICA_PATH,
    text: 'Gráficas',
  },
  {
    text: 'Crear Gráfica',
  },
];

export const routeReporte = [
  {
    text: 'Reportes',
  },
];

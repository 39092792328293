/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RouteApp from './routes/route';
import 'antd/dist/antd.css';
import './App.scss';
import { setToken } from './actions/app';
import { setAuthentication } from './services/api';
import { setUserHeader } from './actions/user';

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.appReducer.user);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    dispatch(
      setToken({
        accessToken,
        refreshToken,
      })
    );
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    setAuthentication();
    if (accessToken && user) {
      dispatch(setUserHeader());
    }
  }, [user]);

  return <RouteApp />;
}

export default App;

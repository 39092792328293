import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CopyOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { message } from 'antd';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Input from '../../../components/input';
import { thereIsErrorInForm, validateField } from '../../../utils/validations';
import Button from '../../../components/button';
import Select from '../../../components/select';

const FormtTarjeta = ({
  data, onSubmit, onChange, disabled = false, elementos, proyectos, empresas
}) => {
  const { pathname } = useLocation();
  const { rol } = useSelector((state) => state.userReducer.user);
  const [isFormComplete, setIsFormComplete] = useState(false);

  useEffect(() => {
    setIsFormComplete(
      data.nombre
      && data.elemento
      && data.proyecto
      && data.empresa
      && data.posicionesEnTrama
    );
  }, [data]);

  const [error, setError] = useState({
    nombre: '',
    proyecto: '',
    empresa: '',
    elemento: '',
    posicionesEnTrama: '',
  });

  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };

  const handleBlur = (key, value) => {
    const fieldError = validateField(key, value);
    setError({
      ...error,
      ...fieldError,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const nombreError = validateField('nombre', data.nombre);
    const proyectoError = validateField('proyecto', data.proyecto);
    const empresaError = validateField('empresa', data.empresa);
    const elementoError = validateField('elemento', data.elemento);
    const posicionesEnTramaError = validateField('posicionesEnTrama', data.posicionesEnTrama);
    const errorMessage = {
      ...error,
      ...proyectoError,
      ...empresaError,
      ...nombreError,
      ...elementoError,
      ...posicionesEnTramaError,
    };
    setError(errorMessage);
    if (!thereIsErrorInForm(errorMessage)) {
      onSubmit();
    }
  };
  return (
    <form>
      <div className="form-info-container">
        <div className="form-info-container__box-container">
          <div className="form-info-container__box">
            {(pathname.includes('info') && rol !== 'empresa') && (
            <div className="form-info-container__clip">
              <Input
                placeholder=""
                name="id"
                label="Id"
                id="idTarjeta"
                value={data.id}
                inputType="text"
                disabled={disabled}
              />
              <CopyToClipboard
                text={data.id}
                onCopy={() => message.success('copiado!')}
              >
                <button
                  type="button"
                >
                  <CopyOutlined />
                </button>
              </CopyToClipboard>

            </div>
            )}
            <Input
              placeholder=""
              name="nombre"
              label="Nombre"
              value={data.nombre}
              inputType="text"
              disabled={disabled}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {(pathname.includes('crear') || pathname.includes('editar')) && (
            <Select
              placeholder=""
              name="empresa"
              label="Empresas"
              value={data.empresa}
              disabled={disabled}
              onChange={handleChange}
              options={empresas}
            />
            )}
            {pathname.includes('info') && (
            <Input
              placeholder=""
              name="empresa"
              label="Empresas"
              value={data.empresa}
              inputType="text"
              disabled={disabled}
            />
            )}
            {(pathname.includes('crear') || pathname.includes('editar')) && (
            <Select
              placeholder=""
              name="proyecto"
              label="Proyecto"
              value={data.proyecto}
              disabled={disabled}
              onChange={handleChange}
              options={proyectos}
            />
            )}
          </div>
          <div className="form-info-container__box">
            {pathname.includes('info') && (
            <Input
              placeholder=""
              name="proyecto"
              label="Proyecto"
              value={data.proyecto}
              inputType="text"
              disabled={disabled}
            />
            )}
            {pathname.includes('info') && (
            <Input
              placeholder=""
              name="elemento"
              label="Elemento"
              value={data.elemento}
              disabled={disabled}
            />
            )}
            {(pathname.includes('crear') || pathname.includes('editar')) && (
            <Select
              placeholder=""
              name="elemento"
              label="Elemento"
              value={data.elemento}
              disabled={disabled}
              onChange={handleChange}
              options={elementos}
            />
            )}
            <Input
              placeholder=""
              name="posicionesEnTrama"
              label="Posición en trama"
              value={(data.posicionesEnTrama)}
              inputType="text"
              disabled={disabled}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-info-container__button">
          { (pathname.includes('crear') || pathname.includes('editar'))
            && (
              <div className="form-info-container__button-edit">
                <Button
                  type="submit"
                  text="Guardar tarjeta"
                  color="gray"
                  onClick={handleSubmit}
                  disabled={!isFormComplete}
                />
              </div>
            )}
        </div>
      </div>
    </form>
  );
};
FormtTarjeta.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  data: PropTypes.shape().isRequired,
  elementos: PropTypes.arrayOf(PropTypes.shape()),
  proyectos: PropTypes.arrayOf(PropTypes.shape()),
  empresas: PropTypes.arrayOf(PropTypes.shape()),
};
FormtTarjeta.defaultProps = {
  onChange: () => {},
  onSubmit: () => {},
  elementos: [],
  proyectos: [],
  empresas: [],
};
export default FormtTarjeta;

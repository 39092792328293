import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../../components/accordion';
import {
  columnsElementos, columnsProyecto, columnsUser, parserDataTabla
} from '../../../utils/parsers/table';
import Card from '../../../components/card';
import { parserUsuarios } from '../../../utils/parsers/usuario';

const AccordionUsuarioProyecto = ({ data }) => (
  <div>
    {data && data.usuarios && (
      <div className="page-container__accordion">
        <Accordion header="Usuarios">
          {data.usuarios.length !== 0
            ? (
              <>
                {data.usuarios.map((usuario, index) => (
                  <Card
                    id={index}
                    key={usuario.nombre}
                    columns={columnsUser}
                    data={[parserDataTabla(parserUsuarios(usuario))]}
                    header={usuario.nombre}
                    route="info-usuario"
                  />
                ))}
              </>
            )
            : <p className="text-nothing">No hay datos para mostrar</p>}
        </Accordion>
      </div>
    )}
    {data && data.proyectos && (
      <div className="page-container__accordion">
        <Accordion header="Proyectos">
          {data.proyectos.length !== 0
            ? (
              <>
                {data.proyectos.map((proyecto, index) => (
                  <div key={proyecto.nombre}>
                    <Card
                      id={index}
                      columns={columnsProyecto}
                      data={[parserDataTabla(proyecto)]}
                      header={proyecto.nombre}
                      route="info-proyecto"
                    />
                    <div>
                      { proyecto && proyecto.elementos && (
                        <Accordion header="Elementos">
                          {proyecto.elementos !== 0
                            ? (
                              <>
                                {proyecto.elementos.map((elemento, index2) => (
                                  <Card
                                    id={index2}
                                    key={elemento.nombre}
                                    columns={columnsElementos}
                                    data={[parserDataTabla(elemento)]}
                                    header={elemento.nombre}
                                    route="info-elemento"
                                  />
                                ))}
                              </>
                            ) : <p className="text-nothing">No hay datos para mostrar</p>}
                        </Accordion>

                      )}
                    </div>
                  </div>
                ))}
              </>
            )
            : <p className="text-nothing">No hay datos para mostrar</p>}
        </Accordion>
      </div>
    )}
  </div>
);
AccordionUsuarioProyecto.propTypes = {
  data: PropTypes.shape({
    usuarios: PropTypes.arrayOf(PropTypes.shape({
      nombre: PropTypes.string
    })),
    proyectos: PropTypes.arrayOf(PropTypes.shape({
      elementos: PropTypes.arrayOf(PropTypes.shape({
        nombre: PropTypes.string
      }))
    }))
  }),
};
AccordionUsuarioProyecto.defaultProps = {
  data: undefined,
};
export default AccordionUsuarioProyecto;

import { handle } from 'redux-pack';
import {
  FETCH_REPORTE_ELEMENTO_BY_ID,
  FETCH_REPORTE_TARJETA_BY_ID,
  FETCH_TIPO_REPORTE,
  FETCH_REPORTE_PROYECTO_SENSORES,
  FETCH_REPORTE_ELEMENTO_SENSORES,
  FETCH_REPORTE_TARJETA_SENSORES,
  FETCH_PROYECTO_REPORTE,
  LOGOUT,
  LIMPIAR_SENSORES,
  GENERAR_REPORTE_BASE,
  GENERAR_REPORTE_ACUMULADO,
  LIMPIAR_ESTADO_REPORTE,
  CHANGE_TIPO_REPORTE
} from '../actions/types';

const initialState = {
  tipoReporte: [],
  isLoading: false,
  error: '',
};

const ReporteReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TIPO_REPORTE:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          tipoReporte: payload.data,
        }),
      });
    case FETCH_PROYECTO_REPORTE:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          proyectos: payload.data,
        }),
      });
    case FETCH_REPORTE_ELEMENTO_BY_ID:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          elementos: payload.data,
        }),
      });
    case FETCH_REPORTE_TARJETA_BY_ID:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          tarjetas: payload.data,
        }),
      });
    case FETCH_REPORTE_PROYECTO_SENSORES:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false, isLoadingSensores: true }),
        finish: (prevState) => ({ ...prevState, isLoading: false, isLoadingSensores: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          sensores: payload.data,
        }),
      });
    case FETCH_REPORTE_ELEMENTO_SENSORES:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false, isLoadingSensores: true }),
        finish: (prevState) => ({ ...prevState, isLoading: false, isLoadingSensores: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          sensores: payload.data,
        }),
      });
    case FETCH_REPORTE_TARJETA_SENSORES:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false, isLoadingSensores: true }),
        finish: (prevState) => ({ ...prevState, isLoading: false, isLoadingSensores: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          sensores: payload.data,
        }),
      });
    case GENERAR_REPORTE_BASE:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: true }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          reporte: { empresa: payload.data.empresa, reporteBase: payload.data }
        }),
      });
    case GENERAR_REPORTE_ACUMULADO:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: true }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          reporte: { empresa: payload.data.empresa, reporteAcumulado: payload.data }
        }),
      });
    case LIMPIAR_SENSORES:
      return {
        ...state, elementos: [], tarjetas: [], sensores: []
      };

    case LIMPIAR_ESTADO_REPORTE:
      return { ...initialState };

    case CHANGE_TIPO_REPORTE:
      return { ...state, reporte: undefined };

    case LOGOUT:
      return {
        ...initialState,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default ReporteReducer;

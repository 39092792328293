/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { thereIsErrorInForm, validateField } from '../../../utils/validations';
import Input from '../../../components/input';
import Button from '../../../components/button';
import Select from '../../../components/select';

const FormProyecto = ({
  data, onSubmit, onChange, disabled = false, empresas
}) => {
  const [error, setError] = useState({
    nombre: undefined,
    descripcion: undefined,
    area: undefined,
    linea: undefined,
    empresa: undefined,
    proceso: undefined,
    identificacion: undefined,
    tipo: undefined
  });
  const { pathname } = useLocation();

  const [isFormComplete, setIsFormComplete] = useState(false);

  useEffect(() => {
    setIsFormComplete(
      data.nombre
      && data.descripcion
      && data.area
      && data.linea
      && data.proceso
      && data.identificacion
      && data.empresa
    );
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const nombreError = validateField('nombre', data.nombre);
    const descripcionError = validateField('descripcion', data.descripcion);
    const areaError = validateField('area', data.area);
    const lineaError = validateField('linea', data.linea);
    const empresaError = validateField('empresa', data.empresa);
    const procesoError = validateField('proceso', data.proceso);
    const identificacionError = validateField('identificacion', data.identificacion);
    const tipoError = validateField('tipo', data.tipo);
    const errorMessage = {
      ...error,
      ...nombreError,
      ...descripcionError,
      ...areaError,
      ...lineaError,
      ...empresaError,
      ...procesoError,
      ...identificacionError,
      ...tipoError,
    };
    setError(errorMessage);
    if (!thereIsErrorInForm(errorMessage)) {
      onSubmit();
    }
  };

  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };

  const handleBlur = (key, value) => {
    const fieldError = validateField(key, value);
    setError({
      ...error,
      ...fieldError,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-info-container">

        <div className="form-info-container__box-container">
          <div className="form-info-container__box">
            <Input
              name="nombre"
              label="Nombre"
              value={(data.nombre)}
              inputType="text"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
            />
            <Input
              name="descripcion"
              label="Descripcion"
              value={data.descripcion}
              inputType="text"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
            />
            <Input
              name="area"
              label="Área"
              value={data.area}
              inputType="text"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
            />
            <Input
              name="linea"
              label="Línea"
              value={data.linea}
              inputType="text"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
            />
          </div>
          <div className="form-info-container__box">
            <Select
              placeholder=""
              name="empresa"
              label="Empresa"
              value={data.empresa}
              disabled={disabled}
              onChange={handleChange}
              options={empresas}
            />
            <Input
              name="proceso"
              label="Proceso"
              value={data.proceso}
              inputType="text"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
            />
            <Input
              name="identificacion"
              label="Identificacion"
              value={data.identificacion}
              inputType="text"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="form-info-container__button">
          { (pathname.includes('crear') || pathname.includes('editar'))
            && (
              <div className="form-info-container__button-edit">
                <Button
                  type="submit"
                  text="Guardar proyecto"
                  color="gray"
                  onClick={handleSubmit}
                  disabled={!isFormComplete}
                />
              </div>
            )}
        </div>
      </div>
    </form>
  );
};
FormProyecto.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  empresas: PropTypes.arrayOf(PropTypes.shape())
};
FormProyecto.defaultProps = {
  onSubmit: () => {},
  onChange: () => {},
  empresas: []
};

export default FormProyecto;

/* eslint-disable import/no-cycle */
import { api } from '../services/api';
import {
  FETCH_ELEMENTO_REPORTE_PATH,
  FETCH_PROYECTO_SENSORES_REPORTE_PATH,
  FETCH_ELEMENTO_SENSORES_REPORTE_PATH,
  FETCH_TARJETA_SENSORES_REPORTE_PATH,
  FETCH_TARJETAS_REPORTE_PATH,
  TIPO_REPORTE_PATH,
  FETCH_PROYECTO_PATH,
  GENERAR_REPORTE_BASE_PATH,
  GENERAR_REPORTE_ACUMULADO_PATH
} from '../services/path';
import { showErrorMessage } from '../utils/utils';
import {
  FETCH_TIPO_REPORTE,
  FETCH_PROYECTO_REPORTE,
  FETCH_REPORTE_ELEMENTO_BY_ID,
  FETCH_REPORTE_TARJETA_BY_ID,
  FETCH_REPORTE_PROYECTO_SENSORES,
  FETCH_REPORTE_ELEMENTO_SENSORES,
  FETCH_REPORTE_TARJETA_SENSORES,
  LIMPIAR_SENSORES,
  GENERAR_REPORTE_BASE,
  GENERAR_REPORTE_ACUMULADO,
  LIMPIAR_ESTADO_REPORTE,
  CHANGE_TIPO_REPORTE
} from './types';

export const fetchProyectoReporte = () => (dispatch) => dispatch({
  type: FETCH_PROYECTO_REPORTE,
  promise: api.get(FETCH_PROYECTO_PATH)
});

export const fetchTipoReporte = () => (dispatch) => dispatch({
  type: FETCH_TIPO_REPORTE,
  promise: api.get(TIPO_REPORTE_PATH)
});

export const fetchElementoReporteById = (id) => (dispatch) => dispatch({
  type: FETCH_REPORTE_ELEMENTO_BY_ID,
  promise: api.get(`${FETCH_ELEMENTO_REPORTE_PATH}/${id}`)

});

export const fetchTarjetaReporteById = (id) => (dispatch) => dispatch({
  type: FETCH_REPORTE_TARJETA_BY_ID,
  promise: api.get(`${FETCH_TARJETAS_REPORTE_PATH}/${id}`)
});

// TRAE SENSORES DESDE LOS NIVELES
export const fetchSensoresProyectoReporteById = (id) => (dispatch) => dispatch({
  type: FETCH_REPORTE_PROYECTO_SENSORES,
  promise: api.get(`${FETCH_PROYECTO_SENSORES_REPORTE_PATH}/${id}`)
});

export const fetchSensoresElementoReporteById = (id) => (dispatch) => dispatch({
  type: FETCH_REPORTE_ELEMENTO_SENSORES,
  promise: api.get(`${FETCH_ELEMENTO_SENSORES_REPORTE_PATH}/${id}`)
});

export const fetchSensoresTarjetaReporteById = (id) => (dispatch) => dispatch({
  type: FETCH_REPORTE_TARJETA_SENSORES,
  promise: api.get(`${FETCH_TARJETA_SENSORES_REPORTE_PATH}/${id}`)
});

export const generarReporteBase = (data) => (dispatch) => dispatch({
  type: GENERAR_REPORTE_BASE,
  promise: api.post(`${GENERAR_REPORTE_BASE_PATH}`, data)
});

export const generarReporteAcumulado = (data) => (dispatch) => dispatch({
  type: GENERAR_REPORTE_ACUMULADO,
  promise: api.post(`${GENERAR_REPORTE_ACUMULADO_PATH}`, data),
  meta: {
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

// export const generarReporteBase = (data, controller) => (dispatch) => dispatch({
//   type: GENERAR_REPORTE_BASE,
//   promise: api.post(`${GENERAR_REPORTE_BASE_PATH}`, data, { signal: controller.signal })
// });

// export const generarReporteAcumulado = (data, controller) => (dispatch) => dispatch({
//   type: GENERAR_REPORTE_ACUMULADO,
//   promise: api.post(`${GENERAR_REPORTE_ACUMULADO_PATH}`, data, { signal: controller.signal })
// });

export const limpiarSensores = () => (dispatch) => dispatch({
  type: LIMPIAR_SENSORES,
});

export const limpiarReporte = () => (dispatch) => dispatch({
  type: LIMPIAR_ESTADO_REPORTE,
});
export const changeTipoReporte = () => (dispatch) => dispatch({
  type: CHANGE_TIPO_REPORTE,
});

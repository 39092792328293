/* eslint-disable no-unused-vars */
const logger = (store) => (next) => (action) => {
  // console.group(action.type);
  // console.info('dispatching', action);
  const result = next(action);
  // console.groupEnd();
  return result;
};

export default logger;

/* eslint-disable react/prop-types */
import React from 'react';
import Tarjeta from './tarjeta';

const Elemento = ({
  id, nombre, tarjetas, sensoresChecked, onCheckSensor, disabled
}) => (
  <div key={id} className="container-checkbox-elemento">
    <h1>
      {`Elemento: ${nombre}`}
    </h1>
    { tarjetas.length === 0
      ? <p className="text-nothing">No hay tarjetas para mostrar</p>
      : (tarjetas
        .map(({ id, nombre, sensores }) => (
          <Tarjeta
            disabled={disabled}
            key={id}
            id={id}
            nombre={nombre}
            sensores={sensores}
            sensoresChecked={sensoresChecked}
            onCheckSensor={onCheckSensor}
          />
        )))}
  </div>
);

export default Elemento;

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { api } from '../services/api';
import {
  FETCH_TIPO_SENSOR_PATH,
  OBJETIVO_ELEMENTO_PATH,
  OBJETIVO_PATH,
  OBJETIVO_PROYECTO_PATH,
} from '../services/path';
import { showErrorMessage, showSuccessMessage } from '../utils/utils';
import {
  AGREGAR_OBJETIVO, FETCH_OBJETIVOS_TIPO_SENSOR, FETCH_OBJETIVO_ELEMENTO, FETCH_OBJETIVO_PROYECTO
} from './types';

export const agregarObjetivo = (data, callback) => (dispatch) => {
  dispatch({
    type: AGREGAR_OBJETIVO,
    promise: api.post(OBJETIVO_PATH, data),
    meta: {
      onSuccess: (res) => {
        showSuccessMessage('Objetivo creado', dispatch);
        callback(res.data._id);
      },
      onFailure: (res) => {
        showErrorMessage(res.response.data.message, dispatch);
        callback();
      }
    }
  });
};

export const fetchTipoSensor = () => (dispatch) => dispatch({
  type: FETCH_OBJETIVOS_TIPO_SENSOR,
  promise: api.get(`${FETCH_TIPO_SENSOR_PATH}`)
});

export const fetchObjetivoProyecto = (perPage, offSet, id) => (dispatch) => dispatch({
  type: FETCH_OBJETIVO_PROYECTO,
  promise: api.get(`${OBJETIVO_PROYECTO_PATH}/${id}?perPage=${perPage}&offSet=${offSet}`)
});

export const fetchObjetivoElemento = (perPage, offSet, id) => (dispatch) => dispatch({
  type: FETCH_OBJETIVO_ELEMENTO,
  promise: api.get(`${OBJETIVO_ELEMENTO_PATH}/${id}?perPage=${perPage}&offSet=${offSet}`)
});

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { colors } from '../../utils/colorsGrafica';

const CardGrafica = ({
  data, title, id, onRemoveSensor, sensor = false, idGrafica
}) => {
  const size = () => {
    const { width } = window.screen;
    if (width > 600) return { width: 850, height: '' };
    if (width < 600 && width > 450) return { width: 350, height: 300 };
    if (width < 450) return { width: 305, height: 350 };
    return 0;
  };
  const [empty, setEmpty] = useState('');
  const [sizeGraph] = useState(size());
  const [state, setState] = useState(undefined);
  const { pathname } = useLocation();

  // funcion para sacar las fechas repetidas
  const listDate = () => {
    const listDate = [];
    if (data.length !== 0) {
      data?.map((element) => {
        element?.data?.map((item) => {
          listDate.push(item.date);
        });
      });
      return [...new Set(listDate)];
    }
    return [];
  };

  // // funcion para agregar mas de un punto cuando hay una secuencias de null
  // const getNextAndPreviousValue = (list, i) => {
  //   let previousValue = 0;
  //   let indexPreviousValue = 0;
  //   let nextValue = list.length;
  //   let indexNextValue = list.length;
  //   for (let index = i; index >= 0; index--) {
  //     if (list[index]?.y) { previousValue = list[index].y; indexPreviousValue = index; break; }
  //   }
  //   for (let index = i; index <= list.length; index++) {
  //     if (list[index]?.y) { nextValue = list[index].y; indexNextValue = index; break; }
  //   }
  //   return {
  //     previousValue, nextValue, indexPreviousValue, indexNextValue
  //   };
  // };

  // // funcion para evaluar si hay puntos
  // const isIntermediatePoint = (list, i) => {
  //   if (i !== 0 || i !== list.length) {
  //     const { previousValue, nextValue } = getNextAndPreviousValue(list, i);
  //     if (previousValue && nextValue) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };
  //
  // // funcion para crear punto o puntos intermedios para cuando value es null en una fecha
  // const getPointIntermediate = (list, index) => {
  //   const {
  //     previousValue, nextValue, indexNextValue, indexPreviousValue
  //   } = getNextAndPreviousValue(list, index);
  //   const value = (nextValue + previousValue) / 2;
  //   if (nextValue - previousValue > 0) {
  //     return (value + (index - indexPreviousValue)).toFixed(1);
  //   }
  //   if (nextValue - previousValue < 0) {
  //     return (value - (index - indexNextValue)).toFixed(1);
  //   }
  //   return value.toFixed(1);
  // };

  // funcion para formatear los datos con x e y
  const formatData = (dataGrafica) => {
    if (dataGrafica !== []) {
      const list = listDate()?.map((item) => ({
        x: item,
        y: dataGrafica.find((i) => i?.date === item)
          ? dataGrafica.find((i) => i?.date === item).value : null
      }));
      const arrayOrdenado = list?.sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime());
      const list2 = arrayOrdenado.map((item) => ({
        ...item,
        y: item.y
          ? item.y
          // : isIntermediatePoint(list, index)
          //   ? getPointIntermediate(list, index)
          : null
      }));
      const arrayOrdenadoFinal = list2?.map((item) => ({
        x: new Date(item.x).toLocaleString(),
        y: item.y
      }));
      return { listNull: arrayOrdenado, list: arrayOrdenadoFinal };
    }
    return { listNull: [], list: [] };
  };

  // mensaje para el sensor/los sensores si es que no tienen datos cargados
  const emptyData = () => {
    const dataEmpty = [];
    data.map((item) => { if (item.data.length !== 0) dataEmpty.push(item); });
    if (dataEmpty.length === 0) setEmpty('Los sensores no tienen datos');
    if (dataEmpty.length === 0 && pathname.includes('info-sensor')) setEmpty('El sensor no tienen datos');
    if (dataEmpty.length === 0 && data.length === 1) setEmpty('El sensor no tienen datos');
  };

  // funcion que parsea los datos
  const parsedData = (dataGraph) => {
    const list = [];
    const listNull = [];
    if (dataGraph?.length !== 0) {
      for (let index = 0; index < data?.length; index++) {
        const dataFormat = formatData(dataGraph[index]?.data).list;
        const dataNull = formatData(dataGraph[index]?.data).listNull;
        const sensor = {
          name: dataGraph[index]?.nombre,
          data: dataFormat
        };
        list.push(sensor);
        listNull.push(dataNull);
      }
    }
    return { list, listNull };
  };

  // funcion para crear los puntos (visualmente) en las graficas
  const setMarkers = () => {
    const listSensorMarker = [];
    parsedData(data)?.listNull.map((item, indx) => {
      item.map((point, index) => {
        const markerSensor = {
          seriesIndex: indx,
          dataPointIndex: index,
          size: point.y === null ? 0 : size().width < 450 ? 1 : 3,
          fillColor: colors[indx],
          strokeColor: colors[indx],
        };
        listSensorMarker.push(markerSensor);
      });
    });
    return listSensorMarker;
  };

  useEffect(() => {
    emptyData();
    setState({
      series: parsedData(data)?.list,
      options: {
        chart: {
          zoom: {
            enabled: !(window.screen.width <= 600)
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          showForNullSeries: true,
          showForZeroSeries: true,

        },
        markers: {
          discrete: setMarkers()
        },
        stroke: {
          show: true,
          lineCap: 'butt',
          width: 1,
          colors: undefined,
          dashArray: 0,
        },
        xaxis: {
          labels: {
            datetimeUTC: false
          }
        },
        yaxis: {
          show: true,
          showAlways: true,
          showForNullSeries: false,
          title: {
            text: `Unidad (${data[0]?.unidades})`,
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: window.screen.width <= 600 ? '12px' : '25px',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-title',
            },
          },

        },

        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: true
                }
              },
              legend: {
                show: true,
                showForSingleSeries: true,
                position: 'top'
              }
            }
          }
        ],
        colors
      }
    });
  }, [data]);

  // funcion para eliminar un sensor
  const handleDelete = (index) => {
    onRemoveSensor(index);
  };
  if (state === undefined) return (<div />);
  return (
    <div className={pathname.includes('info-grafica') ? 'card-grafica-container-info' : 'card-grafica-container'}>
      <div className="card-grafica-container__header">
        <p className="card-grafica-container__title">{title}</p>
        {
          !pathname.includes('/info-grafica')
        && (
        <Link to={`/info-grafica/${idGrafica}`}>
          <InfoCircleOutlined />
        </Link>
        )
        }
      </div>
      <div className="card-grafica-container__body-grafica">
        {data.length !== 0
          ? (
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="line"
              width={sizeGraph.width}
              height={sizeGraph.height}
            />
          )
          : <p className="text-nothing">{empty}</p>}
      </div>
      <div className="card-grafica-container__footer">
        {sensor && (
        <div className="card-grafica-container__box-sensor">
          <div className="card-grafica-container__tipo">
            <p>{data[0].tipo.nombre}</p>
          </div>
          <div className="card-grafica-container__box-info">
            <div className="card-grafica-container__info">
              <p>Rango Min.</p>
              <p>{data[0].rangoMin.$numberDecimal}</p>
            </div>
            <div className="card-grafica-container__info">
              <p>Rango Max.</p>
              <p>{data[0].rangoMax.$numberDecimal}</p>
            </div>
            <div className="card-grafica-container__info">
              <p>Posición en trama</p>
              <p>{data[0].posicionEnTrama}</p>
            </div>
          </div>
        </div>
        )}
        {pathname.includes('info-grafica')
          && (
          <>
            {data?.map((item) => (
              <div className="card-grafica-container__sensor" key={item[id]}>
                <p>{item.nombre}</p>
                <Popconfirm
                  title="¿Estás seguro que deseas eliminar el sensor?"
                  onConfirm={() => handleDelete(item[id])}
                  okText="Si"
                  cancelText="Cancelar"
                  placement="right"
                >
                  <button
                    className="header-container__button-down"
                  >
                    <CloseOutlined />
                  </button>
                </Popconfirm>
              </div>
            ))}
          </>
          )}
      </div>
    </div>
  );
};

CardGrafica.propTypes = {
  title: PropTypes.string,
  idGrafica: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()),
  id: PropTypes.string,
  onRemoveSensor: PropTypes.func,
  sensor: PropTypes.bool,
};
CardGrafica.defaultProps = {
  title: '',
  idGrafica: '',
  data: [],
  id: '_id',
  sensor: false,
  onRemoveSensor: () => {}
};
export default CardGrafica;

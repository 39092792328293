import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const CheckboxInput = ({
  name,
  value,
  onChange,
  label,
  disabled = false,
}) => {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleChange = () => {
    const newValue = !checked;
    setChecked(newValue);
    onChange(name, newValue);
  };

  return (
    <div className="input-container-checkbox">
      <p>{label}</p>
      <input
        name={name}
        className="input-container-checkbox__input"
        type="checkbox"
        defaultChecked={value}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  );
};

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

CheckboxInput.defaultProps = {
  disabled: false,
};

export default CheckboxInput;

export const parserSensores = ({
  _id,
  nombre,
  alias,
  unidades,
  tipo,
  rangoMin,
  rangoMax,
  resolucionMin,
  resolucionMax,
  peligrosidad,
  peligrosidadAjuste,
  posicionEnTrama,
  tarjeta
}) => ({
  _id,
  nombre,
  alias,
  unidades,
  tipo: tipo?.nombre,
  rangoMin: rangoMin?.$numberDecimal,
  rangoMax: rangoMax?.$numberDecimal,
  resolucionMin: resolucionMin?.$numberDecimal,
  resolucionMax: resolucionMax?.$numberDecimal,
  peligrosidad: peligrosidad?.$numberDecimal,
  peligrosidadAjuste: peligrosidadAjuste?.$numberDecimal,
  posicionEnTrama,
  tarjeta: tarjeta?.nombre
});

export const validateField = (key, value) => {
  if (value === '' || value === []) {
    if (key === 'password' || key === 'confirmPassword') {
      return {
        [key]: 'contraseña es requerida',
      };
    }
    if (key === 'tipografica') {
      return {
        [key]: 'tipo de gráfica es requerido',
      };
    }
    if (key === 'tipoReporte') {
      return {
        [key]: 'tipo de reporte es requerido',
      };
    }
    return {
      [key]: `${key} es requerido`,
    };
  }

  return {
    [key]: '',
  };
};
export const validateDate = (key, value) => {
  if (value === '' || value === null) {
    if (key === 'fechaInicio' || key === 'fechaFin') {
      return {
        [key]: 'la fecha es requerida',
      };
    }
  }
  return {
    [key]: '',
  };
};
export const thereIsErrorInForm = (d) => {
  const arrayValues = Object.values(d);
  const thereIsError = arrayValues.some((item) => item);
  return thereIsError;
};

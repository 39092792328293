import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';

const Bread = ({ routes }) => (
  <Breadcrumb>
    {routes.map((item) => (
      <Breadcrumb.Item key={item.text}>
        <a href={item.route}>{item.text}</a>
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
);

Bread.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
};

export default Bread;

const addCero = (date) => ((date >= 0 && date < 10) ? `0${date}` : date);
export const formatDate = (fecha) => {
  const fechaAux = new Date(fecha);
  return `${addCero(fechaAux.getUTCDate())}/${addCero(fechaAux.getUTCMonth() + 1)}/${fechaAux.getUTCFullYear()}`;
};

export const formatDateToUTC = (date) => {
  const dateMiliseconds = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );

  return new Date(dateMiliseconds);
};

export const fechaHora = (fecha) => {
  const fechaNew = new Date(fecha);
  return `${addCero(fechaNew.getDate())}/${addCero(fechaNew.getMonth() + 1)}/${fechaNew.getFullYear()} ${addCero(fechaNew.getHours())}:${addCero(fechaNew.getMinutes())}`;
};

export const fechaHoraRango = (fecha1, fecha2) => {
  const fechaNew1 = new Date(fecha1);

  const fechaNew2 = new Date(fecha2);
  return `${addCero(fechaNew1.getDate())}/${addCero(fechaNew1.getMonth() + 1)}/${fechaNew1.getFullYear()} (${addCero(fechaNew1.getHours())}:${addCero(fechaNew1.getMinutes())} - ${addCero(fechaNew2.getHours())}:${addCero(fechaNew2.getMinutes())})`;
};

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Popconfirm, Spin } from 'antd';
import {
  deleteTarjeta, fetchTarjetaById, fetchTarjetaByIdGrafica, limpiarTarjeta
} from '../../../actions/tarjeta';
import FormTarjeta from '../form';
import { routeTarjetaInfo } from '../../../utils/routesBread';
import Bread from '../../../components/breadcrumb';
import AccordionSensores from '../accordion-sensores';
import Accordion from '../../../components/accordion';
import CardGrafica from '../../../components/card-grafica';
import Button from '../../../components/button';
import { TARJETA_PATH } from '../../../routes/path';

const InfoTarjeta = () => {
  const { tarjeta, isLoading } = useSelector((state) => state.tarjetaReducer);
  const { elementos } = useSelector((state) => state.elementoReducer);
  const { rol } = useSelector((state) => state.userReducer.user);
  const [form, setForm] = useState({
    id: '',
    nombre: '',
    proyecto: '',
    elemento: '',
    posicionesEnTrama: '',
    empresa: ''
  });
  const disabled = true;
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchTarjetaById(id));
  }, []);
  useEffect(() => {
    setForm({
      id: tarjeta && tarjeta._id,
      nombre: tarjeta && tarjeta.nombre,
      proyecto: tarjeta && tarjeta?.elemento?.proyecto?.nombre,
      empresa: tarjeta && tarjeta?.elemento?.proyecto?.empresa?.nombre,
      elemento: tarjeta && tarjeta?.elemento?.nombre,
      posicionesEnTrama: tarjeta && tarjeta.posicionesEnTrama,
    });
  }, [tarjeta]);

  useEffect(() => {
    const intervalId = setInterval(() => dispatch(fetchTarjetaByIdGrafica(id)), 30000);
    return (() => {
      clearInterval(intervalId);
      dispatch(limpiarTarjeta());
    });
  }, []);

  const editTarjeta = () => {
    navigate(`/editar-tarjeta/${id}`);
  };
  const redirect = () => {
    navigate(TARJETA_PATH);
  };

  const handleDelete = () => {
    dispatch(deleteTarjeta(id, redirect));
  };

  return (
    <div>
      <div className="header-nav">
        <Bread routes={routeTarjetaInfo} />
        <Popconfirm
          title="¿Estás seguro que deseas eliminar la tarjeta?"
          onConfirm={handleDelete}
          okText="Si"
          cancelText="Cancelar"
          placement="right"
        >
          {rol !== 'empresa' && <Button type="submit" text="Eliminar tarjeta" color="redd" />}
        </Popconfirm>
      </div>
      <div className="info-container">
        {isLoading
          ? (
            <div className="container-spin">
              <Spin tip="Cargando..." />
            </div>
          )
          : (
            <>
              {tarjeta === undefined && isLoading === true
                ? (
                  <p className="info-container__nothing">Los detalles de la tarjeta no se encontrarón</p>
                )
                : (
                  <>
                    <FormTarjeta
                      data={form}
                      isEdit
                      disabled={disabled}
                      elementos={elementos}
                    />
                    <div className="form-info-container__button-edit">
                      {rol !== 'empresa' && <Button type="submit" text="Editar tarjeta" onClick={editTarjeta} color="gray" />}
                    </div>
                    <AccordionSensores data={tarjeta} />
                    <div className="info-container__grafica">
                      <Accordion header="Graficas" keyPanel="grafica">
                        {tarjeta?.graficas
                    && (
                      <>
                        {tarjeta?.graficas.length !== 0 ? (
                          <div className="graficas-items">
                            {tarjeta.graficas.map((item) => (
                              <div key={item._id}>
                                <CardGrafica
                                  idGrafica={item._id}
                                  title={item.nombre}
                                  data={item.sensores}
                                />
                                {/* <Tab dataGrafica={item} dataSankey={item} /> */}
                              </div>
                            ))}
                          </div>
                        ) : <p className="text-nothing">No hay graficas para mostrar</p>}
                      </>
                    ) }
                      </Accordion>
                    </div>
                  </>
                )}
            </>
          )}
      </div>
    </div>
  );
};

export default InfoTarjeta;

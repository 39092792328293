export const SET_TOKEN = 'SET_TOKEN';
export const SET_FLASH_MESSAGE = 'SET_FLASH_MESSAGE';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// perfil usuario
export const FETCH_USER = 'FETCH_USER';
export const SET_USER_HEADER = 'SET_USER_HEADER';
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const CREATE_USER = 'CREATE_USER';

// empresa
export const FETCH_EMPRESA = 'FETCH_EMPRESA';
export const FETCH_EMPRESA_NOT_PAGINATION = 'FETCH_EMPRESA_NOT_PAGINATION';

export const SET_EMPRESA = 'SET_EMPRESA';
export const CREATE_EMPRESA = 'CREATE_EMPRESA';
export const UPDATE_EMPRESA = 'UPDATE_EMPRESA';
export const DELETE_EMPRESA = 'DELETE_EMPRESA';
export const FETCH_EMPRESA_BY_ID = 'FETCH_EMPRESA_BY_ID';

// usuarios
export const FETCH_USUARIO = 'FETCH_USER';
export const CREATE_USUARIO = 'CREATE_USUARIO';
export const FETCH_USUARIO_BY_ID = 'FETCH_USUARIO_BY_ID';

// proyecto
export const FETCH_PROYECTO_BY_ID = 'FETCH_PROYECTO_BY_ID';
export const FETCH_PROYECTO = 'FETCH_PROYECTO';
export const FETCH_PROYECTO_NOT_PAGINATION = 'FETCH_PROYECTO_NOT_PAGINATION';
export const FETCH_PROYECTO_GRAFICA = 'FETCH_PROYECTO_GRAFICA';
export const FETCH_PROYECTO_BY_ID_GRAFICA = 'FETCH_PROYECTO_BY_ID_GRAFICA';
export const CREATE_PROYECTO = 'CREATE_PROYECTO';
export const EDITAR_PROYECTO = 'EDITAR_PROYECTO';
export const DELETE_PROYECTO = 'DELETE_PROYECTO';

// elemento
export const FETCH_ELEMENTO_BY_ID = 'FETCH_ELEMENTO_BY_ID';
export const FETCH_ELEMENTO_BY_ID_GRAFICA = 'FETCH_ELEMENTO_BY_ID_GRAFICA';
export const FETCH_ELEMENTO_NOT_PAGINATION = 'FETCH_ELEMENTO_NOT_PAGINATION';
export const FETCH_PROYECTO_BY_EMPRESA = 'FETCH_PROYECTO_BY_EMPRESA';
export const FETCH_ELEMENTO = 'FETCH_ELEMENTO';
export const CREATE_ELEMENTO = 'CREATE_ELEMENTO';
export const EDITAR_ELEMENTO = 'EDITAR_ELEMENTO';
export const DELETE_ELEMENTO = 'DELETE_ELEMENTO';

// sensor
export const FETCH_SENSOR_BY_ID = 'FETCH_SENSOR_BY_ID';
export const FETCH_SENSOR_BY_ID_GRAFICA = 'FETCH_SENSOR_BY_ID_GRAFICA';
export const FETCH_SENSOR = 'FETCH_SENSOR';
export const CREATE_SENSOR = 'CREATE_SENSOR';
export const EDITAR_SENSOR = 'EDITAR_SENSOR';
export const DELETE_SENSOR = 'DELETE_SENSOR';

// tipo sensor
export const FETCH_TIPO_SENSOR = 'FETCH_TIPO_SENSOR';
export const FETCH_TARJETA_BY_ELEMENTO = 'FETCH_TARJETA_BY_ELEMENTO';

// tarjeta
export const FETCH_TARJETA = 'FETCH_TARJETA';
export const FETCH_TARJETA_BY_ID = 'FETCH_TARJETA_BY_ID';
export const FETCH_TARJETA_BY_ID_GRAFICA = 'FETCH_TARJETA_BY_ID_GRAFICA';
export const FETCH_TARJETA_NOT_PAGINATION = 'FETCH_TARJETA_NOT_PAGINATION';
export const FETCH_ELEMENTO_BY_PROYECTO = 'FETCH_ELEMENTO_BY_PROYECTO';
export const CREATE_TARJETA = 'CREATE_TARJETA';
export const EDITAR_TARJETA = 'EDITAR_TARJETA';
export const DELETE_TARJETA = 'DELETE_TARJETA';

// grafica
export const FETCH_GRAFICA = 'FETCH_GRAFICA';
export const CREAR_GRAFICA = 'CREAR_GRAFICA';
export const FETCH_ELEMENTO_GRAFICA = 'FETCH_ELEMENTO_GRAFICA';
export const FETCH_TARJETA_GRAFICA = 'FETCH_TARJETA_GRAFICA';
export const FETCH_GRAFICA_BY_ID = 'FETCH_GRAFICA_BY_ID';
export const FETCH_GRAFICA_BY_ID_REFRESH = 'FETCH_GRAFICA_BY_ID_REFRESH';
export const FETCH_TIPO_GRAFICA = 'FETCH_TIPO_GRAFICA';
export const FETCH_SENSORES_GRAFICA = 'FETCH_SENSORES_GRAFICA';
export const ADD_SENSORES_GRAFICA = 'ADD_SENSORES_GRAFICA';
export const DELETE_SENSORES_GRAFICA = 'DELETE_SENSORES_GRAFICA';
export const CHANGE_ELEMENTO_GRAFICA = 'CHANGE_ELEMENTO_GRAFICA';
export const CHANGE_TARJETA_GRAFICA = 'CHANGE_TARJETA_GRAFICA';
export const LIMPIAR_GRAFICA = 'LIMPIAR_GRAFICA';
export const DELETE_GRAFICA = 'DELETE_GRAFICA';

// reset
export const SEND_EMAIL = 'SEND_EMAIL';
export const RESET_PASS = 'RESET_PASS';

// objetivo
export const AGREGAR_OBJETIVO = 'AGREGAR_OBJETIVO';
export const FETCH_OBJETIVO_PROYECTO = 'FETCH_OBJETIVO_PROYECTO';
export const FETCH_OBJETIVO_ELEMENTO = 'FETCH_OBJETIVO_ELEMENTO';
export const FETCH_OBJETIVOS_TIPO_SENSOR = 'FETCH_OBJETIVOS_TIPO_SENSOR';

// reporte

export const FETCH_PROYECTO_REPORTE = 'FETCH_PROYECTO_REPORTE';
export const FETCH_TIPO_REPORTE = 'FETCH_TIPO_REPORTE';
export const FETCH_REPORTE_ELEMENTO_BY_ID = 'FETCH_REPORTE_ELEMENTO_BY_ID';
export const FETCH_REPORTE_TARJETA_BY_ID = 'FETCH_REPORTE_TARJETA_BY_ID';

export const FETCH_REPORTE_PROYECTO_SENSORES = 'FETCH_REPORTE_PROYECTO_SENSORES';
export const FETCH_REPORTE_ELEMENTO_SENSORES = 'FETCH_REPORTE_ELEMENTO_SENSORES';
export const FETCH_REPORTE_TARJETA_SENSORES = 'FETCH_REPORTE_TARJETA_SENSORES';
export const GENERAR_REPORTE_BASE = 'GENERAR_REPORTE_BASE';
export const GENERAR_REPORTE_ACUMULADO = 'GENERAR_REPORTE_ACUMULADO';
export const LIMPIAR_SENSORES = 'LIMPIAR_SENSORES';

export const LIMPIAR_ESTADO_USUARIO = 'LIMPIAR_ESTADO_USUARIO';
export const LIMPIAR_ESTADO_EMPRESA = 'LIMPIAR_ESTADO_EMPRESA';
export const LIMPIAR_ESTADO_PROYECTO = 'LIMPIAR_ESTADO_PROYECTO';
export const LIMPIAR_ESTADO_ELEMENTO = 'LIMPIAR_ESTADO_ELEMENTO';
export const LIMPIAR_ESTADO_TARJETA = 'LIMPIAR_ESTADO_TARJETA';
export const LIMPIAR_ESTADO_SENSOR = 'LIMPIAR_ESTADO_SENSOR';
export const LIMPIAR_ESTADO_GRAFICA = 'LIMPIAR_ESTADO_GRAFICA';
export const LIMPIAR_ESTADO_REPORTE = 'LIMPIAR_ESTADO_REPORTE';
export const CHANGE_TIPO_REPORTE = 'CHANGE_TIPO_REPORTE';

import { handle } from 'redux-pack';
import {
  FETCH_SENSOR,
  FETCH_SENSOR_BY_ID,
  FETCH_SENSOR_BY_ID_GRAFICA,
  FETCH_TIPO_SENSOR,
  LIMPIAR_ESTADO_SENSOR,
  LOGOUT,
} from '../actions/types';
import { handleAction } from '../utils/handleAction';

const initialState = {
  sensors: [],
  isLoading: false,
  error: '',
  sensor: undefined
};

const SensorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SENSOR:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          sensors: payload.data.data,
          total: payload.data.total
        }),
      });
    case FETCH_TIPO_SENSOR:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          tiposensores: payload.data,
        }),
      });
    case FETCH_SENSOR_BY_ID:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          sensor: payload.data,
        }),
      });
    case FETCH_SENSOR_BY_ID_GRAFICA:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          sensor: payload.data,
        }),
      });
    case LIMPIAR_ESTADO_SENSOR:
      return { ...initialState };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default SensorReducer;

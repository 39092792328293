import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../../components/accordion';
import { columnsSensores, parserDataTabla } from '../../../utils/parsers/table';
import Card from '../../../components/card';
import { parserSensores } from '../../../utils/parsers/sensor';

const AccordionSensores = ({ data }) => (
  <div>
    {data && data.sensores && (
      <div className="page-container__accordion">
        <Accordion header="Sensores">
          {data.sensores.length !== 0 ? (
            data.sensores.map((item) => (
              <div key={item.nombre}>
                <Card
                  columns={columnsSensores}
                  data={[parserDataTabla(parserSensores(item))]}
                  header={item.nombre}
                  route="info-sensor"
                  sensores
                />
              </div>
            ))
          )
            : <p className="text-nothing">No hay datos para mostrar</p>}
        </Accordion>
      </div>
    )}
  </div>
);
AccordionSensores.propTypes = {
  data: PropTypes.shape({
    sensores: PropTypes.arrayOf(PropTypes.shape({
      nombre: PropTypes.string
    }))
  }),
};

AccordionSensores.defaultProps = {
  data: undefined,
};

export default AccordionSensores;

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */
import { Popconfirm, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteElemento, fetchElementoById, fetchElementoByIdGrafica, limpiarElemento
} from '../../../actions/elemento';
import Accordion from '../../../components/accordion';
import AccordionObjetivos from '../../../components/acordion-objetivos';
import Bread from '../../../components/breadcrumb';
import Tab from '../../../components/tab';
import { routeElementoInfo } from '../../../utils/routesBread';
import AccordionElemento from '../accordion-elemento';
import FormElemento from '../form';
import Button from '../../../components/button';
import { ELEMENTO_PATH } from '../../../routes/path';
import { fetchProyectoNotPagination } from '../../../actions/proyecto';

const InfoElemento = () => {
  const { proyectos } = useSelector((state) => state.proyectoReducer);
  const { rol } = useSelector((state) => state.userReducer.user);
  const { elemento, isLoading } = useSelector((state) => state.elementoReducer);
  const [form, setForm] = useState({
    nombre: '',
    descripcion: '',
    area: '',
    linea: '',
    proyecto: '',
    proceso: '',
    tipo: '',
    empresa: ''
  });
  const [disabled, setDisabled] = useState(true);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateObjetivos = () => {
    dispatch(fetchElementoById(id));
  };

  const redirectEditElementos = () => {
    navigate(`/editar-elemento/${id}`);
  };

  useEffect(() => {
    dispatch(fetchProyectoNotPagination());
    dispatch(fetchElementoById(id));
  }, []);

  useEffect(() => {
    setForm({
      nombre: elemento && String(elemento.nombre),
      descripcion: elemento && elemento.descripcion,
      area: elemento && elemento.area,
      linea: elemento && elemento.linea,
      proyecto: elemento && elemento?.proyecto?.nombre,
      empresa: elemento && elemento?.proyecto?.empresa?.nombre,
      proceso: elemento && elemento.proceso,
      tipo: elemento && elemento.tipo,
    });
  }, [elemento]);

  useEffect(() => {
    const intervalId = setInterval(() => dispatch(fetchElementoByIdGrafica(id)), 30000);
    return () => {
      clearInterval(intervalId);
      dispatch(limpiarElemento());
    };
  }, []);

  const redirect = () => {
    navigate(ELEMENTO_PATH);
  };

  const handleDelete = () => {
    dispatch(deleteElemento(id, redirect));
  };
  return (
    <div>
      <div className="header-nav">
        <Bread routes={routeElementoInfo} />
        <Popconfirm
          title="¿Estás seguro que deseas eliminar el elemento?"
          onConfirm={handleDelete}
          okText="Si"
          cancelText="Cancelar"
          placement="right"
        >
          {rol !== 'empresa' && <Button type="submit" text="Eliminar elemento" color="redd" />}
        </Popconfirm>
      </div>
      <div className="info-container">

        {isLoading
          ? (
            <div className="container-spin">
              <Spin tip="Cargando..." />
            </div>
          )
          : (
            <>
              {elemento === undefined && isLoading === true
                ? (
                  <p className="info-container__nothing">Los detalles del elemento no se encontrarón</p>
                )
                : (
                  <>
                    <FormElemento
                      data={form}
                      disabled={disabled}
                      setDisabled={setDisabled}
                      proyectos={proyectos}
                    />
                    <div className="form-info-container__button-edit">
                      {rol !== 'empresa' && <Button type="submit" text="Editar elemento" color="gray" onClick={redirectEditElementos} />}
                    </div>
                    <AccordionObjetivos
                      dataSource={elemento?.objetivos}
                      isLoading={isLoading}
                      onUpdated={updateObjetivos}
                    />
                    <AccordionElemento data={elemento} />
                    <div className="info-container__grafica">
                      <Accordion header="Graficas" keyPanel="grafica">
                        {(elemento?.graficas || elemento?.graficaSankey)
                          && (
                            <Tab
                              dataGrafica={elemento?.graficas}
                              dataSankey={elemento?.graficasSankey}
                            />
                          )}
                      </Accordion>
                    </div>
                  </>
                )}
            </>
          )}
      </div>
    </div>
  );
};

export default InfoElemento;

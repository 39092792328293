/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Input from '../../../components/input';
import { validateField } from '../../../utils/validations';
import Button from '../../../components/button';
import { newPassword } from '../../../actions/app';
import { setChangeAuthentication } from '../../../services/api';

const NuevaContraseña = () => {
  const [form, setForm] = useState({
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState({
    password: '',
    confirmPassword: ''
  });
  const { token } = useParams();
  const dispatch = useDispatch();
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleBlur = (key, value) => {
    const fieldError = validateField(key, value);
    setError({
      ...error,
      ...fieldError,
    });
  };
  useEffect(() => {
    localStorage.setItem('token', token);
    setChangeAuthentication();
  }, [token]);

  const handleSubmit = () => {
    dispatch(newPassword(form));
  };

  return (
    <div className="reset-container">
      <h2>Nueva contraseña</h2>
      <div className="reset-container__input">
        <Input
          placeholder="Ingresar nueva contraseña"
          name="password"
          label=""
          value={form.password}
          inputType="password"
          onChange={handleChange}
          onBlur={handleBlur}
          error={error.password}
        />
        <Input
          placeholder="Confirmar nueva contraseña"
          name="confirmPassword"
          label=""
          value={form.confirmPassword}
          inputType="password"
          onChange={handleChange}
          onBlur={handleBlur}
          error={error.confirmPassword}
        />
        <Button onClick={handleSubmit} text="Enviar" color="primary" />
      </div>
    </div>
  );
};

export default NuevaContraseña;

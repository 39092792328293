/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import { combineReducers } from 'redux';
import appReducer from './appReducer';
import userReducer from './userReducer';
import empresaReducer from './empresaReducer';
import proyectoReducer from './proyectoReducer';
import elementoReducer from './elementoReducer';
import usuarioReducer from './usuarioReducer';
import sensorReducer from './sensorReducer';
import tarjetaReducer from './tarjetaReducer';
import graficaReducer from './graficaReducer';
import { LOGOUT } from '../actions/types';
import { removeTokens } from '../services/api';
import objetivoReducer from './objetivoReducer';
import reporteReducer from './reporteReducer';

const MainReducer = combineReducers({
  appReducer,
  userReducer,
  empresaReducer,
  proyectoReducer,
  elementoReducer,
  usuarioReducer,
  sensorReducer,
  tarjetaReducer,
  graficaReducer,
  objetivoReducer,
  reporteReducer,
});
const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    removeTokens();
  }
  return MainReducer(state, action);
};
export default rootReducer;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo/logo_azul.png';
import Button from '../../components/button';

const Error404 = () => {
  const navigate = useNavigate();
  const redirectPage = () => {
    navigate(-1);
  };
  return (
    <div className="container-404">
      <img src={Logo} alt="Logo" />
      <p>No se encontro la pagina</p>
      <Button text="Volver a la pagina anterior" color="gray" onClick={redirectPage} />
    </div>
  );
};

export default Error404;

export const niveles = [
  {
    _id: 'proyecto',
    nombre: 'Proyecto'
  },
  {
    _id: 'elemento',
    nombre: 'Elemento'
  },
  {
    _id: 'tarjeta',
    nombre: 'Tarjeta'
  }
];
export const nivelesReporte = [
  {
    _id: 'proyecto',
    nombre: 'Proyecto'
  },
  {
    _id: 'elemento',
    nombre: 'Elemento'
  },
];

/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../../components/accordion';
import { columnsElementos, columnsTarjetas, parserDataTabla } from '../../../utils/parsers/table';
import Card from '../../../components/card';
import { parserElementos } from '../../../utils/parsers/elemento';
import { parserTarjeta } from '../../../utils/parsers/tarjeta';

const AccordionProyecto = ({ data }) => (
  <div>
    {data && data?.elementos && (
      <div className="page-container__accordion">
        <Accordion header="Elementos">
          {data.elementos.length !== 0 ? (
            <>
              {data.elementos.map((elemento) => (
                <div key={parserElementos(elemento).id}>
                  <Card
                    columns={columnsElementos}
                    data={[parserDataTabla(elemento)]}
                    header={elemento.nombre}
                    route="info-elemento"
                  />
                  {elemento?.tarjetas
                  && (
                  <Accordion header="Tarjetas">
                    {elemento.tarjetas.length !== 0 ? (
                      <>
                        {elemento.tarjetas.map((tarjeta) => (
                          <Card
                            key={tarjeta._id}
                            columns={columnsTarjetas}
                            data={[parserDataTabla(parserTarjeta(tarjeta))]}
                            header={tarjeta.nombre}
                            route="info-tarjeta"
                          />
                        ))}
                      </>
                    )
                      : <p className="text-nothing">No hay datos para mostrar</p>}
                  </Accordion>
                  )}
                </div>
              ))}
            </>
          )
            : <p className="text-nothing">No hay datos para mostrar</p>}
        </Accordion>
      </div>
    )}
  </div>
);
AccordionProyecto.propTypes = {
  data: PropTypes.shape({
    elementos: PropTypes.arrayOf(PropTypes.shape({
      tarjetas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string
      }))
    }))
  }),
};

AccordionProyecto.defaultProps = {
  data: undefined,
};

export default AccordionProyecto;

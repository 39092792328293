import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ textError }) => (
  <div className="error-container">
    <p className="error-container__error">{ textError }</p>
  </div>
);

ErrorMessage.propTypes = {
  textError: PropTypes.string,
};
ErrorMessage.defaultProps = {
  textError: ''
};

export default ErrorMessage;

/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Bread from '../../../components/breadcrumb';
import { routeElementoCreate, routeElementoEdit } from '../../../utils/routesBread';
import FormElemento from '../form';
import { ELEMENTO_PATH } from '../../../routes/path';
import {
  createElemento, fetchElementoById, limpiarElemento, updateElemento
} from '../../../actions/elemento';
import { fetchEmpresaNotPagination, fetchProyectoByEmpresa } from '../../../actions/empresa';

const CrearElemento = () => {
  const [form, setForm] = useState({
    nombre: '',
    descripcion: '',
    area: '',
    linea: '',
    proyecto: '',
    proceso: '',
    tipo: '',
    empresa: ''
  });
  const [selectedEmpresa, setSelectedEmpresa] = useState('');
  const { elemento } = useSelector((state) => state.elementoReducer);
  const { proyectos } = useSelector((state) => state.proyectoReducer);
  const { empresas } = useSelector((state) => state.empresaReducer);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchEmpresaNotPagination());
    if (pathname.includes('editar')) {
      dispatch(fetchElementoById(id));
    }
    return () => {
      dispatch(limpiarElemento());
    };
  }, []);

  useEffect(() => {
    if (selectedEmpresa) dispatch(fetchProyectoByEmpresa(selectedEmpresa));
  }, [selectedEmpresa]);

  useEffect(() => {
    setForm({
      nombre: elemento && elemento.nombre,
      descripcion: elemento && elemento.descripcion,
      area: elemento && elemento.area,
      linea: elemento && elemento.linea,
      proyecto: elemento && elemento?.proyecto?._id,
      empresa: elemento && elemento?.proyecto?.empresa?._id,
      proceso: elemento && elemento.proceso,
      tipo: elemento && elemento.tipo,
    });
    setSelectedEmpresa(elemento?.proyecto?.empresa?._id);
  }, [elemento]);

  const redirectElementos = () => {
    navigate(ELEMENTO_PATH);
  };

  const redirectProfile = () => {
    navigate(`/info-elemento/${id}`);
  };

  const handleCreate = () => {
    delete form.empresa;
    dispatch(createElemento(form, redirectElementos));
  };

  const handleEdit = () => {
    delete form.empresa;
    dispatch(updateElemento(id, form, redirectProfile));
  };

  const handleChange = (newForm) => {
    if (newForm?.empresa !== form?.empresa) {
      setForm({
        ...newForm,
        proyecto: '',
      });
    } else {
      setForm(newForm);
    }
    setSelectedEmpresa(newForm?.empresa);
  };
  return (
    <>
      <div className="header-nav">
        <Bread routes={pathname.includes('crear-elemento') ? routeElementoCreate : routeElementoEdit} />
      </div>
      <div className="info-container">
        <div className="info-container">
          {pathname.includes('crear')
            ? (
              <FormElemento
                data={form}
                proyectos={proyectos}
                onSubmit={handleCreate}
                onChange={handleChange}
                empresas={empresas}
              />
            ) : (
              <FormElemento
                data={form}
                proyectos={proyectos}
                onSubmit={handleEdit}
                onChange={handleChange}
                empresas={empresas}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default CrearElemento;

/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable func-call-spacing */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import PropTypes, { bool } from 'prop-types';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { Collapse, Spin } from 'antd';
import { setMinutes, setHours } from 'date-fns';
import { thereIsErrorInForm, validateDate, validateField } from '../../../utils/validations';
import Select from '../../../components/select';
import Button from '../../../components/button';
import ErrorMessage from '../../../components/errorMessage';
import ConfiguracionAvanzada from '../configuracion-avanzada-base';
import { periodos } from '../../../utils/periodos';
import ConfiguracionAvanzadaAcumulado from '../configuracion-avanzada-acumulado';
import { nivelesReporte } from '../../../utils/niveles';

const { Panel } = Collapse;

registerLocale('es', es);
const initialError = {
  tipoReporte: '',
  nivel: '',
  proyecto: '',
  elemento: '',
  tarjeta: '',
  fechaInicio: '',
  fechaFin: '',
  periodo: ''
};
const FormReporte = (
  {
    data,
    onSubmit,
    onChange,
    proyectos,
    tipoReportes,
    elementos,
    tarjetas,
    niveles,
    sensores,
    isLoading,
    tipoReporte,
    tipoSensores,
    sensoresChecked,
    setChecked,
    allSensores,
    disabledButton
  }
) => {
  const [fechaInicio, setFechaInicio] = useState();
  const [error, setError] = useState(initialError);

  const handleSubmit = (e) => {
    e.preventDefault();
    const tipoReporteError = validateField('tipoReporte', data.tipoReporte);
    const periodoError = validateField('periodo', data.periodo);
    const fechaFinError = validateDate('fechaFin', data.fechaFin);
    const fechaInicioError = validateDate('fechaInicio', data.fechaInicio);
    const nivelError = validateField('nivel', data.nivel);
    const proyectoError = validateField('proyecto', data.proyecto);
    const elementoError = data.nivel === 'elemento' || data.nivel === 'tarjeta' ? validateField('elemento', data.elemento) : '';
    const tarjetaError = data.nivel === 'tarjeta' ? validateField('tarjeta', data.tarjeta) : '';
    let errorMessage = {
      ...error,
      ...tipoReporteError,
      ...periodoError,
      ...nivelError,
      ...proyectoError,
      ...elementoError,
      ...tarjetaError,
      ...fechaInicioError,
      ...fechaFinError,
    };
    if (tipoReporte === 'Base') {
      setError({ ...errorMessage, periodo: '' });
      errorMessage = {
        ...errorMessage, periodo: ''
      };
    }
    setError(errorMessage);
    if (!thereIsErrorInForm(errorMessage)) {
      onSubmit().then();
    }
  };

  const handleBlur = (key, value) => {
    if (key === 'fechaInicio' || key === 'fechaFin') {
      const fieldError = validateDate(key, value);
      setError({
        ...error,
        ...fieldError,
      });
    } else {
      const fieldError = validateField(key, value);
      setError({
        ...error,
        ...fieldError,
      });
    }
  };

  const handleChange = (name, value) => {
    let formReset;
    if (value !== '') {
      if (name === 'secundario' && value === '') {
        formReset = {
          ...data,
          conversion: '',
          [name]: value,
        };
        onChange(formReset);
      } else if (name === 'periodo') {
        formReset = {
          ...data,
          nivel: '',
          proyecto: '',
          elemento: '',
          tarjeta: '',
          fechaInicio: '',
          fechaFin: '',
          [name]: value,
        };
        onChange(formReset);
      } else if (name === 'tipoReporte') {
        formReset = {
          ...data,
          nivel: '',
          proyecto: '',
          elemento: '',
          tarjeta: '',
          fechaInicio: '',
          fechaFin: '',
          seccion: ['General', 'Bloques'],
          sensores: [],
          principal: '',
          secundario: '',
          periodo: '',
          [name]: value,
        };
        onChange(formReset);
        setError(initialError);
      } else if (name === 'nivel') {
        formReset = {
          ...data,
          proyecto: '',
          elemento: '',
          tarjeta: '',
          sensores: [],
          [name]: value,
        };
        onChange(formReset);
      } else if (name === 'proyecto') {
        formReset = {
          ...data,
          elemento: '',
          tarjeta: '',
          sensores: [],
          [name]: value,
        };
        onChange(formReset);
      } else {
        onChange({
          ...data,
          [name]: value,
        });
      }
      handleBlur(name, value);
    }
  };

  useEffect(() => {
    if (data.nivel === 'proyecto') {
      const errorProyecto = { ...error, elemento: '', tarjeta: '' };
      setError(errorProyecto);
    }
    if (data.nivel === 'elemento') {
      const errorElemento = { ...error, tarjeta: '' };
      setError(errorElemento);
    }
  }, [data.nivel]);

  useEffect(() => {
    setFechaInicio(new Date(data.fechaInicio));
  }, [data.fechaInicio]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-info-container">
        <div className="form-info-container__box-container-up">
          <div className="form-info-container__box-grid">
            <Select
              label="Tipo Reporte"
              value={data.tipoReporte}
              onChange={handleChange}
              onBlur={handleBlur}
              options={tipoReportes}
              name="tipoReporte"
              error={error.tipoReporte}
              disabled={disabledButton}
            />
            {tipoReporte === 'Acumulado' && (
              <Select
                label="Periodo"
                value={data.periodo}
                onChange={handleChange}
                onBlur={handleBlur}
                options={periodos}
                name="periodo"
                error={error.periodo}
              />
            )}
          </div>
          { tipoReporte !== '' && (
          <div className="form-info-container__box-grid">
            <div className="input-container">
              <p>Inicio</p>
              <ReactDatePicker
                showIcon
                onBlur={(date) => handleBlur('fechaInicio', date.target.value)}
                selected={data.fechaInicio}
                onChange={(date) => handleChange('fechaInicio', date)}
                showTimeSelect={tipoReporte === 'Base'}
                maxDate={tipoReporte === 'Base' && new Date()}
                maxTime={new Date(data.fechaInicio).toLocaleDateString() === new Date().toLocaleDateString()
                  ? setHours(setMinutes(new Date(), new Date().getUTCMinutes()), new Date().getHours())
                  : setHours(setMinutes(new Date(), 59), 23)}
                minTime={setHours(setMinutes(new Date(), 0), 0)}
                showMonthYearPicker={data.periodo === 'Mensual'}
                dateFormat={data.periodo === 'Mensual' ? 'MMMM yyyy' : tipoReporte === 'Base' ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
                locale="es"
                disabled={disabledButton || (data.periodo === '' && tipoReporte === 'Acumulado')}
              />
              <ErrorMessage textError={error.fechaInicio} />
            </div>
            <div className="input-container">
              <p>Fin</p>
              <ReactDatePicker
                showIcon
                onBlur={(date) => handleBlur('fechaFin', date.target.value)}
                selected={data.fechaFin}
                onChange={(date) => handleChange('fechaFin', date)}
                minDate={fechaInicio}
                maxDate={tipoReporte === 'Base' && new Date()}
                minTime={new Date(data.fechaFin).toLocaleDateString() === new Date(data.fechaInicio).toLocaleDateString()
                  ? setHours(setMinutes(new Date(data.fechaInicio), new Date(data.fechaInicio).getUTCMinutes()), new Date(data.fechaInicio).getHours())
                  : setHours(setMinutes(new Date(), 0), 0)}
                maxTime={new Date(data.fechaFin).toLocaleDateString() === new Date().toLocaleDateString()
                  ? setHours(setMinutes(new Date(), new Date().getUTCMinutes()), new Date().getHours())
                  : setHours(setMinutes(new Date(), 59), 23)}
                showTimeSelect={tipoReporte === 'Base'}
                showMonthYearPicker={data.periodo === 'Mensual'}
                dateFormat={data.periodo === 'Mensual' ? 'MMMM yyyy' : tipoReporte === 'Base' ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
                locale="es"
                disabled={disabledButton || (data.periodo === '' && tipoReporte === 'Acumulado')}
              />
              <ErrorMessage textError={error.fechaFin} />
            </div>
            <Select
              label="Nivel"
              value={data.nivel}
              onChange={handleChange}
              onBlur={handleBlur}
              options={tipoReporte === 'Acumulado' ? nivelesReporte : niveles}
              name="nivel"
              disabled={disabledButton}
              error={error.nivel}
            />
            <Select
              label="Proyectos"
              value={data.proyecto}
              onChange={handleChange}
              onBlur={handleBlur}
              options={proyectos}
              name="proyecto"
              disabled={disabledButton}
              error={error.proyecto}
            />
            { data.nivel === 'elemento' && (
            <Select
              label="Elementos"
              value={data.elemento}
              onChange={handleChange}
              onBlur={handleBlur}
              options={elementos}
              name="elemento"
              disabled={disabledButton}
              error={error.elemento}
            />
            )}
            {
              data.nivel === 'tarjeta' && (
                <>
                  <Select
                    label="Elementos"
                    value={data.elemento}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={elementos}
                    name="elemento"
                    disabled={disabledButton}
                    error={error.elemento}
                  />
                  <Select
                    label="Tarjetas"
                    value={data.tarjeta}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={tarjetas}
                    name="tarjeta"
                    disabled={disabledButton}
                    error={error.tarjeta}
                  />
                </>
              )
            }
          </div>
          )}
        </div>
        <div>
          <Collapse
            ghost

          >
            {tipoReporte === 'Base'
            && (
            <Panel showArrow header="Configuración avanzada" key="1">
              {sensores !== []
              && (
                <div>
                  {isLoading ? (
                    <div className="container-spin">
                      <Spin tip="Cargando..." />
                    </div>
                  )
                    : (
                      <ConfiguracionAvanzada
                        sensores={sensores}
                        data={data}
                        onChange={handleChange}
                        sensoresChecked={sensoresChecked}
                        setChecked={setChecked}
                        allSensores={allSensores}
                        disabled={disabledButton}
                      />
                    )}
                </div>
              )}
            </Panel>
            )}
            {tipoReporte === 'Acumulado'
            && (
            <Panel showArrow header="Configuración avanzada" key="2">
              <ConfiguracionAvanzadaAcumulado
                data={data}
                onChange={handleChange}
                onBlur={handleBlur}
                tipoSensores={tipoSensores}
                error={error}
                disabled={disabledButton}
              />
            </Panel>
            )}
          </Collapse>
        </div>
        <div className="form-info-container__button--center">
          {disabledButton
            ? (
              <div style={{ textAlign: 'center', paddingBlock: '2rem' }}>
                <Spin />
              </div>
            )
            : (
              <Button
                type="submit"
                text="Generar"
                color="primary"
                disabled={(tipoReporte === 'Base' ? sensoresChecked.length === 0 : false)}
              />
            )}
        </div>
      </div>
    </form>
  );
};

FormReporte.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.shape().isRequired,
  proyectos: PropTypes.arrayOf(PropTypes.shape()),
  tipoReportes: PropTypes.arrayOf(PropTypes.shape()),
  elementos: PropTypes.arrayOf(PropTypes.shape()),
  tarjetas: PropTypes.arrayOf(PropTypes.shape()),
  sensores: PropTypes.any,
  niveles: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  tipoReporte: PropTypes.string,
  tipoSensores: PropTypes.arrayOf(PropTypes.shape()),
  sensoresChecked: PropTypes.any,
  allSensores: PropTypes.any,
  setChecked: PropTypes.func,
  disabledButton: bool.isRequired
};

FormReporte.defaultProps = {
  tipoReportes: [],
  proyectos: [],
  elementos: [],
  tarjetas: [],
  niveles: [],
  tipoSensores: [],
  sensores: {},
  onSubmit: () => {},
  onChange: () => {},
  setChecked: () => {},
  isLoading: false,
  tipoReporte: '',
  sensoresChecked: [],
  allSensores: []
};

export default FormReporte;

/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Bread from '../../../components/breadcrumb';
import FormProyecto from '../form';
import { routeProyectoCrear, routeProyectoEditar } from '../../../utils/routesBread';
import {
  createProyecto, fetchProyectoById, limpiarProyecto, updateProyecto
} from '../../../actions/proyecto';
import { fetchEmpresaNotPagination } from '../../../actions/empresa';
import { PROYECTO_PATH } from '../../../routes/path';

const CrearEditProyecto = () => {
  const [form, setForm] = useState({
    nombre: '',
    descripcion: '',
    area: '',
    linea: '',
    empresa: '',
    proceso: '',
    identificacion: '',
    tipo: '',
  });

  const { proyecto } = useSelector((state) => state.proyectoReducer);
  const { empresas } = useSelector((state) => state.empresaReducer);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleChange = (newForm) => {
    setForm(newForm);
  };

  useEffect(() => {
    setForm({
      nombre: proyecto && proyecto.nombre,
      descripcion: proyecto && proyecto.descripcion,
      area: proyecto && proyecto.area,
      linea: proyecto && proyecto.linea,
      empresa: proyecto && proyecto?.empresa?._id,
      proceso: proyecto && proyecto.proceso,
      identificacion: proyecto && proyecto.identificacion,
      tipo: proyecto && proyecto.tipo,
    });
  }, [proyecto]);

  useEffect(() => {
    dispatch(fetchEmpresaNotPagination());
    if (pathname.includes('editar-proyecto')) {
      dispatch(fetchProyectoById(id));
    }

    return () => {
      dispatch(limpiarProyecto());
    };
  }, []);

  const redirectProyectos = () => {
    navigate(PROYECTO_PATH);
  };
  const redirectProfile = () => {
    navigate(`/info-proyecto/${id}`);
  };

  const handleCreate = () => {
    dispatch(createProyecto(form, redirectProyectos));
  };

  const handleEdit = () => {
    dispatch(updateProyecto(id, form, redirectProfile));
  };

  return (
    <>
      <div className="header-nav">
        <Bread routes={pathname.includes('crear-proyecto') ? routeProyectoCrear : routeProyectoEditar} />
      </div>
      <div className="info-container">
        {pathname.includes('crear-proyecto')
          ? (
            <FormProyecto
              data={form}
              empresas={empresas}
              onSubmit={handleCreate}
              onChange={handleChange}
            />
          ) : (
            <FormProyecto
              data={form}
              empresas={empresas}
              onSubmit={handleEdit}
              onChange={handleChange}
            />
          )}
      </div>
    </>
  );
};

export default CrearEditProyecto;

/* eslint-disable no-undef */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { menuItems, menuItemsEmpresa } from '../../utils/sidebar';

const { Sider } = Layout;

const Sidebar = ({ onShow }) => {
  const { rol } = useSelector((state) => state.userReducer.user);
  const { isLoading } = useSelector((state) => state.userReducer);
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = () => setCollapsed(!collapsed);

  return (
    <Sider
      collapsible
      onCollapse={onCollapse}

    >
      {!isLoading
      && <Menu onClick={() => onShow()} items={rol === 'empresa' ? menuItemsEmpresa : menuItems} theme="dark" mode="inline" />}
    </Sider>
  );
};
Sidebar.propTypes = {
  onShow: PropTypes.func,
};
Sidebar.defaultProps = {
  onShow: () => {}
};
export default Sidebar;

/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import Sidebar from '../sidebar';
import Header from '../header';

const { Content } = Layout;

const LayoutContainer = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [checked, setChecked] = useState(false);
  const token = useSelector((state) => (!!state.appReducer.token.accessToken));
  const isLoading = useSelector((state) => state.appReducer.isLoading);
  const sizePage = window.screen.width;
  const handleShow = () => {
    setShowMenu(!showMenu);
    setChecked(!checked);
  };
  const handleShowClick = () => {
    if (showMenu && checked) {
      setShowMenu(!showMenu);
      setChecked(!checked);
    }
  };
  return (
    <Layout onClick={handleShowClick}>
      <Header onShow={handleShow} checked={checked} />
      <Content>
        <Layout className="site-layout-background">
          {token && (
            <div className="sidebar">
              {sizePage > 600 ? <Sidebar /> : (
                <>
                  {showMenu && (
                  <div className="menu-phone">
                    <Sidebar onShow={handleShow} />
                  </div>
                  )}
                </>
              )}
            </div>
          )}
          <Content>
            <div className="main-container">
              <div className="main-container__page">

                <div>
                  <Skeleton loading={isLoading}>
                    {children}
                  </Skeleton>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
};

LayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
export default LayoutContainer;

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { api } from '../services/api';
import {
  DELETE_GRAFICA_PATH,
  FETCH_ELEMENTO_GRAFICA_PATH,
  FETCH_GRAFICA_PATH,
  FETCH_PROYECTO_PATH,
  FETCH_SENSORES_TARJETA_GRAFICA_PATH,
  FETCH_TARJETA_GRAFICA_PATH,
  FETCH_TIPO_GRAFICA_PATH,
  SENSORES_GRAFICA_PATH
} from '../services/path';
import { showErrorMessage, showSuccessMessage } from '../utils/utils';
import {
  ADD_SENSORES_GRAFICA,
  CHANGE_ELEMENTO_GRAFICA,
  CHANGE_TARJETA_GRAFICA,
  CREAR_GRAFICA,
  DELETE_GRAFICA,
  DELETE_SENSORES_GRAFICA,
  FETCH_ELEMENTO_GRAFICA,
  FETCH_GRAFICA,
  FETCH_GRAFICA_BY_ID,
  FETCH_GRAFICA_BY_ID_REFRESH,
  FETCH_PROYECTO_GRAFICA,
  FETCH_SENSORES_GRAFICA,
  FETCH_TARJETA_GRAFICA,
  FETCH_TIPO_GRAFICA,
  LIMPIAR_ESTADO_GRAFICA,
  LIMPIAR_GRAFICA,
} from './types';

export const fetchGrafica = (perPage, offSet) => (dispatch) => dispatch({
  type: FETCH_GRAFICA,
  promise: api.get(`${FETCH_GRAFICA_PATH}?perPage=${perPage}&offSet=${offSet}`)
});

export const fetchTipoGrafica = () => (dispatch) => dispatch({
  type: FETCH_TIPO_GRAFICA,
  promise: api.get(FETCH_TIPO_GRAFICA_PATH)
});

export const fetchGraficaById = (id) => (dispatch) => dispatch({
  type: FETCH_GRAFICA_BY_ID,
  promise: api.get(`${FETCH_GRAFICA_PATH}/${id}`)
});

export const fetchGraficaByIdRefresh = (id) => (dispatch) => dispatch({
  type: FETCH_GRAFICA_BY_ID_REFRESH,
  promise: api.get(`${FETCH_GRAFICA_PATH}/${id}`)
});

export const fetchProyectoGrafica = () => (dispatch) => dispatch({
  type: FETCH_PROYECTO_GRAFICA,
  promise: api.get(FETCH_PROYECTO_PATH)
});

export const fetchElementoGrafica = (id) => (dispatch) => dispatch({
  type: FETCH_ELEMENTO_GRAFICA,
  promise: api.get(`${FETCH_ELEMENTO_GRAFICA_PATH}/${id}`)
});

export const fetchTarjetaGrafica = (id) => (dispatch) => dispatch({
  type: FETCH_TARJETA_GRAFICA,
  promise: api.get(`${FETCH_TARJETA_GRAFICA_PATH}/${id}`)
});

export const fetchSensoresGrafica = (idTarjeta, idTipo, idGrafica) => (dispatch) => dispatch({
  type: FETCH_SENSORES_GRAFICA,
  promise: api.get(`${FETCH_SENSORES_TARJETA_GRAFICA_PATH}/${idTarjeta}/${idTipo}/${idGrafica}`)
});

export const addSensor = (data, id) => (dispatch) => dispatch({
  type: ADD_SENSORES_GRAFICA,
  promise: api.post(SENSORES_GRAFICA_PATH, data),
  meta: {
    onSuccess: () => {
      dispatch(fetchGraficaById(id));
      showSuccessMessage('Sensor agregado', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const createGrafica = (data, callback) => (dispatch) => {
  if (data.elemento === '') delete data.elemento;
  if (data.tarjeta === '') delete data.tarjeta;
  dispatch({
    type: CREAR_GRAFICA,
    promise: api.post(FETCH_GRAFICA_PATH, data),
    meta: {
      onSuccess: (res) => {
        showSuccessMessage('Grafica creada', dispatch);
        callback(res.data._id);
      },
      onFailure: (res) => {
        showErrorMessage(res.message, dispatch);
      }
    }
  });
};

export const deleteGrafica = (id, callback) => (dispatch) => dispatch({
  type: DELETE_GRAFICA,
  promise: api.delete(`${DELETE_GRAFICA_PATH}/${id}`),
  meta: {
    onSuccess: () => {
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const deleteSensor = (id, idGrafica) => (dispatch) => dispatch({
  type: DELETE_SENSORES_GRAFICA,
  promise: api.delete(`${SENSORES_GRAFICA_PATH}/${id}`),
  meta: {
    onSuccess: () => {
      dispatch(fetchGraficaById(idGrafica));
      showSuccessMessage('Sensor Eliminado', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.message, dispatch);
    }
  }
});

export const changeTarjeta = () => (dispatch) => dispatch({
  type: CHANGE_TARJETA_GRAFICA,
});

export const changeElemento = () => (dispatch) => dispatch({
  type: CHANGE_ELEMENTO_GRAFICA,
});

export const limpiarGrafica = () => (dispatch) => dispatch({
  type: LIMPIAR_GRAFICA,
});
export const limpiarGraficaEstado = () => (dispatch) => dispatch({
  type: LIMPIAR_ESTADO_GRAFICA,
});

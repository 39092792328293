/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Bread from '../../../components/breadcrumb';
import { routeUsuarioCreate, routeUsuarioEditar } from '../../../utils/routesBread';
import FormUsuario from '../form';
import { USUARIO_PATH } from '../../../routes/path';
import {
  createUsuario, editUser, fetchUsuarioById, limpiarUsuario
} from '../../../actions/user';
import { fetchEmpresaNotPagination } from '../../../actions/empresa';

const CrearEditUsuario = () => {
  const [form, setForm] = useState({
    nombre: '',
    empresa: '',
    email: '',
    celular: '',
    puesto: '',
    password: '',
  });
  const { usuario } = useSelector((state) => state.usuarioReducer);

  const { empresas } = useSelector((state) => state.empresaReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchEmpresaNotPagination());
    if (pathname.includes('editar-usuario')) {
      dispatch(fetchUsuarioById(id));
    }
    return () => {
      dispatch(limpiarUsuario());
    };
  }, []);
  useEffect(() => {
    setForm({
      email: usuario && usuario.email,
      nombre: usuario && usuario.nombre,
      celular: usuario && usuario.celular,
      puesto: usuario && usuario.puesto,
      empresa: usuario && usuario.empresa && usuario.empresa._id,
      rol: 'empresa'
    });
  }, [usuario]);

  const handleChange = (newForm) => {
    setForm(newForm);
  };

  const redirect = () => {
    navigate(USUARIO_PATH);
  };

  const redirectProfile = () => {
    navigate(`/info-usuario/${id}`);
  };

  const handleSubmitCreate = () => {
    dispatch(createUsuario(form, redirect));
  };

  const handleSubmitEdit = () => {
    dispatch(editUser(id, form, redirectProfile));
  };
  return (
    <>
      <div className="header-nav">
        <Bread routes={pathname.includes('crear-usuario') ? routeUsuarioCreate : routeUsuarioEditar} />
      </div>
      <div className="info-container">
        {pathname.includes('crear-usuario')
          ? (
            <FormUsuario
              data={form}
              onSubmit={handleSubmitCreate}
              onChange={handleChange}
              empresas={empresas}
            />
          )
          : (
            <FormUsuario
              data={form}
              onSubmit={handleSubmitEdit}
              onChange={handleChange}
              empresas={empresas}
              isEdit
            />
          )}
      </div>
    </>
  );
};

export default CrearEditUsuario;

/* eslint-disable react/prop-types */
import { Divider } from 'antd';
import React from 'react';
import Sensor from './sensor';

const Tarjeta = ({
  id, nombre, sensores, sensoresChecked, onCheckSensor, disabled
}) => (
  <div key={id} className="container-checkbox-tarjeta">
    <h2>
      {`Tarjeta: ${nombre}`}
    </h2>
    <div className="orden-sensores">
      {sensores.length === 0
        ? <p className="text-nothing">No hay sensores para mostrar</p>
        : (sensores.map(({ id, nombre }) => (
          <Sensor
            disabled={disabled}
            key={id}
            id={id}
            nombre={nombre}
            checked={sensoresChecked.includes(id)}
            onCheckSensor={onCheckSensor}
          />
        )))}
    </div>
    <Divider style={{ border: '0.01 groove ' }} />

  </div>
);

export default Tarjeta;

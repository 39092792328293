/* eslint-disable camelcase */
/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import moment from 'moment';
import { Provider } from 'react-redux';
import es_ES from 'antd/lib/locale/es_ES';
import { ConfigProvider } from 'antd';
import configureStore from './state/store';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = configureStore();

moment.locale('es-mx');

root.render(
  <ConfigProvider locale={es_ES}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>
);

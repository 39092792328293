import React from 'react';
import PropTypes from 'prop-types';
import imgElemento from '../../assets/icons/elemento.png';
import imgEmpresa from '../../assets/icons/empresa.png';
import imgGraficas from '../../assets/icons/graficas.png';
import imgProyectos from '../../assets/icons/proyecto.png';
import imgReportes from '../../assets/icons/reportes.png';
import imgSensores from '../../assets/icons/sensor.png';
import imgTarjetas from '../../assets/icons/tarjeta.png';
import imgUsuarios from '../../assets/icons/user.png';

const imgPaths = {
  elemento: imgElemento,
  empresa: imgEmpresa,
  grafica: imgGraficas,
  proyecto: imgProyectos,
  reporte: imgReportes,
  sensor: imgSensores,
  tarjeta: imgTarjetas,
  usuario: imgUsuarios,
};

const Icon = ({ name, size }) => (
  <div>
    <img src={imgPaths[`${name}`]} width={size} alt={name} />
  </div>
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
};
Icon.defaultProps = {
  size: 20
};

export default Icon;

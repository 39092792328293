/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

const BurguerButton = ({ checked, onChecked }) => (
  <div>
    <div className="demo">
      <div className="menu-icon">
        <input
          className="menu-icon__cheeckbox"
          type="checkbox"
          checked={checked}
          onChange={() => onChecked()}
        />
        <div>
          <span />
          <span />
        </div>
      </div>
    </div>
  </div>
);

BurguerButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChecked: PropTypes.func.isRequired,
};

export default BurguerButton;

/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-restricted-globals */
import { fechaHoraRango, formatDate } from './date';

export const parserReportesGeneral = ({
  _id,
  nombre,
  unidades,
  rangoMin,
  rangoMax,
  tipoReporte,
  fechaInicio,
  fechaFin,
  minimo,
  maximo,
  promedio,
  alertas,
  consumo,
  isConsumo,
}) => ({
  key: _id,
  sensor: nombre,
  rango: `(${`${rangoMin?.$numberDecimal}, ${rangoMax?.$numberDecimal}`}) ${unidades}`,
  tipoReporte: tipoReporte?.nombre,
  fechaInicio,
  fechaFin,
  minimo: minimo === null ? '-' : Number(minimo?.$numberDecimal).toFixed(2),
  maximo: maximo === null ? '-' : Number(maximo?.$numberDecimal).toFixed(2),
  promedio: promedio === null ? '-' : promedio,
  consumo: isConsumo === true ? (parseFloat(consumo) < 0 ? 0 : consumo) : '-',
  alertas: alertas === null ? 0 : alertas,
});

export const parserReportesBloques = ({
  fechaInicio,
  fechaFin,
  minimo,
  maximo,
  promedio,
  alertas,
  consumo,
  isConsumo,
}) => {
  return {
    key: fechaInicio + fechaFin,
    fecha: fechaHoraRango(fechaInicio, fechaFin),
    minimo: minimo === null ? '-' : Number(minimo?.$numberDecimal).toFixed(2),
    maximo: maximo === null ? '-' : Number(maximo?.$numberDecimal).toFixed(2),
    promedio: promedio === null ? '-' : promedio,
    consumo:
      isConsumo === true
        ? parseFloat(consumo) < 0
          ? 0
          : consumo.toFixed(2)
        : '-',
    alertas: alertas === null ? 0 : alertas,
  };
};

export const parserReportesAcumulado = (
  {
    fecha,
    objetivo,
    totalRango1,
    totalRango2,
    totalRango3,
    secundario,
    total,
    ryo,
  },
  periodo
) => ({
  key: fecha,
  fecha: periodo === 'Diario' ? formatDate(fecha) : fecha,
  objetivo,
  totalRango1,
  totalRango2,
  totalRango3,
  secundario,
  total,
  ryo: `${ryo}%`,
});

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Input from '../../../components/input';
import { thereIsErrorInForm, validateField } from '../../../utils/validations';
import Select from '../../../components/select';
import Button from '../../../components/button';
import { limpiarUsuario } from '../../../actions/user';

const FormUsuario = ({
  data, onSubmit, onChange, disabled = false, empresas
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState({
    nombre: '',
    empresa: '',
    email: '',
    celular: '',
    puesto: '',
    password: '',
  });

  const { pathname } = useLocation();

  const [isFormComplete, setIsFormComplete] = useState(false);

  useEffect(() => () => {
    dispatch(limpiarUsuario());
  }, []);

  useEffect(() => {
    setIsFormComplete(
      data.nombre
      && data.empresa
      && data.email
      && data.celular
      && data?.puesto
      && data.password
    );
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const nombreError = validateField('nombre', data.nombre);
    const empresaError = validateField('empresa', data.empresa);
    const emailError = validateField('email', data.email);
    const celularError = validateField('celular', data.celular);
    const puestoError = validateField('puesto', data.puesto);
    const contraseñaError = validateField('password', data.password);

    const errorMessage = {
      ...error,
      ...nombreError,
      ...empresaError,
      ...emailError,
      ...celularError,
      ...puestoError,
      ...contraseñaError

    };
    setError(errorMessage);
    if (!thereIsErrorInForm(errorMessage)) {
      onSubmit();
    }
  };

  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-info-container">
        <div className="form-info-container__box-container">
          <div className="form-info-container__box">
            <Input
              placeholder=""
              name="email"
              label="Email"
              value={data.email}
              inputType="email"
              disabled={disabled}
              onChange={handleChange}
            />
            {
              (pathname.includes('crear') || pathname.includes('editar'))
              && (
              <Input
                placeholder=""
                name="password"
                label="Contraseña"
                value={data.password}
                inputType="password"
                disabled={disabled}
                onChange={handleChange}
              />
              )
            }
            <Input
              placeholder=""
              name="nombre"
              label="Nombre"
              value={data.nombre}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
            />
            <Select
              placeholder=""
              name="empresa"
              label="Empresa"
              value={data.empresa}
              disabled={disabled}
              onChange={handleChange}
              options={empresas}
            />
          </div>
          <div className="form-info-container__box">
            <Input
              placeholder=""
              name="celular"
              label="Celular"
              value={data.celular}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
            />
            <Input
              placeholder=""
              name="puesto"
              label="Puesto"
              value={data.puesto}
              inputType="text"
              disabled={disabled}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-info-container__button">
          { (pathname.includes('crear') || pathname.includes('editar'))
            && (
              <div className="form-info-container__button-edit">
                <Button
                  type="submit"
                  text="Guardar usuario"
                  color="gray"
                  onClick={handleSubmit}
                  disabled={!isFormComplete}
                />
              </div>
            )}
        </div>
      </div>
    </form>
  );
};
FormUsuario.propTypes = {
  data: PropTypes.shape({
    nombre: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
};
export default FormUsuario;

import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  type, text, color, onClick, disabled = false
}) => (
  <div className="button-container">
    <button
      type={type}
      onClick={onClick}
      className={`button-container__button--${color}`}
      disabled={disabled}
    >
      {text}
    </button>
  </div>
);

Button.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
Button.defaultProps = {
  type: 'button',
  color: '',
  onClick: () => {},
  disabled: false,
};

export default Button;

import {
  DELETE_EMPRESA,
  SET_EMPRESA,
  UPDATE_EMPRESA,
  FETCH_EMPRESA,
  FETCH_EMPRESA_BY_ID,
  LOGOUT,
  LIMPIAR_ESTADO_EMPRESA,
  FETCH_EMPRESA_NOT_PAGINATION
} from '../actions/types';
import { handleAction } from '../utils/handleAction';

const initialState = {
  empresas: [],
  isLoading: false,
  error: '',
};

const EmpresaReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_EMPRESA:
      return {
        ...state,
        token: action.tokens,
      };
    case UPDATE_EMPRESA:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({ ...prevState, bussine: payload.data }),
      });
    case FETCH_EMPRESA:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          empresas: payload.data.data,
          total: payload.data.total
        }),
      });
    case FETCH_EMPRESA_NOT_PAGINATION:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          empresas: payload.data,
        }),
      });
    case DELETE_EMPRESA:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({ ...prevState }),
      });
    case FETCH_EMPRESA_BY_ID:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          empresa: payload.data,
        }),
      });
    case LIMPIAR_ESTADO_EMPRESA:
      return { ...initialState };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default EmpresaReducer;
